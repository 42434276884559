import React from "react"
import ReactDOM from "react-dom"
import "styles/materialize.scss"
import App from "./App.jsx"

/* Redux */
import { Provider } from "react-redux"
import generateStore from "./redux/store"

/* SEO */
import { HelmetProvider } from "react-helmet-async"

/* GA */
import ReactGA from "react-ga"

// Init store
const store = generateStore()

ReactGA.initialize(process.env.REACT_APP_GA)
ReactDOM.render(
    <HelmetProvider>
        <Provider store={store}>
            <App />
        </Provider>
    </HelmetProvider>,
    document.getElementById("root")
)
