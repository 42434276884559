import { ConvertBase64ToFile } from "./ConvertBase64ToFile"
import axios from "axios"
import { LogEvent } from "./LogEvents"
import { LoadingTimer } from "./LoadingTimer"
import { database, db, firebase } from "controller/firebase"
import QRCodeStyling from "qr-code-styling"

const ALLOWED_PATTERNS = ["dots", "square", "rounded"]

const ALLOWED_CORNERS_SQUARE = ["dot", "square"]

const ALLOWED_CORNERS_DOT = ["dot", "square"]

export function CreateQRCodeFastOld({
    url = window.origin,
    logo = null,
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
    config = {},
}) {
    return new Promise(async (resolve, reject) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 10,
            eventName: "create qr no logo",
            userId: userId,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        const killQrCodes = await validateMaxQrCodes()
        if (killQrCodes.code !== 200) {
            // Stop timer
            stopTimer(interval)
            LogEvent("new_error", {
                description: `L32 @ CreateQrCode.js | ${killQrCodes.code} - ${killQrCodes.message}`,
            })
            reject(killQrCodes)
            return
        }

        let configQR = {
            body: "square",
            eye: "frame0",
            eyeBall: "ball0",
            erf1: ["fh"],
            brf1: ["fh"],
            erf3: ["fv", "fh"],
            brf3: ["fv", "fh"],
            bodyColor: "#000",
            bgColor: "#fff",
            eye1Color: "#000",
            eye2Color: "#000",
            eye3Color: "#000",
            eyeBall1Color: "#000",
            eyeBall2Color: "#000",
            eyeBall3Color: "#000",
        }

        if (config.body) {
            configQR = { ...config }
        }

        const options = {
            method: "POST",
            url: "https://qrcode-monkey.p.rapidapi.com/qr/custom",
            responseType: "arraybuffer",
            data: {
                data: url,
                config: {
                    ...configQR,
                },
                size: 600,
                download: false,
                file: "png",
            },
            headers: {
                "content-type": "application/json",
                "x-rapidapi-key": process.env.REACT_APP_QR_MONKEY,
                "x-rapidapi-host": "qrcode-monkey.p.rapidapi.com",
            },
        }

        // If there is a logo to add then add it
        if (logo) {
            options.data.config.logo = logo
            options.data.config.logoMode = "clean"
        } else {
            LogEvent("qr_code_create", {
                logo: "no_logo",
                prices_type: pricesType,
                new_user_id: userId,
                venue_id: venueId,
                qr_flow_id: qrFlowId,
                description: `${"no_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
            })
        }

        axios
            .request(options)
            .then(function (response) {
                var blob = response.data
                var str = btoa(
                    new Uint8Array(blob).reduce(function (data, byte) {
                        return data + String.fromCharCode(byte)
                    }, "")
                )

                var newValue = `data:image/png;base64,${str}`

                const file = ConvertBase64ToFile({
                    dataUrl: newValue,
                    fileName: "qr.png",
                })

                // Update realtime database
                database
                    .ref("qr_code_limiter/limit/u")
                    .push(firebase.database.ServerValue.TIMESTAMP)

                // Stop timer
                stopTimer(interval)
                resolve({ file: file, image: newValue })
            })
            .catch(function (error) {
                LogEvent("new_error", {
                    description: `L240 @ CreateQrCode.js | ${error.code} - ${error.message}`,
                })

                // Stop timer
                stopTimer(interval)
                reject(error)
            })
    })
}

export function CreateQrCodeWithLogoFastOld({
    logo = null,
    url = window.origin,
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
    config = {},
}) {
    return new Promise(async (resolve, reject) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 30,
            eventName: "create qr logo",
            userId: userId,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        const killQrCodes = await validateMaxQrLogos()
        if (killQrCodes.code !== 200) {
            // Stop timer
            stopTimer(interval)
            LogEvent("new_error", {
                description: `L32 @ CreateQrCode.js | ${killQrCodes.code} - ${killQrCodes.message}`,
            })
            reject(killQrCodes)
            return
        }

        if (logo) {
            const data = new FormData()
            data.append("file", logo)

            const optionsLogo = {
                method: "POST",
                url: "https://qrcode-monkey.p.rapidapi.com/qr/uploadImage",
                data: data,
                headers: {
                    "content-Type": "multipart/form-data",
                    "content-type": "application/json",
                    "x-rapidapi-key": process.env.REACT_APP_QR_MONKEY,
                    "x-rapidapi-host": "qrcode-monkey.p.rapidapi.com",
                },
            }

            axios
                .request(optionsLogo)
                .then(async function (response) {
                    // Create QR Code
                    const qrCode = await CreateQRCodeFastOld({
                        url: url,
                        logo: response.data.file,
                        config: config,
                    })
                    LogEvent("qr_code_create", {
                        logo: "custom_logo",
                        prices_type: pricesType,
                        new_user_id: userId,
                        venue_id: venueId,
                        qr_flow_id: qrFlowId,
                        description: `${"custom_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
                    })

                    // Update realtime database
                    database
                        .ref("qr_logo_limiter/limit/u")
                        .push(firebase.database.ServerValue.TIMESTAMP)

                    // Stop timer
                    stopTimer(interval)
                    resolve(qrCode)
                })
                .catch(function (error) {
                    LogEvent("new_error", {
                        description: `L294 @ CreateQrCode.js | ${error.code} - ${error.message}`,
                    })

                    // Stop timer
                    stopTimer(interval)
                    reject(error)
                })
        } else {
            try {
                const qrCode = await CreateQRCodeFastOld({
                    url: url,
                    logo: "https://myfreeqr.com/static/media/default_logo.a10a37cc.png",
                })

                LogEvent("qr_code_create", {
                    logo: `default_logo`,
                    prices_type: pricesType,
                    new_user_id: userId,
                    venue_id: venueId,
                    qr_flow_id: qrFlowId,
                    description: `${"default_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId} `,
                })

                // Stop timer
                stopTimer(interval)
                resolve(qrCode)
            } catch (error) {
                // Stop timer
                stopTimer(interval)
                reject(error)
            }
        }
    })
}

const validateMaxQrCodes = () => {
    return new Promise(async (resolve, reject) => {
        try {
            // Get data from database
            const p1 = new Promise((resolve) => {
                database
                    .ref("qr_code_limiter/count")
                    .get()
                    .then((res) => resolve(res.val()))
            })
            const p2 = new Promise((resolve) => {
                db.collection("limiters")
                    .doc("qrMonkeyCode")
                    .get()
                    .then((res) => resolve(res.data()))
            })

            await Promise.all([p1, p2]).then((values) => {
                const count = values[0]
                const maxLimit = values[1].lastLimit

                if (count < maxLimit) {
                    resolve({ code: 200, message: "successful" })
                } else {
                    resolve({ code: 400, message: "max limit reached" })
                }
            })
        } catch (error) {
            LogEvent("new_error", {
                description: `L245 @ CreateQrCode.js | ${error.code} - ${error.message}`,
            })

            reject(error)
        }
    })
}

const validateMaxQrLogos = () => {
    return new Promise(async (resolve, reject) => {
        try {
            // Get data from database
            const p1 = new Promise((resolve) => {
                database
                    .ref("qr_logo_limiter/count")
                    .get()
                    .then((res) => resolve(res.val()))
            })
            const p2 = new Promise((resolve) => {
                db.collection("limiters")
                    .doc("qrMonkeyLogo")
                    .get()
                    .then((res) => resolve(res.data()))
            })

            await Promise.all([p1, p2]).then((values) => {
                const count = values[0]
                const maxLimit = values[1].lastLimit

                if (count < maxLimit) {
                    resolve({ code: 200, message: "successful" })
                } else {
                    resolve({ code: 400, message: "max limit reached" })
                }
            })
        } catch (error) {
            LogEvent("new_error", {
                description: `L289 @ CreateQrCode.js | ${error.code} - ${error.message}`,
            })

            reject(error)
        }
    })
}

export async function CreateQRCodeFast({
    url = window.origin,
    logo = null,
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
    config = {},
}) {
    // Start timer with a max limit
    const { interval, stopTimer } = LoadingTimer({
        maxTime: 10,
        eventName: "create qr no logo",
        userId: userId,
        venueId: venueId,
        qrFlowId: qrFlowId,
    })

    try {
        const killQrCodes = await validateMaxQrCodes()
        if (killQrCodes.code !== 200) {
            // Stop timer
            stopTimer(interval)
            LogEvent("new_error", {
                description: `L32 @ CreateQrCode.js | ${killQrCodes.code} - ${killQrCodes.message}`,
            })

            return killQrCodes
        }

        let bodyColor = "#000"
        let backgroundColor = "#fff"
        let pattern = "square"
        let cornersSquare = "square"
        let cornersDot = "square"

        if (config?.body) {
            bodyColor = config.bodyColor
            backgroundColor = config.bgColor
            pattern = ALLOWED_PATTERNS.includes(config.body)
                ? config.body
                : "square"
            cornersSquare = ALLOWED_CORNERS_SQUARE.includes(config.eye)
                ? config.eye
                : "square"
            cornersDot = ALLOWED_CORNERS_DOT.includes(config.eyeBall)
                ? config.eyeBall
                : "square"
        }

        // If there is a logo to add then add it
        if (!logo) {
            LogEvent("qr_code_create", {
                logo: "no_logo",
                prices_type: pricesType,
                new_user_id: userId,
                venue_id: venueId,
                qr_flow_id: qrFlowId,
                description: `${"no_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
            })
        }

        const qrCode = new QRCodeStyling({
            width: 500,
            height: 500,
            type: "canvas",
            data: url,
            margin: 10,
            imageOptions: {
                crossOrigin: "anonymous",
                margin: 0,
                imageSize: 0.4,
            },
            dotsOptions: {
                color: bodyColor,
                type: pattern,
            },
            cornersSquareOptions: {
                color: bodyColor,
                type: cornersSquare,
            },
            backgroundOptions: {
                color: backgroundColor,
            },
            cornersDotOptions: {
                color: bodyColor,
                type: cornersDot,
            },
            imageOptions: {
                crossOrigin: "anonymous",
                margin: 20,
            },
        })

        const file = await qrCode.getRawData("png")

        // Update realtime database
        database
            .ref("qr_code_limiter/limit/u")
            .push(firebase.database.ServerValue.TIMESTAMP)

        // Stop timer
        stopTimer(interval)

        // Convert to base64
        const image = qrCode._canvas
            .getContext("2d")
            .canvas.toDataURL("image/png", 90)

        return { file, image }
    } catch (error) {
        LogEvent("new_error", {
            description: `L240 @ CreateQrCode.js | ${error.code} - ${error.message}`,
        })

        // Stop timer
        stopTimer(interval)
        return error
    }
}

export async function CreateQrCodeWithLogoFast({
    url = window.origin,
    logo = null,
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
    config = {},
}) {
    // Start timer with a max limit
    const { interval, stopTimer } = LoadingTimer({
        maxTime: 30,
        eventName: "create qr logo",
        userId: userId,
        venueId: venueId,
        qrFlowId: qrFlowId,
    })

    try {
        const killQrCodes = await validateMaxQrCodes()
        if (killQrCodes.code !== 200) {
            // Stop timer
            stopTimer(interval)
            LogEvent("new_error", {
                description: `L32 @ CreateQrCode.js | ${killQrCodes.code} - ${killQrCodes.message}`,
            })

            return killQrCodes
        }

        let bodyColor = "#000"
        let backgroundColor = "#fff"
        let pattern = "square"
        let cornersSquare = "square"
        let cornersDot = "square"

        if (config?.body) {
            bodyColor = config.bodyColor
            backgroundColor = config.bgColor
            pattern = ALLOWED_PATTERNS.includes(config.body)
                ? config.body
                : "square"
            cornersSquare = ALLOWED_CORNERS_SQUARE.includes(config.eye)
                ? config.eye
                : "square"
            cornersDot = ALLOWED_CORNERS_DOT.includes(config.eyeBall)
                ? config.eyeBall
                : "square"
        }

        // If there is a logo to add then add it
        if (logo) {
            // Create QR Code
            const srcLogo = URL.createObjectURL(logo)

            const qrCode = new QRCodeStyling({
                width: 500,
                height: 500,
                type: "canvas",
                data: url,
                margin: 10,
                image: srcLogo,
                imageOptions: {
                    crossOrigin: "anonymous",
                    margin: 0,
                    imageSize: 0.4,
                },
                dotsOptions: {
                    color: bodyColor,
                    type: pattern,
                },
                cornersSquareOptions: {
                    color: bodyColor,
                    type: cornersSquare,
                },
                backgroundOptions: {
                    color: backgroundColor,
                },
                cornersDotOptions: {
                    color: bodyColor,
                    type: cornersDot,
                },
                imageOptions: {
                    crossOrigin: "anonymous",
                    margin: 20,
                },
            })

            LogEvent("qr_code_create", {
                logo: "custom_logo",
                prices_type: pricesType,
                new_user_id: userId,
                venue_id: venueId,
                qr_flow_id: qrFlowId,
                description: `${"custom_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
            })

            const file = await qrCode.getRawData("png")

            // Update realtime database
            database
                .ref("qr_logo_limiter/limit/u")
                .push(firebase.database.ServerValue.TIMESTAMP)

            // Stop timer
            stopTimer(interval)

            // Convert to base64
            const image = qrCode._canvas
                .getContext("2d")
                .canvas.toDataURL("image/png", 90)

            // Revoke the object url
            URL.revokeObjectURL(srcLogo)

            return { file, image }
        } else {
            const qrCode = await CreateQRCodeFast({
                url: url,
                logo: "https://myfreeqr.com/static/media/default_logo.a10a37cc.png",
            })

            LogEvent("qr_code_create", {
                logo: `default_logo`,
                prices_type: pricesType,
                new_user_id: userId,
                venue_id: venueId,
                qr_flow_id: qrFlowId,
                description: `${"default_logo"} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId} `,
            })

            // Stop timer
            stopTimer(interval)

            return qrCode
        }
    } catch (error) {
        LogEvent("new_error", {
            description: `L240 @ CreateQrCode.js | ${error.code} - ${error.message}`,
        })

        // Stop timer
        stopTimer(interval)
        return error
    }
}
