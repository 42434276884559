/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

/* Helpers */
import {
    CreateQRCodeFast,
    CreateQrCodeWithLogoFast,
} from "helpers/CreateQrCode"
import { createEasyPdfHtml } from "../helpers/QrActions"
import { CreateCustomUrl } from "helpers/CreateCustomUrl"
import { LogEvent } from "helpers/LogEvents"

/* Html2Pdf */
import html2pdf from "html2pdf.js"

/* Helpers */
import { DeleteAccount } from "helpers/DeleteAccount"
import { CreateCustomerId } from "helpers/stripe"
import { LoadingTimer } from "helpers/LoadingTimer"

/* Redux */
import { getQrFlowsData } from "./qrFlowDucks"
import {
    endLoadingStriper,
    getDataStripe,
    startLoadingStriper,
    updateCurrentPaymentIntent,
    updateCustomerId,
} from "./stripeDucks"
import { getDataVenue } from "./venueDucks"
import { logout } from "./authDucks"

/* Firebase */
import { db, storage, functions, auth } from "controller/firebase"

/* I18n */
import i18n from "i18next"

/* Constants or States */
const initData = {
    loading: false,
    loadingQrs: false,
    loadingDeleteAccount: false,
    newUser: false,
    data: [],
}

const LOADING_USER = "LOADING_USER"
const LOADING_QRS = "LOADING_QRS"
const LOADING_DELETE_ACCOUNT = "LOADING_DELETE_ACCOUNT"

const CREATE_USER_EXIT = "CREATE_USER_EXIT"
const CREATE_USER_ERROR = "CREATE_USER_ERROR"

const CREATE_QRS_EXIT = "CREATE_QRS_EXIT"
const CREATE_QRS_ERROR = "CREATE_QRS_ERROR"

const GET_USER_EXIT = "GET_USER_EXIT"
const GET_USER_ERROR = "GET_USER_ERROR"

const UPDATE_USER_EXIT = "UPDATE_USER_EXIT"
const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

const RESTART_USER = "RESTART_USER"

/* Reducer (Save call API in constant) */
export default function authReducer(state = initData, action) {
    switch (action.type) {
        case LOADING_USER:
            return { ...state, loading: true }
        case LOADING_QRS:
            return { ...state, loadingQrs: true }
        case LOADING_DELETE_ACCOUNT:
            return { ...state, loadingDeleteAccount: true }
        case CREATE_USER_EXIT:
            return {
                ...state,
                data: { ...action.payload },
                newUser: true,
                loading: false,
            }
        case CREATE_USER_ERROR:
            return { ...initData }
        case CREATE_QRS_EXIT:
            return { ...state, loadingQrs: false }
        case CREATE_QRS_ERROR:
            return { ...initData }
        case GET_USER_EXIT:
            return { ...state, data: { ...action.payload }, loading: false }
        case GET_USER_ERROR:
            return { ...initData }
        case UPDATE_USER_EXIT:
            return { ...state, data: { ...action.payload }, loading: false }
        case UPDATE_USER_ERROR:
            return { ...state, loading: false }
        case RESTART_USER:
            return { ...initData }
        default:
            return { ...state }
    }
}

/* Actions (Calls API) */
export const createUserStructure =
    ({ uid = "", email = "", country, from = "home", hasPassword }) =>
    async (dispatch, getState) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 20,
            eventName: "sign up - create structure",
            userId: uid,
            venueId: "none",
            qrFlowId: "none",
        })

        // Init loading to create user
        dispatch({
            type: LOADING_USER,
        })

        let venueId = ""
        let idFlow = ""

        try {
            // Get Time zone from the browser
            const time = new Date().toTimeString().slice(9)
            const timezone =
                time.substr(0, 6) +
                ":" +
                time.substr(6, 3) +
                Intl.DateTimeFormat().resolvedOptions().timeZone

            // Get language
            const lang = navigator.language || navigator.userLanguage

            // User's properties
            await db
                .collection("users")
                .doc(uid)
                .set({
                    firstName: "",
                    lastName: "",
                    email: email,
                    phone: "",
                    lang: lang,
                    timezone: timezone,
                    pricesType: 6,
                    hasPassword,
                    country: country,
                    from: from,
                    usage: {
                        contacts: 100,
                        emails_1: 100,
                        created: new Date(),
                    },
                    reloadData: false,
                    toast: {
                        customers: false,
                        plan: false,
                        menu: true,
                        qr: true,
                    },
                    venues: {},
                })

            // 15 days without ads
            const showAdsDate = new Date()
            showAdsDate.setDate(showAdsDate.getDate() + 15)

            // Create venue
            const venue = await db
                .collection("users")
                .doc(uid)
                .collection("venues")
                .add({
                    name: "",
                    address: {
                        street: "",
                        country: "",
                        city: "",
                        state: "",
                        postalCode: "",
                    },
                    socials: {
                        instagram: "",
                        tiktok: "",
                        twitter: "",
                        facebook: "",
                    },
                    payNow: false,
                    pricesType: 6,
                    showAds: {
                        version: 5,
                        offUntil: showAdsDate,
                    },
                })

            // Create QR flow
            const qrFlow = await db
                .collection("users")
                .doc(uid)
                .collection("venues")
                .doc(venue.id)
                .collection("qrFlows")
                .add({
                    name: `Menu 1 - ${i18n.t("menuWord")} 1`,
                    qrCode: {
                        config: {
                            body: "square",
                            eye: "square",
                            eyeBall: "square",
                            erf1: ["fh"],
                            brf1: ["fh"],
                            erf3: ["fv", "fh"],
                            brf3: ["fv", "fh"],
                            bodyColor: "#000",
                            bgColor: "#fff",
                            eye1Color: "#000",
                            eye2Color: "#000",
                            eye3Color: "#000",
                            eyeBall1Color: "#000",
                            eyeBall2Color: "#000",
                            eyeBall3Color: "#000",
                        },
                        style: "normal",
                        logo: null,
                        options: {
                            noLogo: null,
                            defaultLogo: null,
                            customLogo: null,
                        },
                        selectedOption: "defaultLogo",
                    },
                    flowSequence: {
                        newsLetterSignUp: {
                            active: false,
                            text: "Subscribe to our newsletter",
                            firstName: false,
                            lastName: false,
                            emailAddress: true,
                            phoneNumber: false,
                            birthday: false,
                            image: null,
                            answerYes: "Subscribe",
                            answerNo: "No, thanks",
                        },
                        showMenu: [],
                    },
                    sequence: ["newsLetterSignUp", "showMenu"],
                    customUrl: "",
                    version: 5,
                })

            venueId = venue.id
            idFlow = qrFlow.id

            // Create data stripe
            startLoadingStriper()(dispatch)
            CreateCustomerId({ email, userId: uid, venueId, qrFlowId: idFlow })
                .then(({ customer, payment }) => {
                    updateCustomerId({ customerId: customer.id })(dispatch)
                    updateCurrentPaymentIntent({ payment })(dispatch)
                    endLoadingStriper()(dispatch)
                })
                .catch(() => {
                    endLoadingStriper()(dispatch)
                })

            // Create customs URL
            const customUrl = await CreateCustomUrl({
                userId: uid,
                venueId: venueId,
                qrFlowId: idFlow,
            })

            // Update in DataBase
            db.collection("users")
                .doc(uid)
                .collection("venues")
                .doc(venueId)
                .collection("qrFlows")
                .doc(idFlow)
                .update({
                    customUrl: customUrl,
                })

            db.collection("users")
                .doc(uid)
                .update({
                    venues: { id: venueId, qrFlows: [idFlow] },
                })

            // Create QR codes
            createQRs({
                venueId: venueId,
                qrFlowId: idFlow,
                uid: uid,
                email: email,
                lang: lang,
                pricesType: 6,
            })(dispatch)

            // Date end free trial
            const endTrial = new Date()
            endTrial.setDate(endTrial.getDate() + 14)

            dispatch({
                type: CREATE_USER_EXIT,
                payload: {
                    uid: uid,
                    email: email,
                    firstName: "",
                    lastName: "",
                    phone: "",
                    timezone: timezone,
                    lang: lang,
                    pricesType: 6,
                    hasPassword,
                    country: country,
                    from: from,
                    usage: {
                        contacts: 100,
                        emails_1: 100,
                        created: new Date(),
                    },
                    reloadData: false,
                    toast: {
                        customers: false,
                        plan: false,
                        menu: true,
                        qr: true,
                    },
                    venueId: venueId,
                    qrFlowsId: [idFlow],
                },
            })

            // Save in the browser user data
            localStorage.setItem(
                "user",
                JSON.stringify({
                    uid: uid,
                    email: email,
                    firstName: "",
                    lastName: "",
                    phone: "",
                    timezone: timezone,
                    lang: lang,
                    pricesType: 6,
                    hasPassword,
                    country: country,
                    from: from,
                    usage: {
                        contacts: 100,
                        emails_1: 100,
                        created: new Date(),
                    },
                    reloadData: false,
                    toast: {
                        customers: false,
                        plan: false,
                        menu: true,
                        qr: true,
                    },
                    venueId: venueId,
                    qrFlowsId: [idFlow],
                })
            )

            // Save in the browser venue data
            localStorage.setItem(
                "venue",
                JSON.stringify({
                    data: [
                        {
                            name: "",
                            address: {
                                street: "",
                                country: "",
                                city: "",
                                state: "",
                                postalCode: "",
                            },
                            socials: {
                                instagram: "",
                                tiktok: "",
                                twitter: "",
                                facebook: "",
                            },
                            payNow: false,
                            pricesType: 6,
                            showAds: {
                                version: 5,
                                offUntil: showAdsDate,
                            },
                            id: venueId,
                        },
                    ],
                })
            )

            // Save in the browser qrFlow data
            localStorage.setItem(
                "qrFlow",
                JSON.stringify({
                    data: [
                        {
                            name: `Menu 1 - ${i18n.t("menuWord")} 1`,
                            qrCode: {
                                config: {
                                    body: "square",
                                    eye: "square",
                                    eyeBall: "square",
                                    erf1: ["fh"],
                                    brf1: ["fh"],
                                    erf3: ["fv", "fh"],
                                    brf3: ["fv", "fh"],
                                    bodyColor: "#000",
                                    bgColor: "#fff",
                                    eye1Color: "#000",
                                    eye2Color: "#000",
                                    eye3Color: "#000",
                                    eyeBall1Color: "#000",
                                    eyeBall2Color: "#000",
                                    eyeBall3Color: "#000",
                                },
                                style: "normal",
                                logo: null,
                                options: {
                                    noLogo: null,
                                    defaultLogo: null,
                                    customLogo: null,
                                },
                                selectedOption: "defaultLogo",
                            },
                            flowSequence: {
                                newsLetterSignUp: {
                                    active: false,
                                    text: "Subscribe to our newsletter",
                                    firstName: false,
                                    lastName: false,
                                    emailAddress: true,
                                    phoneNumber: false,
                                    birthday: false,
                                    image: null,
                                    answerYes: "Subscribe",
                                    answerNo: "No, thanks",
                                },
                                showMenu: [],
                            },
                            sequence: ["newsLetterSignUp", "showMenu"],
                            customUrl: customUrl,
                            venueId: venueId,
                            qrFlowId: idFlow,
                        },
                    ],
                })
            )

            // Get data of venues
            getDataVenue()(dispatch)

            // Get data stripe
            getDataStripe()(dispatch, getState)

            // stop timer
            stopTimer(interval)
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")
            // Save analytics
            LogEvent("new_error", {
                description: `L245 @ userDucks.js | ${error.code} - ${error.message}`,
            })

            // Delete structure
            /* await db
                .collection("users")
                .doc(uid)
                .collection("venues")
                .doc(venueId)
                .collection("qrFlows")
                .doc(idFlow)
                .delete() */

            dispatch({
                type: CREATE_USER_ERROR,
            })

            // stop timer
            stopTimer(interval)
        }
    }

export const createQRs =
    ({
        uid = "",
        venueId = "",
        qrFlowId = "",
        email = "",
        lang = "",
        pricesType = 6,
    }) =>
    async (dispatch) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 120,
            eventName: "sign up - create qr",
            userId: uid,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        dispatch({
            type: LOADING_QRS,
        })

        try {
            // Create QR codes
            const qrNoLogo = await CreateQRCodeFast({
                url: `${window.origin}/scan/${uid}/${venueId}/${qrFlowId}`,
                pricesType: pricesType,
                userId: uid,
                venueId: venueId,
                qrFlowId: qrFlowId,
            })

            // Save image in the storage
            const metaData = { contentType: qrNoLogo.file.type }
            const res = await storage
                .ref()
                .child(uid + "/" + venueId + "/" + qrFlowId + "/QR/")
                .child("noLogo.png")
                .put(qrNoLogo.file, metaData)
            const qrNoLogoURL = await res.ref.getDownloadURL()

            const qrDefaultLogo = await CreateQrCodeWithLogoFast({
                url: `${window.origin}/scan/${uid}/${venueId}/${qrFlowId}`,
                pricesType: pricesType,
                userId: uid,
                venueId: venueId,
                qrFlowId: qrFlowId,
            })
            // Save image in the storage
            const metaDataQrDefaultLogo = {
                contentType: qrDefaultLogo.file.type,
            }
            const resQrDefaultLogoURL = await storage
                .ref()
                .child(uid + "/" + venueId + "/" + qrFlowId + "/QR/")
                .child("defaultLogo.png")
                .put(qrDefaultLogo.file, metaDataQrDefaultLogo)
            const qrDefaultLogoURL =
                await resQrDefaultLogoURL.ref.getDownloadURL()

            // Update data base
            await db
                .collection("users")
                .doc(uid)
                .collection("venues")
                .doc(venueId)
                .collection("qrFlows")
                .doc(qrFlowId)
                .update({
                    "qrCode.options.noLogo": qrNoLogoURL,
                    "qrCode.options.defaultLogo": qrDefaultLogoURL,
                })

            // Save pdf in storage
            const { html } = await createEasyPdfHtml(qrDefaultLogoURL)
            // Config canvas options
            let html2canvasOpts = {
                dpi: 192,
                scale: 3,
                letterRendering: true,
                useCORS: true,
                width: 794,
                height: 992,
            }
            const pdfBase64 = await html2pdf()
                .set({
                    html2canvas: html2canvasOpts,
                    jsPDF: {
                        unit: "mm",
                        format: "a4",
                        orientation: "portrait",
                    },
                })
                .from(html.children[0])
                .outputPdf("datauristring")
            const resPdfQrDefaultLogo = await storage
                .ref()
                .child(uid + "/" + venueId + "/" + qrFlowId + "/QR/")
                .child("reminder.pdf")
                .putString(pdfBase64, "data_url")
            const pdfQrDefaultLogoURL =
                await resPdfQrDefaultLogo.ref.getDownloadURL()

            // 30 days without ads
            /* const date = new Date()
            date.setDate(date.getDate() + 30) */

            // Create turn on ads reminder
            /* await db.collection("turnOnAdsReminder").doc().set({
                userId: uid,
                dateStartAds: date,
                email: email,
                language: lang,
                venueId: venueId,
                qrFlowId: qrFlowId,
            }) */

            // Send welcome email
            const sendWelcomeEmails =
                functions.httpsCallable("sendWelcomeEmails")
            sendWelcomeEmails({
                email: email,
                language: lang,
                qrImage: qrDefaultLogoURL,
                qrPdf: pdfQrDefaultLogoURL,
            })

            // Update redux state
            getQrFlowsData({ readDB: true })(dispatch)

            dispatch({
                type: CREATE_QRS_EXIT,
            })

            // Stop timer
            stopTimer(interval)
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")
            // Save analytics
            LogEvent("new_error", {
                description: `L314 @ userDucks.js | ${error.code} - ${error.message}`,
            })

            // Delete structure
            /* await db
                .collection("users")
                .doc(uid)
                .collection("venues")
                .doc(venueId)
                .collection("qrFlows")
                .doc(qrFlowId)
                .delete() */

            dispatch({
                type: CREATE_QRS_ERROR,
            })

            // stop timer
            stopTimer(interval)
        }
    }

export const getDataUser = () => async (dispatch, getState) => {
    // Init loading
    dispatch({
        type: LOADING_USER,
    })

    // Validate if the data exist in the browser
    if (localStorage.getItem("user")) {
        dispatch({
            type: GET_USER_EXIT,
            payload: JSON.parse(localStorage.getItem("user")),
        })

        // Get data of QR flows
        getQrFlowsData({})(dispatch)

        // Get data of venues
        getDataVenue()(dispatch)

        // Get data stripe
        getDataStripe()(dispatch, getState)
        return
    }

    if (localStorage.getItem("auth")) {
        // Get user's uid from browser
        const uid = JSON.parse(localStorage.getItem("auth")).uid
        let data = {}

        try {
            const res = await db.collection("users").doc(uid).get()

            data = {
                email: res.data().email,
                firstName: res.data().firstName,
                lastName: res.data().lastName,
                phone: res.data().phone,
                timezone: res.data().timezone,
                lang: res.data().lang,
                pricesType: res.data().pricesType,
                hasPassword: res.data().hasPassword,
                country: res.data().country,
                from: res.data().from,
                usage: {
                    ...res.data().usage,
                    created: new Date(res.data().usage.created.toDate()),
                },
                reloadData: res.data().reloadData,
                toast: res.data().toast,
                venueId: res.data().venues.id,
                qrFlowsId: res.data().venues.qrFlows,
            }

            dispatch({
                type: GET_USER_EXIT,
                payload: data,
            })

            // Save in the browser
            localStorage.setItem(
                "user",
                JSON.stringify({
                    ...data,
                })
            )

            // Get data of QR flows
            getQrFlowsData({})(dispatch)

            // Get data of venues
            getDataVenue()(dispatch)

            // Get data stripe
            getDataStripe()(dispatch, getState)
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")
            // Save analytics
            LogEvent("new_error", {
                description: `L383 @ userDucks.js | ${error.code} - ${error.message}`,
            })

            dispatch({
                type: GET_USER_ERROR,
            })
        }
    } else {
        dispatch({
            type: GET_USER_ERROR,
        })
    }
}

export const updateDataUser =
    ({ data = {}, newEmail = null }) =>
    async (dispatch) => {
        // Init loading
        dispatch({
            type: LOADING_USER,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            const dataUser = JSON.parse(localStorage.getItem("user"))
            let email = ""

            if (newEmail) {
                email = newEmail
            } else {
                email = JSON.parse(localStorage.getItem("auth")).email
            }

            try {
                await db.collection("users").doc(uid).update({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: email,
                    phone: data.phone,
                })

                // Reload data token
                reloadDataToken()(dispatch)

                dispatch({
                    type: UPDATE_USER_EXIT,
                    payload: {
                        ...dataUser,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: email,
                        phone: data.phone,
                    },
                })

                // Save in the browser
                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        ...dataUser,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        email: email,
                        phone: data.phone,
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L441 @ userDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_USER_ERROR,
                })
            }
        }
    }

export const updateQrFlows =
    ({ data = {}, newQrFlows }) =>
    async (dispatch) => {
        // Init loading to create user
        dispatch({
            type: LOADING_USER,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid

            try {
                await db.collection("users").doc(uid).update({
                    "venues.qrFlows": newQrFlows,
                })

                // Reload data token
                reloadDataToken()(dispatch)

                dispatch({
                    type: UPDATE_USER_EXIT,
                    payload: {
                        ...data,
                        qrFlowsId: newQrFlows,
                    },
                })

                // Save in the browser
                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        ...data,
                        qrFlowsId: newQrFlows,
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L441 @ userDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_USER_ERROR,
                })
            }
        }
    }

export const updateTimezoneUser =
    ({ data = {} }) =>
    async (dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_USER,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid

            try {
                await db.collection("users").doc(uid).update({
                    timezone: data.timezone,
                })

                // Reload data token
                reloadDataToken()(dispatch)

                dispatch({
                    type: UPDATE_USER_EXIT,
                    payload: {
                        ...getState().user.data,
                        timezone: data.timezone,
                    },
                })

                // Save in the browser
                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        ...getState().user.data,
                        timezone: data.timezone,
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L484 @ userDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_USER_ERROR,
                })
            }
        }
    }

export const updateHasPasswordUser =
    ({ data = {} }) =>
    async (dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_USER,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid

            try {
                await db.collection("users").doc(uid).update({
                    hasPassword: data.hasPassword,
                })

                // Reload data token
                reloadDataToken()(dispatch)

                dispatch({
                    type: UPDATE_USER_EXIT,
                    payload: {
                        ...getState().user.data,
                        hasPassword: data.hasPassword,
                    },
                })

                // Save in the browser
                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        ...getState().user.data,
                        hasPassword: data.hasPassword,
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L954 @ userDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_USER_ERROR,
                })
            }
        } else {
            dispatch({
                type: UPDATE_USER_ERROR,
            })
        }
    }

export const updateToast =
    ({ data = {} }) =>
    async (dispatch) => {
        // Init loading
        dispatch({
            type: LOADING_USER,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            const dataUser = JSON.parse(localStorage.getItem("user"))

            try {
                await db
                    .collection("users")
                    .doc(uid)
                    .update({
                        toast: {
                            ...dataUser.toast,
                            ...data,
                        },
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                dispatch({
                    type: UPDATE_USER_EXIT,
                    payload: {
                        ...dataUser,
                        toast: {
                            ...dataUser.toast,
                            ...data,
                        },
                    },
                })

                // Save in the browser
                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        ...dataUser,
                        toast: {
                            ...dataUser.toast,
                            ...data,
                        },
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L918 @ userDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_USER_ERROR,
                })
            }
        }
    }

export const deleteAccount = () => async (dispatch) => {
    // Init loading to create user
    dispatch({
        type: LOADING_DELETE_ACCOUNT,
    })

    if (localStorage.getItem("auth")) {
        // Get user's uid from browser
        const uid = JSON.parse(localStorage.getItem("auth")).uid

        try {
            await DeleteAccount(uid)
            logout()(dispatch)
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")
            // Save analytics
            LogEvent("new_error", {
                description: `L484 @ userDucks.js | ${error.code} - ${error.message}`,
            })

            dispatch({
                type: UPDATE_USER_ERROR,
            })
        }
    }
}

// Save reload data
export const reloadDataToken =
    (reload = true) =>
    async () => {
        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            try {
                // Get current tokenID
                const idToken = await auth.currentUser.getIdToken(
                    /* forceRefresh */
                    false
                )
                // Save current tokenID
                await db.collection("users").doc(uid).set(
                    {
                        idToken,
                    },
                    { merge: true }
                )

                await db.collection("users").doc(uid).set(
                    {
                        reloadDataToken: reload,
                    },
                    { merge: true }
                )
            } catch (error) {
                console.log(error)
            }
        }
    }

// Reset toast
export const restartDataUser = () => async (dispatch) => {
    dispatch({
        type: RESTART_USER,
    })
}
