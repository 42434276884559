import React from "react"

/* Styles */
import "./TempToast.scss"
import M from "materialize-css"

/* Components */
import StaticToast from "components/toast/StaticToast"
import { Offline, Detector } from "react-detect-offline"

/* Helpers */
import { LogEvent } from "helpers/LogEvents"

/* Redux */
import { useSelector } from "react-redux"

/* I18n */
import { useTranslation } from "react-i18next"

const TempToast = () => {
  // Const to translation
  const { t } = useTranslation()

  // Redux states
  const { active } = useSelector((store) => store.subMenu)

  // Constant
  const leftTrialDays = 5
  let timer
  let timeNetworksFall = 0

  // Component states
  const [isDashboard, setIsDashboard] = React.useState(false)

  // Validate if subMenu is active to change width of toast
  React.useEffect(() => {
    if (
      window.location.href.includes("dashboard") ||
      window.location.href.includes("home-1")
    ) {
      setIsDashboard(true)
    }
  }, [active])

  // Listen class of toast error to reload page
  React.useEffect(() => {
    const toastError = document.getElementById("toast-unexpected-error")
    const observer = new MutationObserver((event) => {
      const currentClass = event[0].target.className
      if (!currentClass.includes("hide")) {
        let time = 5
        setInterval(() => {
          time--
          if (time < 0) {
            window.location.reload()
          }
        }, 1000)
      }
    })

    observer.observe(toastError, {
      attributeFilter: ["class"],
    })
  }, [])

  return (
    <>
      {/* Limit customer */}
      <div
        id="toast-upgrade-plan-limit-customers"
        className="o-info-container hide"
      >
        <StaticToast
          content={<p className="mt-0">{t("tempToast1Content")}</p>}
          link={<p className="mt-0">{t("tempToast1Link")}</p>}
          action={() => {
            const elem = document.getElementById("modal-upgrade-plan")
            var instance = M.Modal.getInstance(elem)
            instance.open()
          }}
          close={true}
          type="warning"
        ></StaticToast>
      </div>

      {/* Limit plan */}
      <div id="toast-upgrade-plan-limit-date" className="o-info-container hide">
        <StaticToast
          content={
            <p className="mt-0">{t("tempToast2Content", { leftTrialDays })}</p>
          }
          link={<p className="mt-0">{t("tempToast2Link")}</p>}
          action={() => {
            const elem = document.getElementById("modal-upgrade-plan")
            var instance = M.Modal.getInstance(elem)
            instance.open()
          }}
          close={true}
          type="warning"
        ></StaticToast>
      </div>

      <div id="toast-upgrade-plan-end-date" className="o-info-container hide">
        <StaticToast
          content={<p className="mt-0">{t("tempToast3Content")}</p>}
          link={<p className="mt-0">{t("tempToast3Link")}</p>}
          action={() => {
            const elem = document.getElementById("modal-upgrade-plan")
            var instance = M.Modal.getInstance(elem)
            instance.open()
          }}
          close={true}
          type="warning"
        ></StaticToast>
      </div>

      {/* Offline mode */}
      <Offline>
        <div
          id="toast-offline"
          className={isDashboard ? "o-info-container" : "o-info-container ml-0"}
        >
          <StaticToast
            content={<p className="mt-0">{t("tempToast4Content")}</p>}
            type="warning"
          ></StaticToast>
        </div>
      </Offline>

      {/* Unexpected error */}
      <div
        id="toast-unexpected-error"
        className={
          isDashboard ? "o-info-container hide" : "o-info-container hide ml-0"
        }
      >
        <StaticToast
          content={<p className="mt-0">{t("tempToast5Content")}</p>}
          //link={<p className="mt-0">{t("tempToast5Link")}</p>}
          action={() => {
            window.location.reload()
          }}
          close={true}
          type="warning"
        ></StaticToast>
      </div>

      {/* Detect the change of networks state */}
      <Detector
        onChange={(online) => {
          clearInterval(timer)

          // If the status change to online
          if (online) {
            LogEvent("network_fall", {
              description: window.location.href,
              time: timeNetworksFall,
            })
            // Reload page
            localStorage.removeItem("venue")
            localStorage.removeItem("qrFlow")
            window.location.reload()
          } else {
            // Start timer to know whe time the user is offline
            timer = setInterval(() => {
              timeNetworksFall++
            }, 1000)
          }
        }}
        render={() => null}
      />
    </>
  )
}

export default TempToast
