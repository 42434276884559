import { createStore, combineReducers, compose, applyMiddleware } from "redux"
import thunk from "redux-thunk"

/* Import all ducks reducer */
import authReducer, { getActiveAuth } from "./authDucks"
import userReducer from "./userDucks"
import qrFlowReducer from "./qrFlowDucks"
import scanReducer from "./scanDucks"
import sideNavMobileReducer from "./navbarMobileDucks"
import subMenuReducer from "./subMenuDucks"
import venueReducer from "./venueDucks"
import stripeReducer from "./stripeDucks"

/* Combine all ducks */
const rootReducer = combineReducers({
    auth: authReducer,
    user: userReducer,
    qrFlow: qrFlowReducer,
    scan: scanReducer,
    sideNavMobile: sideNavMobileReducer,
    subMenu: subMenuReducer,
    venue: venueReducer,
    stripe: stripeReducer,
})

/* Configuration to extension redux in google chrome */
const composeEnhancers =
    (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose

/* Configuration to store */
export default function generateStore() {
    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    )

    // Verify if exist auth
    getActiveAuth()(store.dispatch)

    return store
}