/* Helpers */
import { ConvertUrlToBase64 } from "./ConvertUrlToBase64"
import { LogEvent } from "./LogEvents"
import { LoadingTimer } from "./LoadingTimer"
import html2pdf from "html2pdf.js"

/* Components */
import { openToast, SUCCESS, ERROR } from "components/toast/Toast"

/* Firebase */
import { functions, storage } from "controller/firebase"

/* I18n */
import i18n from "i18next"

export function DownloadQr({
    type = "",
    qrImage = "",
    qrType = "",
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
    qrConfig = {},
    style = "normal",
}) {
    return new Promise(async(resolve, reject) => {
        if (type === "pdf") {
            const { html } = await createEasyPdfHtml(qrImage)
                // Config canvas options
            let html2canvasOpts = {
                    dpi: 192,
                    scale: 4,
                    letterRendering: true,
                    useCORS: true,
                    width: 794,
                    height: 992,
                }
                // Convert into PDF and download
            html2pdf()
                .set({
                    image: { type: "jpeg", quality: 1 },
                    html2canvas: html2canvasOpts,
                    jsPDF: {
                        unit: "mm",
                        format: "a4",
                        orientation: "portrait",
                    },
                })
                .from(html.children[0])
                .save("print-qr-code.pdf")
                .then(function(e) {
                    LogEvent("", {
                        logo: qrType,
                        method: "download",
                        format: type,
                        prices_type: pricesType,
                        new_user_id: userId,
                        venue_id: venueId,
                        qr_flow_id: qrFlowId,
                        qr_body: qrConfig.body,
                        qr_color: qrConfig.bodyColor,
                        qr_eyes: qrConfig.eye,
                        qr_style: style,
                        description: `download - ${type} - ${qrType} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
                        descriptionQr: `download - ${userId} - ${qrFlowId} - ${qrConfig.body} - ${qrConfig.bodyColor} - ${qrConfig.eye} - ${style}`,
                    })
                    resolve("Done")
                })
        } else if (type === "png") {
            // Convert into blob and after download
            fetch(qrImage)
                .then((response) => {
                    return response.blob()
                })
                .then((blob) => {
                    const base64 = URL.createObjectURL(blob)
                    const a = document.createElement("a") //Create <a>
                    a.href = base64 //Image Base64 Goes here
                    a.download = "qr-code-image.png" //File name Here
                    a.click() //Downloaded file
                    LogEvent("qr_code_get", {
                        logo: qrType,
                        method: "download",
                        format: type,
                        prices_type: pricesType,
                        new_user_id: userId,
                        venue_id: venueId,
                        qr_flow_id: qrFlowId,
                        qr_body: qrConfig.body,
                        qr_color: qrConfig.bodyColor,
                        qr_eyes: qrConfig.eye,
                        qr_style: style,
                        description: `download - ${type} - ${qrType} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
                        qr_description: `download - ${userId} - ${qrFlowId} - ${qrConfig.body} - ${qrConfig.bodyColor} - ${qrConfig.eye} - ${style}`,
                    })
                    resolve("Done")
                })
        }
    })
}

export async function EmailQr({
    type = "pdf",
    qrImage = "",
    lang = "en",
    email = "",
    qrType = "",
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
    qrConfig = {},
    style = "normal",
}) {
    return new Promise(async(resolve, reject) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 100,
            eventName: "send email",
            userId: userId,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        // Create html to convert into PDF and get qrImage as base64
        const { html, base64 } = await createEasyPdfHtml(qrImage)

        // Config canvas options
        let html2canvasOpts = {
            dpi: 192,
            scale: 3,
            letterRendering: true,
            useCORS: true,
            width: 794,
            height: 992,
        }

        const pdfBase64 = await html2pdf()
            .set({
                html2canvas: html2canvasOpts,
                jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            })
            .from(html.children[0])
            .outputPdf("datauristring")

        const data = {
            qrCodeImg: base64.replace("data:image/png;base64,", ""),
            qrCodePdf: pdfBase64.replace(
                "data:application/pdf;filename=generated.pdf;base64,",
                ""
            ),
            lang: lang,
            type: type,
            email: email,
        }

        // Call function
        const sendQRCode = functions.httpsCallable("sendQRCodeNew")

        sendQRCode(data)
            .then(async function(result) {
                if (result.data.res[0].statusCode === 202) {
                    LogEvent("qr_code_get", {
                        logo: qrType,
                        method: "email",
                        format: type,
                        prices_type: pricesType,
                        new_user_id: userId,
                        venue_id: venueId,
                        qr_flow_id: qrFlowId,
                        qr_body: qrConfig.body,
                        qr_color: qrConfig.bodyColor,
                        qr_eyes: qrConfig.eye,
                        qr_style: style,
                        description: `email - ${type} - ${qrType} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
                        qr_description: `email - ${userId} - ${qrFlowId} - ${qrConfig.body} - ${qrConfig.bodyColor} - ${qrConfig.eye} - ${style}`,
                    })
                    openToast({
                        content: i18n.t("toastSuccessQRSent"),
                        type: SUCCESS,
                    })
                    LogEvent("toast", {
                        description: `L165 @ QrActions.js | Your QR code was sent to your email. Please check your spam folder if it doesn’t
            arrive in your inbox.`,
                    })

                    // Stop timer
                    stopTimer(interval)
                    resolve("Done")
                } else {
                    openToast({
                        content: i18n.t("toastErrorEmailNotSent"),
                        type: ERROR,
                    })

                    // Stop timer
                    stopTimer(interval)
                    resolve("Error")
                }
            })
            .catch(function(error) {
                // Save error
                openToast({
                    content: i18n.t("toastErrorEmailNotSent"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L187 @ qrActions.js | ${error.code} - ${error.message}`,
                })

                // Stop timer
                stopTimer(interval)
                resolve("Error")
            })
    })
}

export async function ShareQr({
    type = "",
    qrImage = "",
    qrType = "",
    pricesType = 6,
    userId = "",
    venueId = "",
    qrFlowId = "",
    qrConfig = {},
    style = "normal",
}) {
    return new Promise(async(resolve, reject) => {
        /* if (type === "pdf") {
            const { html } = await createEasyPdfHtml(qrImage)
                // Config canvas options
            let html2canvasOpts = {
                dpi: 192,
                scale: 4,
                letterRendering: true,
                useCORS: true,
                width: 794,
                height: 992,
            }

            // Convert into blob
            const pdfBase64 = await html2pdf()
                .set({
                    html2canvas: html2canvasOpts,
                    jsPDF: {
                        unit: "mm",
                        format: "a4",
                        orientation: "portrait",
                    },
                })
                .from(html.children[0])
                .outputPdf("datauristring")

            const response = await fetch(pdfBase64)
            const blob = await response.blob()
            const file = new File([blob], "QR_CODE.pdf", { type: blob.type })

            if (navigator.canShare && navigator.canShare({ files: [file] })) {
                navigator
                    .share({
                        files: [file],
                        title: "Pictures",
                        text: "Our Pictures.",
                    })
                    .catch((error) => {
                        if (error.code !== 20) {
                            // Save error
                            openToast({
                                content: i18n.t("toastErrorShare"),
                                type: ERROR,
                            })
                            LogEvent("new_error", {
                                description: `L273 @ qrActions.js | ${error.code} - ${error.message}`,
                            })

                            resolve("Error")
                        }
                    })
            } else {
                // Save error
                openToast({
                    content: i18n.t("toastErrorShare"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L286 @ qrActions.js | Your system doesn't support sharing files.`,
                })

                resolve("Error")
            }
        } else if (type === "png") {
            // Convert into blob
            const response = await fetch(qrImage)
            const blob = await response.blob()
            const file = new File([blob], "QR_CODE.png", { type: blob.type })

            if (navigator.canShare && navigator.canShare({ files: [file] })) {
                navigator
                    .share({
                        files: [file],
                        title: "Pictures",
                        text: "Our Pictures.",
                    })
                    .catch((error) => {
                        if (error.code !== 20) {
                            // Save error
                            openToast({
                                content: i18n.t("toastErrorEmailNotSent"),
                                type: ERROR,
                            })
                            LogEvent("new_error", {
                                description: `L311 @ qrActions.js | ${error.code} - ${error.message}`,
                            })

                            resolve("Error")
                        }
                    })
            } else {
                // Save error
                openToast({
                    content: i18n.t("toastErrorShare"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L323 @ qrActions.js | Your system doesn't support sharing files.`,
                })

                resolve("Error")
            }
        } */

        const sendWhatsApp = functions.httpsCallable("sendWhatsApp")

        if (type === "png") {
            sendWhatsApp({ url: qrImage }).then((res) => console.log(res))
        } else if (type === "pdf") {
            // Create html to convert into PDF and get qrImage as base64
            const { html } = await createEasyPdfHtml(qrImage)

            // Config canvas options
            let html2canvasOpts = {
                dpi: 192,
                scale: 3,
                letterRendering: true,
                useCORS: true,
                width: 794,
                height: 992,
            }

            const pdfBase64 = await html2pdf()
                .set({
                    html2canvas: html2canvasOpts,
                    jsPDF: {
                        unit: "mm",
                        format: "a4",
                        orientation: "portrait",
                    },
                })
                .from(html.children[0])
                .outputPdf("datauristring")

            const resPdf = await storage
                .ref()
                .child(userId + "/" + venueId + "/" + qrFlowId + "/QR/")
                .child("reminder.pdf")
                .putString(pdfBase64, "data_url")

            // URL of qr pdf
            const pdfQrURL = await resPdf.ref.getDownloadURL()

            sendWhatsApp({
                url: pdfQrURL,
            }).then((res) => console.log(res))
        }

        // Save event
        LogEvent("qr_code_get", {
            logo: qrType,
            method: "share",
            format: type,
            prices_type: pricesType,
            new_user_id: userId,
            venue_id: venueId,
            qr_flow_id: qrFlowId,
            qr_body: qrConfig.body,
            qr_color: qrConfig.bodyColor,
            qr_eyes: qrConfig.eye,
            qr_style: style,
            description: `share - ${type} - ${qrType} - ${pricesType} - ${userId} - ${venueId} - ${qrFlowId}`,
            qr_description: `share - ${userId} - ${qrFlowId} - ${qrConfig.body} - ${qrConfig.bodyColor} - ${qrConfig.eye} - ${style}`,
        })

        resolve("Done")
    })
}

export async function createEasyPdfHtml(qrImage) {
    // ------- Create element to be made into pdf and introduce the QR code
    const html = document.createElement("div")
    html.innerHTML =
        '<div class="row o-row-qr">' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        '<div class="col s4 o-col-qr"><img class="qr" width="90%" src=""></div>' +
        "</div>"

    const images = html.getElementsByTagName("img")

    qrImage = await ConvertUrlToBase64(qrImage)

    for (let i = 0; i < images.length; i++) {
        images[i].src = qrImage
    }

    return { html: html, base64: qrImage }
}