import React from "react"

/* Style */
import "./style.scss"

/* Router */
import { withRouter } from "react-router-dom"

/* Firebase */
import { db } from "controller/firebase"

const RedirectUrl = ({ history }) => {
  // Load data
  React.useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const user = urlParams.get("location")

    if (user) {
      db.collection("redirectUrl")
        .doc(user)
        .get()
        .then((res) => {
          const venueId = res.data().venueId
          const qrFlowId = res.data().qrFlowId
          if (venueId && qrFlowId) {
            history.push(`/scan/${user}/${venueId}/${qrFlowId}`)
          } else {
            history.push(`/`)
          }
        })
        .catch((error) => {
          console.log(error)
          history.push(`/`)
        })
    }
  }, [history])

  return <div className="redirect-url-container"></div>
}

export default withRouter(RedirectUrl)
