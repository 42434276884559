export const translationEs = {
    /* --------------- WORDS --------------- */
    acceptWord: "Aceptar",
    accountWord: "Cuenta",
    addressWord: "Dirección",
    adWord: "Anuncio",
    cancelWord: "Cancelar",
    changeWord: "Cambiar",
    closeWord: "Cerrar",
    codeWord: "Código",
    companyWord: "Restaurante",
    congratulationsWord: "Felicitaciones",
    continueWord: "Continuar",
    cookiesWord: "Cookies",
    customizeWord: "Personalizar",
    customWord: "Personalizado",
    downloadWord: "Descargar",
    shareWord: "Compartir",
    emailWord: "Email",
    facebookWord: "Facebook",
    freeWord:
        "Gratis" /*-Q: Donde usamos esto? Free can be translated to Gratis or Gratuito dependiendo-*/,
    helpWord: "Ayuda",
    instagramWord: "Instagram",
    loginWord: "Iniciar Sesión",
    logoWord: "Logo",
    menusWord: "Menús",
    menuWord: "Menú",
    monthWord: "mes",
    nameWord: "Nombre",
    orWord: "Ó",
    pageWord: "Página",
    passWord: "Contraseña",
    plansWord: "Planes",
    planWord: "Plan",
    previewWord: "Ver Menú",
    printWord: "Imprimir",
    recoverWord: "Recuperar",
    removeWord: "Remover",
    reportsWord: "Reportes",
    saveWord: "Guardar",
    sendWord: "enviar",
    signInWord: "Iniciar Sesión",
    signUpWord: "Crear cuenta",
    siteName: "MyFreeQr",
    socialsWord: "Redes Sociales",
    tikTokWord: "TikTok",
    timezoneWord: "Zona horaria",
    todayWord: "Hoy",
    twitterWord: "Twitter",
    usageWord: "Uso",
    whatsAppWord: "WhatsApp",
    yesterdayWord: "Ayer",
    typeWord: "Tipo",
    yesWord: "Si",
    noWord: "No",
    advertisementWord: "Publicidad",
    createYourMenuWord: "Crea tu menú QR gratis",

    /* --------------- SITE --------------- */

    /* --- Home --- */
    siteTitleHome:
        "Crear menú QR gratuito para restaurantes, bares y cafeterías",
    siteDescriptionHome:
        "Crea tu menú QR gratis para tu restaurante. Es gratis para siempre y nunca caduca. Simplemente cargue su menú y descargue su código QR.",
    siteKeyWordsHome:
        "menú qr gratis, menú qr, menú código qr, menú código qr gratis, menú qr libre dinámico, restaurantes",

    /* --- Scan --- */
    siteTitleScan: "Tu menú",
    siteDescriptionScan: "Echa un vistazo a la carta del restaurante aquí.",
    siteKeyWordsScan:
        "gratis, qr, código qr, código QR gratis, menú dinámico, restaurantes, escanear",

    /* --- Terms --- */
    siteTitleTerms: "Términos",
    siteDescriptionTerms: "Términos y privacidad de MyFreeQr",
    siteKeyWordsTerms:
        "gratis, qr, código qr, código QR gratis, menú dinámico, restaurantes, términos, privacidad",

    /* --- Profile --- */
    siteTitleProfile: "Perfil",
    siteDescriptionProfile: "Cambia la información de tu cuenta",
    siteKeyWordsProfile:
        "gratis, qr, código qr, código QR gratis, menú dinámico, restaurantes, cuenta, facturas, método de pago, productos, planes",

    /* --- Account --- */
    siteTitleAccount: "Cuenta",
    siteDescriptionAccount: "Cambia la información de tu cuenta",
    siteKeyWordsAccount:
        "gratis, qr, código qr, código QR gratis, menú dinámico, restaurantes, cuenta",

    /* --- Invoices --- */
    siteTitleInvoices: "Facturas",
    siteDescriptionInvoices: "Obtén tus facturas",
    siteKeyWordsInvoices:
        "gratis, qr, código qr, código QR gratis, menú dinámico, restaurantes, facturas, método de pago",

    /* --- Products --- */
    siteTitleProducts: "Productos",
    siteDescriptionProducts: "Actualiza tus productos",
    siteKeyWordsProducts:
        "gratis, qr, código qr, código QR gratis, menú dinámico, restaurantes, productos, planes",

    /* --- Auth --- */
    siteTitleAuth: "Autenticación",
    siteDescriptionAuth: "Crea tu Código QR para siempre en 35 segundos",
    siteKeyWordsAuth:
        "gratis, qr, código qr, código QR gratis, menú dinámico, restaurantes, autenticación",

    /* --- Dashboard --- */
    siteTitleDashboard: "Tablero",
    siteDescriptionDashboard: "Personaliza tu código QR y menú",
    siteKeyWordsDashboard:
        "gratis, qr, código qr, código QR gratis, menú dinámico, restaurantes, personalizar, páginas, archivos, imágenes",

    /* --- 404 --- */
    siteTitleNotFound: "404 - No encontrado",
    siteDescriptionNotFound: "Crea tu Código QR para siempre en 35 segundos",
    siteKeyWordsNotFound:
        "gratis, qr, código qr, código QR gratis, menú dinámico, restaurantes",

    /* --------------- PAGES --------------- */

    /* --- Home --- */
    createQRMenu: "Crear Menú QR Gratuito",

    homeTitle: "Crear menú QR gratuito para restaurantes, bares y cafeterías",
    homeSubTitlePrimary:
        "Crea tu menú QR gratis para tu restaurante. Es gratis para siempre y nunca caduca. Simplemente cargue su menú y descargue su código QR.",
    homeSubTitle:
        "Sube tu menú y descarga tu código QR en <bold>35 segundos</bold>",
    homeContent1:
        "MyFreeQr es y siempre será un menú de código QR 100% gratuito para restaurantes de todo el mundo",

    tag1: "Gratis Para Siempre",
    tag2: "Nunca Expira",
    tag3: "Menus Ilimitados",
    tag4: "Scans Ilimitados",
    tag5: "Soporte Gratuito",
    tag6: "Reportes",
    tag7: "Gratis",
    tag8: "Ilimitado",
    tag9: "Para Siempre",

    step1: "Escanea Tu Código QR",
    step2: "Anuncio de 3 Segundos",
    step3: "Observa tu Menú",

    section1Title: "Obtén Tu Menú QR Gratuito en 35 Segundos",
    section1SubTitle: "En sólo 2 simples pasos",
    section1Content1: "Sube Tu Menú",
    section1Content2: "Imprime Tu Menú QR",

    section2Title: "Menus y Scans Ilimitados",
    section2SubTitle: "Mantenga un registro se sus escaneos diarios",

    section3Title: "Cambia Tu Menú a MyFreeQr",
    section3SubTitle:
        "Creado para restaurantes, bares, y cafés en todo el mundo",
    section3Content1: "Únete a miles de restaurantes alrededor del mundo",

    section4Title: "Crea Tu Menú QR Gratuito Hoy Mismo",
    section4SubTitle: "Es fácil y gratis",
    section4Content1: "Servicio al Cliente",
    section4Content2:
        "Nuestro equipo está para ayudarte de Lunes a Domingo entre las 8:00 a.m. y las 6:00 p.m. Hora del Este.",

    /* --- Login --- */
    signInTitle: "Iniciar Sesión",
    signUpTitle: "Crear Cuenta",
    recoverPass: "Recuperar Contraseña",

    signInSubTitle:
        "Utiliza el <bold>email que ingresaste para registrarte</bold> en MyFreeQr",
    signUpSubTitle:
        "MyFreeQr es <bold>100% completamente GRATIS para siempre</bold>",

    enterEmail: "Ingresa tu email",

    googleButton: "Continuar con Google",

    rememberPass: "Recuerdas tu contraseña?",
    forgotPass: "Olvidaste tu contraseña?",
    recoverNow: "Recuperar ahora",

    createAccount: "Crear nueva cuenta",
    alreadyAccount: "Ya tienes una cuenta?",
    signInNow: "Iniciar sesión ahora",

    /* --- Dashboard --- */
    QrCode: "Ver Código QR",
    removeAds: "¿Deseas remover los anuncios de este menú?",
    removeAdsMobile: "Remover los anuncios de este menú",
    menuFreeAds: "Este menú esta libre de anuncios",

    /* --- Add Menu --- */
    addMenuTitle: "Sube Tu Menú",

    addMenuToast1Content:
        "Solo tienes que <bold>imprimir tu código QR</bold> una vez pero puedes cambiar tu menú tantas veces como quieras y se actualiza inmediatamente.",

    addMenuToast2Title: "Consejos para subir archivos",
    addMenuToast2Content1:
        "Para convertir <bold>cualquier archivo a un PDF,</bold> <a>haz click en este link</a>",
    addMenuToast2Content2:
        "Para reducir el <bold>tamaño del PDF,</bold> <a>haz click en este link</a>",

    addMenuToast3Content:
        "Por favor selecciona únicamente imagenes o únicamente un PDF. <br>Para convertir imagenes en un PDF<bold> <a1>haz click en este link.</a1></bold> <br>Si deseas convertir un PDF a imágenes<bold> <a2>haz click en este link.</a2></bold>",

    addMenuToast4Content:
        "Por favor selecciona un solo archivo PDF. Para combinar varios PDFs en un solo archivo<bold> <a>haz click en este link</a></bold>",

    chooseFiles: "Escoger archivos",
    addFiles: "Añadir archivos",
    dropFilesHere: "O arrastra archivos aquí",

    emptySectionTitle: "Tu menú saldrá aquí",
    emptySectionDescription: "Por favor sube tu menú y este aparecerá aquí",

    poweredByMyFreeQr: "Crea tu menú QR gratuito con MyFreeQr",

    // Modal update custom url
    modalPreviewMobile: "Tu Menú",

    /* --- Get QR Code --- */
    getQRCodeTitle: "Obtén Tu Código QR",

    getQRCodeToast1Content1:
        "Puedes cambiar el diseño de tu código QR para tu menú y <bold>los códigos viejos seguirán funcionando</bold> junto a los nuevos sin problema.",
    getQRCodeToast2Title: "Consejos para subir archivos",
    getQRCodeToast2Content1:
        "Para convertir un archivo <bold>a una IMAGEN,</bold> <a>haz click en este link</a>",
    getQRCodeToast3Title: "Consejos para descargar tu menú",
    getQRCodeToast3Content1:
        "Toque y mantenga presionado el código QR para guardar la imagen",

    chooseLogoDesign: "Escoger logo y diseño",

    choosePattern: "Diseño",
    chooseColor: "Color",
    chooseCorner: "Esquina",
    chooseStyle: "Estilo",
    chooseImage: "Logo",

    chooseImageButton: "Escoger imágen",
    dropImageHere: "O arrasta una imágen aquí",

    printServices: "Servicios de Impresión",

    // Modal update custom url
    modalUpdateUrlTitle: "URL Personalizada",
    modalUpdateUrlContent1:
        "<bold>Copia y pega esta URL en tus redes sociales</bold>, como Instagram, Facebook, TikTok o WhatsApp <bold>para que todos visiten tu menú.</bold>",
    modalUpdateUrlLabel: "Tu URL Personalizada",
    modalUpdateUrlLabelDescription:
        "(Esta será tu URL personalizada <bold>despues de hacer click en guardar</bold>)",

    // Modal actions
    modalActionsDownloadTitle: "Descarga Tu Código QR",
    modalActionsPrintTitle: "Imprime Tu Código QR",
    modalActionsEmailTitle: "Envía Tu Código QR",

    // Modal create account
    modalCreateAnonymousAccountSubtitle:
        "Ingresa un email y contraseña para guardar tu código QR en tu cuenta <br> <br> <bold>Sólo tienes que hacer esto una vez </bold>",

    // Modal custom qr code
    modalCustomQrCode: "Personalización",

    // Modal welcome
    modalWelcomeTitle: "Bienvenido 🎉",
    modalWelcomeContent1: "<bold>MyFreeQr es 100% GRATIS para siempre.</bold>",
    modalWelcomeContent2:
        "Nuestra misión es poder ofrecer menus QR gratuitos a cualquier restaurante que lo necesite.",
    modalWelcomeContent3:
        "<bold>Mostramos anuncios por unos segundos para cubrir nuestros costos.</bold>",
    modalWelcomeContent4:
        "Escanea el siguiente código QR para ver como funciona:",
    modalWelcomeContent5: "<bold>ESCANEAME</bold>",

    // Modal sign up
    modalSignUpTitle: "Bienvenido",
    modalSignUpSubTitle: "MyFreeQr es 100% <bold>gratis</bold> para siempre",
    modalSignUpContent1: "¿Ya tienes una cuenta? <a>Iniciar sesión</a>",
    modalSignUpContent2:
        "Unete a más de 5.000 restaurantes que utilizan MyFreeQr",

    // Modal create menu
    modalCreateMenuTitle: "Nuevo Menú = Nuevo Código QR 🎉",
    modalCreateMenuContent1:
        "Este <bold>nuevo menú</bold> tiene un <bold>nuevo código QR.</bold>",
    modalCreateMenuContent2:
        "Solo tienes que <bold>imprimir tu código QR una vez</bold> pero <bold>puedes cambiar tu menú tantas veces como quieras</bold> y se actualiza inmediatamente.",
    modalCreateMenuContent3:
        "Puedes cambiar el diseño de tu código QR para tu menú y <bold>los códigos viejos seguirán funcionando</bold> junto a los nuevos sin problema.",

    // Modal delete menu
    modalDeleteMenuTitle: "Eliminar Menú",
    modalDeleteMenuContent:
        "Si eliminas este menú, todos tus códigos asociados a el dejaran de funcionar. <br><br>Recuerda que <bold>esta acción no se puede deshacer.</bold> <br><br>¿Estás seguro que quieres eliminar <bold>{{menu}}</bold>?",

    // Modal update name menu
    modalUpdateNameMenuTitle: "Nombre del Menú",

    // Modal edit menu
    modalEditMenuTitle: "Editar Menú",
    modalEditMenuContent1: "Editar Nombre",
    modalEditMenuContent2: "Eliminar Menú",

    // Modal password
    modalPassTitle: "Ingresa Tu Contraseña",
    placeholderPass: "Tu Contraseña",

    // Modal create account
    modalCreateAccountTitle: "Crear Nueva Cuenta",
    modalCreateAccountContent:
        "Parece que el correo electrónico <bold>{{email}}</bold> no existe. ¿Le gustaría que le creáramos una nueva cuenta con este correo electrónico?",

    // Modal sign in
    modalSignInTitle: "Iniciar Sesión",
    modalSignInContent:
        "Parece que el correo electrónico <bold>{{email}}</bold> ya existe. ¿Le gustaría intentar iniciar sesión con este correo electrónico?",

    // Modal delete account
    modalDeleteAccountTitle: "Eliminar Cuenta",
    modalDeleteAccountContent:
        "Si eliminas tu cuenta todos tus códigos QR e información se perderán. <br> <br>Recuerda que <bold>esta acción no se puede deshacer.</bold> <br> <br>¿Estás seguro que deseas eliminar tu cuenta?",

    // Modal reset password
    modalResetPasswordTitle: "Crear Contraseña",
    modalResetPasswordContent:
        "Esta acción requiere tener una contraseña para continuar. <br/><br/> Haga clic a continuación para enviarle las instrucciones a su correo electrónico para crear su contraseña.",
    modalResetPasswordButton: "Enviar Email",

    // Modal pay qr
    modalSelectPayQrTittle1: "Remover Anuncios",
    modalSelectPayQrTittle2:
        "MyFreeQr es 100% gratis para siempre. Opcionalmente puedes pagar para remover los anuncios, pero no es necesario.",
    modalSelectPayQrSubTittle: "{{count}} mes",
    modalSelectPayQrSubTittle_plural: "{{count}} meses",
    modalSelectPayQrContent1:
        "Este plan removerá los anuncios del <bold>{{menu}}</bold> durante <bold>{{count}} mes</bold>, por un total de <bold>{{price}} USD.</bold>",
    modalSelectPayQrContent1_plural:
        "Este plan removerá los anuncios del <bold>{{menu}}</bold> durante <bold>{{count}} meses</bold>, por un total de <bold>{{price}} USD.</bold>",
    modalSelectPayQrContent2: "Seleccione el número de meses:",
    modalSelectPayQrConfirm: "Pagar",
    modalSelectPayQrCancel: "Cancelar",

    // Modal confirm pay qr
    modalConfirmSelectPayQrTitle: "Confirmar Orden",
    modalConfirmSelectPayQrContent1:
        "Haga clic en continuar para proceder con el pago de <bold>{{price}} USD,</bold> para eliminar los anuncions del <bold>{{menu}}</bold> por un período de <bold>{{count}} mes</bold>",
    modalConfirmSelectPayQrContent1_plural:
        "Haga clic en continuar para proceder con el pago de <bold>{{price}} USD,</bold> para eliminar los anuncions del <bold>{{menu}}</bold> por un período de <bold>{{count}} meses</bold>",
    modalConfirmSelectPayQrConfirm: "Continuar",
    modalConfirmSelectPayQrCancel: "Cancelar",

    // Modal stripe
    modalStripeTittle: "Remover Anuncios",
    modalStripeSubTittle: "{{count}} mes",
    modalStripeSubTittle_plural: "{{count}} meses",
    modalStripeContent1:
        "Este plan removerá los anuncios del <bold>{{menu}}</bold> durante <bold>{{count}} mes</bold>, por un total de <bold>{{price}} USD.</bold>",
    modalStripeContent1_plural:
        "Este plan removerá los anuncios del <bold>{{menu}}</bold> durante <bold>{{count}} meses</bold>, por un total de <bold>{{price}} USD.</bold>",
    modalStripeContent2:
        "¿Quieres confirmar tu compra usando este medio de pago?",
    modalStripeConfirm: "Pagar",
    modalStripeCancel: "Cancelar",

    // Modal loading stripe
    modalStripeLoadingTittle: "Estamos Creando Tu Pago",

    // Modal Pay Method
    modalPayMethodTittle: "Remover Anuncios",
    modalPayMethodSubTittle: "{{count}} mes",
    modalPayMethodSubTittle_plural: "{{count}} meses",
    modalPayMethodContent1:
        "Este plan removerá los anuncios del <bold>{{menu}}</bold> durante <bold>{{count}} mes</bold>, por un total de <bold>{{price}} USD.</bold>",
    modalPayMethodContent1_plural:
        "Este plan removerá los anuncios del <bold>{{menu}}</bold> durante <bold>{{count}} meses</bold>, por un total de <bold>{{price}} USD.</bold>",
    modalPayMethodContent2: "Usaremos siguiente método de pago para tu compra:",
    modalPayMethodContent3:
        "<bold>{{brand}}</bold> Finalizada en <bold>{{last4}}</bold> Expira el <bold>{{month}}/{{year}}</bold>",
    modalPayMethodContent4:
        "¿Deseas confirmar tu compra usando este mismo medio de pago?",
    modalPayMethodConfirm: "Pagar",
    modalPayMethodAnotherMethod: "Nuevo Método de pago",

    // Modal delete payment method
    modalDeletePaymentMethodTittle: "Elminar Método de Pago",
    modalDeletePaymentMethodContent1:
        "Si eliminas tu método de pago, deberás agregar uno nuevo para tu próxima compra.",
    modalDeletePaymentMethodContent2:
        "<bold>{{brand}}</bold> Finalizada en <bold>{{last4}}</bold> Expira el <bold>{{month}}/{{year}}</bold>",
    modalDeletePaymentMethodContent3:
        "Recuerda que <bold>esta acción no se puede deshacer.</bold> <br/> <br/> ¿Estás seguro que deseas eliminar este método de pago?",

    /* --- Reports Scan --- */
    reportsScanTitle: "Scans",

    reportsScanToast1Content:
        "Los reportes de scans se actualizan una vez al día. <bold>Los scans de hoy aparecerán mañana.</bold>",

    numberScans: "Número de Scans",
    last7Days: "Últimos 7 días",
    lastWeek: "Última semana",
    last30Days: "Últimos 30 días",
    lastMonth: "Último mes calendario",

    sun: "Dom",
    mon: "Lun",
    tue: "Mar",
    wed: "Mie",
    thu: "Jue",
    fri: "Vie",
    sat: "Sab",

    /* --- Page 404 --- */
    pageNotFound: "Esta página no pudo ser encontrada",
    goToHome: "Ir a inicio",

    /* --- Menu Not Found --- */
    menuNotFound:
        "El código QR no pudo ser encontrado.<br><br>Si crees que es un error inténtalo nuevamente.",

    /* --- Terms --- */
    "pt-section-privacy-title": "Política de Privacidad",
    "pt-section-privacy-text-1":
        "MyFreeQr  proporciona códigos QR gratuitos, formularios de recopilación de datos y otros servicios a usuarios de todo el mundo. Nuestra Política de privacidad ayuda a explicar nuestras prácticas de información. Por ejemplo, hablamos sobre qué información recopilar y cómo esto le afecta. También explicamos los pasos que tomamos para proteger su privacidad, como crear MyFreeQr  para que la información de contacto de los usuarios se almacene en su teléfono en todo momento y solo se comparta cuando se inscriba explícitamente en un establecimiento determinado. ",
    "pt-section-privacy-text-2":
        "Cuando decimos 'MyFreeQr ', 'nuestro', 'nosotros' o 'nos', estamos hablando de SuperQr Ltd. Esta Política de privacidad ('Política de privacidad') se aplica a todas nuestras aplicaciones, servicios, funciones, software y sitio web (en conjunto, 'Servicios') a menos que se especifique lo contrario. ",
    "pt-section-privacy-text-3":
        "Lea también nuestros Términos de servicio ('Términos'), que describen los términos bajo los cuales utiliza nuestros Servicios.",
    "pt-section-privacy-information-we-collect-title":
        "Información que recopilamos",
    "pt-section-privacy-information-we-collect-p":
        "MyFreeQr  recibe o recopila información cuando operamos y brindamos nuestros Servicios, incluso cuando usted instala, accede o usa nuestros Servicios",
    "pt-section-privacy-information-you-provide-title":
        "Información que proporciona",
    "pt-section-privacy-your-account-information": "Información de su cuenta",
    "pt-section-privacy-your-account-information-desc":
        "Usted proporciona su dirección de correo electrónico para crear una cuenta MyFreeQr . También puede agregar otra información a su cuenta, como un nombre, logotipo y mensaje diario",
    "pt-section-privacy-your-patrons": "Tus usuarios",
    "pt-section-privacy-your-patrons-desc":
        "No retenemos la información de sus usuarios por más tiempo de lo requerido por la ley para fines de rastreo de contactos si la ley lo especifica y usted opta por esta función opcional. Podemos mantenerla en nuestros servidores por hasta 30 días mientras tratamos de cumplir con la regulación en evolución debido a la situación de la pandemia global. Si la información no es requerida por las autoridades 30 días después del período de retención obligatorio, la eliminamos. ",
    "pt-section-privacy-your-pictures": "Tus imágenes",
    "pt-section-privacy-your-pictures-desc":
        "Para ayudarlo a mostrar su menú de manera fácil y eficiente, podemos cambiar el tamaño o comprimir su menú antes de almacenarlo en nuestros servidores. Lo mismo se aplica a su logotipo.",
    "pt-section-privacy-customer-support": "Atención al cliente",
    "pt-section-privacy-customer-support-desc":
        "Puede proporcionarnos información relacionada con el uso de nuestros Servicios, incluidas copias de su menú, información de contacto de los usuarios y cómo contactarlo para que podamos proporcionarle soporte. Por ejemplo, puede enviarnos un correo electrónico con información relacionada con el rendimiento de nuestra aplicación u otros problemas. ",
    "pt-section-privacy-auto-collected-info-title":
        "Información recopilada automáticamente",
    "pt-section-privacy-usage-and-log": "Información de uso y registro",
    "pt-section-privacy-usage-and-log-desc":
        "Recopilamos información relacionada con el servicio, de diagnóstico y de rendimiento. Esto incluye información sobre su actividad (por ejemplo, cómo usa nuestros Servicios, cómo interactúa con otros usando nuestros Servicios y similares), archivos de registro y registros e informes de diagnóstico, fallos, sitios web y rendimiento. ",
    "pt-section-privacy-device-and-connection-info":
        "Información del dispositivo y la conexión",
    "pt-section-privacy-device-and-connection-info-desc":
        "Recopilamos información específica del dispositivo cuando instala, accede o usa nuestros Servicios. Esto incluye información como el modelo de hardware, información del sistema operativo, información del navegador , Dirección IP, información de la red móvil e identificadores de dispositivos. ",
    "pt-section-privacy-cookies": "Cookies.",
    "pt-section-privacy-cookies-desc":
        "Usamos cookies para operar y proporcionar nuestros Servicios, incluso para proporcionar nuestros Servicios basados ​​en la web, mejorar sus experiencias, comprender cómo se utilizan nuestros Servicios y personalizar nuestros Servicios . Por ejemplo, utilizamos cookies para proporcionar MyFreeQr  para la web y el escritorio y otros servicios basados ​​en la web. También podemos utilizar cookies para comprender cuáles de nuestras preguntas frecuentes son más populares y para mostrarle contenido relevante relacionado con nuestros Servicios. Además, podemos use cookies para recordar sus opciones, como sus preferencias de idioma, nombre y teléfono para registrarse, y de otro modo para personalizar nuestros Servicios para usted. Obtenga más información sobre cómo usamos las cookies para brindarle nuestros Servicios. ",
    "pt-section-privacy-third-party-info-title": "Información de terceros",
    "pt-section-privacy-third-party-providers": "Proveedores de terceros",
    "pt-section-privacy-third-party-providers-desc":
        "Trabajamos con proveedores externos para ayudarnos a operar, proporcionar, mejorar, comprender, personalizar, respaldar y comercializar nuestros Servicios. Por ejemplo, trabajamos con empresas para distribuir nuestras aplicaciones web, proporcionar nuestra infraestructura, entrega y otros sistemas, suministrar información de mapas y lugares, procesar pagos, ayudarnos a comprender cómo las personas usan nuestros Servicios y comercializar nuestros Servicios. Estos proveedores pueden proporcionarnos información sobre en determinadas circunstancias; por ejemplo, las tiendas de aplicaciones pueden proporcionarnos informes para ayudarnos a diagnosticar y solucionar problemas de servicio. ",
    "pt-section-privacy-third-party-services": "Servicios de terceros",
    "pt-section-privacy-third-party-services-desc":
        "Le permitimos usar nuestros Servicios en conexión con servicios de terceros. Si usa nuestros Servicios con dichos servicios de terceros, podemos recibir información sobre usted de ellos; por ejemplo, si usa el botón para compartir de MyFreeQr  en un servicio de noticias para compartir un artículo de noticias con sus contactos, grupos o listas de transmisión de MyFreeQr  en nuestros Servicios, o si elige acceder a nuestros Servicios a través de un operador o dispositivo móvil promoción de nuestros Servicios por parte del proveedor. Tenga en cuenta que cuando utilice servicios de terceros, sus propios términos y políticas de privacidad regirán el uso de esos servicios. ",
    "pt-section-privacy-how-use-info-title": "Cómo usamos la información",
    "pt-section-privacy-how-use-info-subtitle":
        "Usamos toda la información que tenemos para ayudarnos a operar, proporcionar, mejorar, comprender, personalizar, respaldar y comercializar nuestros Servicios",
    "pt-section-privacy-our-services": "Nuestros servicios",
    "pt-section-privacy-our-services-desc":
        "Operamos y proporcionamos nuestros Servicios, incluida la asistencia al cliente, y la mejora, reparación y personalización de nuestros Servicios. Entendemos cómo las personas utilizan nuestros Servicios, y analizamos y utilizan los información que tenemos para evaluar y mejorar nuestros Servicios, investigar, desarrollar y probar nuevos servicios y funciones, y realizar actividades de resolución de problemas. También usamos su información para responderle cuando se comunica con nosotros. Utilizamos cookies para operar, proporcionar, mejorar, comprender y personalizar nuestros Servicios. ",
    "pt-section-privacy-safety-security": "Seguridad y protección",
    "pt-section-privacy-safety-security-desc":
        "Verificamos las cuentas y la actividad, y promovemos la seguridad dentro y fuera de nuestros Servicios, por ejemplo, investigando actividades sospechosas o violaciones de nuestros Términos, y para asegurarnos de que nuestros Servicios sean siendo utilizado legalmente. ",
    "pt-section-privacy-comns-about-our-services":
        "Comunicaciones sobre nuestros servicios",
    "pt-section-privacy-comns-about-our-services-desc":
        "Nos comunicamos con usted acerca de nuestros Servicios y funciones y le informamos sobre nuestros términos y políticas y otras actualizaciones importantes",
    "pt-section-privacy-info-we-share-title":
        "Información que usted y nosotros compartimos",
    "pt-section-privacy-info-we-share-subtitle":
        "Usted comparte su información mientras usa y se comunica a través de nuestros Servicios, y nosotros compartimos su información para ayudarnos a operar, proporcionar, mejorar, comprender, personalizar, brindar soporte, y comercializar nuestros Servicios. ",
    "pt-section-privacy-account-info": "Información de la cuenta",
    "pt-section-privacy-account-info-desc":
        "Su correo electrónico, número de nombre del establecimiento, logotipo, menú, mensaje diario, estado activo de seguimiento del contrato y libro de visitas. En particular, su nombre, logotipo, menú y mensaje diario puede estar disponible para cualquier persona que utilice nuestros Servicios. ",
    "pt-section-privacy-contacts-and-others": "Tus contactos y otros",
    "pt-section-privacy-contacts-and-others-desc":
        "Los usuarios con los que se comunica pueden almacenar o compartir su información con otros dentro y fuera de nuestros Servicios",
    "pt-section-privacy-manage-your-info-title":
        "Cómo administrar su información",
    "pt-section-privacy-manage-your-info-subtitle":
        "Si desea administrar, cambiar, limitar o eliminar su información, le permitimos hacerlo a través de las siguientes herramientas:",
    "pt-section-privacy-change-email-pass":
        "Cambio de correo electrónico, contraseña, nombre, menú del logotipo y mensaje diario",
    "pt-section-privacy-change-email-pass-desc":
        "Puede cambiar cualquiera de los anteriores en cualquier momento en su cuenta o panel de control.",
    "pt-section-privacy-deleting-account": "Eliminando su cuenta MyFreeQr ",
    "pt-section-privacy-deleting-account-desc-1":
        "Puede eliminar su cuenta MyFreeQr  en cualquier momento (incluso si desea revocar su consentimiento para el uso de su información) enviándonos un correo electrónico a",
    "pt-section-privacy-deleting-account-desc-2":
        "Cuando elimine su cuenta MyFreeQr , se eliminará cualquier información que ya no necesitemos para operar y brindar nuestros Servicios. Recuerde que cuando elimine su cuenta , no afecta la información que otros usuarios tienen sobre usted, como las copias que puedan tener de la información que usted les envió. ",
    "pt-section-privacy-law-and-protection-title": "Ley y protección",
    "pt-section-privacy-law-and-protection-subtitle":
        "Podemos recopilar, usar, preservar y compartir su información si creemos de buena fe que es razonablemente necesario para: (a) responder de conformidad con leyes o reglamentos aplicables, procesos legales o solicitudes gubernamentales; (b) hacer cumplir nuestros Términos y cualquier otro término y política aplicable, incluso para investigaciones de posibles infracciones; (c) detectar, investigar, prevenir y abordar el fraude y otros actividad, seguridad o problemas técnicos; o (d) proteger los derechos, la propiedad y la seguridad de nuestros usuarios, MyFreeQr  u otros. ",
    "pt-section-privacy-updates-policy-title":
        "Actualizaciones de nuestra política",
    "pt-section-privacy-updates-policy-subtitle":
        "Podemos modificar o actualizar nuestra Política de privacidad. Le notificaremos las enmiendas a esta Política de privacidad, según corresponda, y actualizaremos la fecha de 'Última modificación' en la parte superior de esta Política de privacidad. Su uso continuado de nuestros Servicios confirma su aceptación de nuestra Política de privacidad, en su forma enmendada. Si no está de acuerdo con nuestra Política de privacidad, en su forma enmendada, debe dejar de usar nuestros Servicios. Por favor, revise nuestra Política de privacidad de vez en cuando.",
    "pt-section-privacy-contact-us-title": "Contáctenos",
    "pt-section-privacy-contact-us-subtitle":
        "Si tiene preguntas sobre nuestra Política de privacidad, comuníquese con nosotros",
    "pt-terms-of-service-title": "Condiciones de servicio",
    "pt-terms-of-service-subtitle":
        "SuperQr Ltd. ('MyFreeQr ' 'nuestro,' nosotros 'o' nos ') proporciona menús QR sin contacto, formularios de recopilación de datos y otros servicios a los usuarios de todo el mundo. Lea nuestros Términos de servicio para comprender qué sucede con el uso de MyFreeQr . Acepta nuestros Términos de servicio ('Términos') al instalar, acceder o usar nuestras aplicaciones, servicios, funciones, software o sitio web ( juntos, 'Servicios'). ",
    "pt-terms-of-service-about-our-services-title":
        "Acerca de nuestros servicios",
    "pt-terms-of-service-registration": "Registro",
    "pt-terms-of-service-registration-desc":
        "Debe registrarse en nuestros Servicios utilizando datos precisos, proporcionar su correo electrónico actual y, si lo cambia, actualizar este correo electrónico utilizando nuestra función de cambio de correo electrónico en la aplicación. acepta recibir mensajes de correo electrónico (de nosotros o de nuestros proveedores externos). ",
    "pt-terms-of-service-age": "Edad",
    "pt-terms-of-service-age-desc":
        "Debe tener al menos 18 años para usar nuestros Servicios (o la edad requerida en su país para que esté autorizado a usar nuestros Servicios sin la aprobación de los padres). Además de tener la edad mínima requerida para usar nuestros Servicios según la ley aplicable, si no tiene la edad suficiente para tener la autoridad para aceptar nuestros Términos en su país, su padre o tutor debe aceptar nuestros Términos en su nombre. ",
    "pt-terms-of-service-devices-software": "Dispositivos y software",
    "pt-terms-of-service-devices-software-desc":
        "Debe proporcionar ciertos dispositivos, software y conexiones de datos para utilizar nuestros Servicios, que de otro modo no proporcionamos. Mientras utilice nuestros Servicios, consiente la descarga e instalación de actualizaciones de nuestros Servicios, incluso automáticamente.",
    "pt-terms-of-service-fees-taxes": "Tasas e impuestos",
    "pt-terms-of-service-fees-taxes-desc":
        "Usted es responsable de todo el plan de datos del operador y otras tarifas e impuestos asociados con el uso de nuestros Servicios. Podemos cobrarle por nuestros Servicios, incluidos los impuestos aplicables. Podemos rechazar o cancelar pedidos. No proporcionamos reembolsos por nuestros Servicios, excepto según lo exija la ley. ",
    "pt-terms-of-service-user-data-title":
        "Política de privacidad y datos del usuario",
    "pt-terms-of-service-user-data-subtitle":
        "MyFreeQr  se preocupa por su privacidad. La Política de privacidad de MyFreeQr  describe nuestras prácticas de información (incluido el mensaje), incluidos los tipos de información que recibimos y recopilamos de usted y cómo usamos y compartir esta información. Usted acepta nuestras prácticas de datos, incluida la recopilación, el uso, el procesamiento y el intercambio de su información como se describe en nuestra Política de privacidad, así como la transferencia y el procesamiento de su información a los Estados Unidos y otros países a nivel mundial donde tenemos o usamos instalaciones, proveedores de servicios o socios, independientemente de dónde use nuestros Servicios. Usted reconoce que las leyes, regulaciones y estándares del país en el que se almacena o procesa su información pueden ser diferentes de las suyas país.",
    "pt-terms-of-service-acceptable-use": "Uso aceptable de nuestros servicios",
    "pt-terms-of-service-terms-and-policies":
        "Nuestras condiciones y políticas",
    "pt-terms-of-service-terms-and-policies-desc":
        "Debe utilizar nuestros Servicios de acuerdo con nuestros Términos y políticas publicadas. Si inhabilitamos su cuenta por una violación de nuestros Términos, no creará otra cuenta sin nuestro permiso. ",
    "pt-terms-of-service-legal-acceptable-use": "Uso legal y aceptable",
    "pt-terms-of-service-legal-acceptable-use-desc":
        "Debe acceder y utilizar nuestros Servicios solo con fines legales, autorizados y aceptables. No utilizará (ni ayudará a otros a utilizar) nuestros Servicios en formas que: (a) violen, se apropien indebidamente o infrinjan los derechos de MyFreeQr , nuestros usuarios u otros, incluida la privacidad, publicidad, propiedad intelectual u otros derechos de propiedad; (b) sean ilegales, obscenos, difamatorios, amenazantes, intimidatorios, acosador, odioso, racial o étnicamente ofensivo, o instigar o alentar conductas que serían ilegales o inapropiadas, incluida la promoción de delitos violentos; (c) involucrar la publicación de falsedades, tergiversaciones o declaraciones engañosas; (d) hacerse pasar por alguien; (e ) implican el envío de comunicaciones ilegales o no permitidas, como mensajes masivos, mensajes automáticos, marcación automática y similares; o (f) implican cualquier uso no personal de nuestros Servicios a menos que lo autoricemos de otra manera. ",
    "pt-terms-of-service-harm-to-us-or-users":
        "Daño a MyFreeQr  o nuestros usuarios",
    "pt-terms-of-service-harm-to-us-or-users-desc":
        "No debe (o ayudar a otros a) acceder, usar, copiar, adaptar, modificar, preparar trabajos derivados basados ​​en, distribuir, otorgar licencia, sublicenciar, transferir, mostrar, ejecutar o explotar nuestros Servicios de manera no permitida o no autorizada, o de formas que carguen, perjudiquen o dañen a nosotros, nuestros Servicios, sistemas, nuestros usuarios u otros, incluso que usted no debe directamente oa través de medios automatizados: (a) realizar ingeniería inversa, alterar, modificar, crear trabajos derivados, descompilar o extraer código de nuestros Servicios; (b) enviar, almacenar o transmitir virus u otro código informático dañino a través de nuestros Servicios o hacia ellos; (c) obtener o intentar obtener acceso no autorizado a nuestros Servicios o sistemas; (d) interferir o interrumpir la integridad o el desempeño de nuestros Servicios; (e) crear cuentas para nuestros Servicios a través de medios no autorizados o automatizados; (f) recopilar el información de o sobre nuestros usuarios de cualquier manera no permitida o no autorizada; (g) vender, revender, alquilar o cobrar por nuestros Servicios; o (h) distribuir o hacer que nuestros Servicios estén disponibles a través de una red donde puedan ser utilizados por múltiples dispositivos al mismo tiempo. ",
    "pt-terms-of-service-keep-account-secure": "Cómo mantener segura su cuenta",
    "pt-terms-of-service-keep-account-secure-desc":
        "Usted es responsable de mantener su dispositivo y su cuenta MyFreeQr  seguros y protegidos, y debe notificarnos de inmediato sobre cualquier uso no autorizado o violación de seguridad de su cuenta o nuestros Servicios. ",
    "pt-terms-of-service-third-party-services-title": "Servicios de terceros",
    "pt-terms-of-service-third-party-services-subtitle":
        "Nuestros Servicios pueden permitirle acceder, usar o interactuar con sitios web, aplicaciones, contenido y otros productos y servicios de terceros. Tenga en cuenta que cuando utiliza servicios de terceros, sus propios términos y políticas de privacidad regirán el uso de esos servicios. ",
    "pt-terms-of-service-licenses": "Licencias",
    "pt-terms-of-service-your-rights":
        "Sus derechos. MyFreeQr  no reclama la propiedad de la información que envía a su cuenta MyFreeQr  oa través de nuestros Servicios. Debe tener los derechos necesarios sobre la información que envíe para su cuenta MyFreeQr  oa través de nuestros Servicios y el derecho a otorgar los derechos y licencias en nuestros Términos. ",
    "pt-terms-of-service-our-rights":
        "Derechos de MyFreeQr . Somos propietarios de todos los derechos de autor, marcas comerciales, dominios, logotipos, imagen comercial, secretos comerciales, patentes y otros derechos de propiedad intelectual asociados con nuestros Servicios.",
    "pt-terms-of-service-license-to-us":
        "Su licencia de MyFreeQr . Para operar y proporcionar nuestros Servicios, usted otorga a MyFreeQr  una licencia mundial, no exclusiva, libre de regalías, sublicenciable y transferible para usar, reproducir, distribuir, crear trabajos derivados, mostrar y ejecutar la información (incluido el contenido) que carga, envía, almacena, envía o recibe en nuestros Servicios oa través de ellos. Los derechos que otorga en esta licencia son para el propósito limitado de operar y proporcionar nuestros Servicios (por ejemplo, permitirnos mostrar su nombre, menú, logotipo y mensaje diario, transmitir esta información a los usuarios y de otra manera, como se describe en nuestra Política de privacidad). ",
    "pt-terms-of-service-license-to-you":
        "Licencia de MyFreeQr  para usted. Le otorgamos una licencia limitada, revocable, no exclusiva, no sublicenciable e intransferible para utilizar nuestros Servicios, sujeto a y de acuerdo con nuestros Términos. Esta licencia tiene el único propósito de permitirle utilizar nuestros Servicios, de la manera permitida por nuestros Términos. No se le otorgan licencias o derechos por implicación o de otro modo, excepto por las licencias y derechos expresamente concedido a usted. ",
    "pt-terms-of-service-reporting-third-party-copyright-title":
        "Informar de una infracción de derechos de autor, marca registrada y otra propiedad intelectual de terceros",
    "pt-terms-of-service-reporting-third-party-copyright-subtitle":
        "Para informar reclamaciones de derechos de autor, marcas comerciales u otra infracción de propiedad intelectual de terceros, envíenos un correo electrónico a",
    "pt-terms-of-service-disclaimers-title": "Exenciones de responsabilidad",
    "pt-terms-of-service-disclaimers-subtitle":
        "USTED UTILIZA NUESTROS SERVICIOS BAJO SU PROPIO RIESGO Y SUJETO A LAS SIGUIENTES EXENCIONES DE RESPONSABILIDAD. ESTAMOS PROPORCIONANDO NUESTROS SERVICIOS  'TAL CUAL' SIN NINGUNA GARANTÍA EXPRESA O IMPLÍCITA, INCLUYENDO , PERO NO LIMITADO A, GARANTÍAS DE COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO PARTICULAR, TÍTULO, NO INFRACCIÓN Y LIBRE DEL VIRUS DE LA COMPUTADORA U OTRO CÓDIGO DAÑINO. NO GARANTIZAMOS QUE LA INFORMACIÓN PROPORCIONADA POR NOSOTROS SEA EXACTA, COMPLETA O ÚTIL. QUE NUESTROS SERVICIOS SERÁN OPERACIONALES, LIBRES DE ERRORES, SEGUROS O SEGUROS, O QUE NUESTROS SERVICIOS FUNCIONARÁN SIN INTERRUPCIONES, RETRASOS O IMPERFECCIONES. NO CONTROLAMOS, Y NO SOMOS RESPONSABLES DE, CONTROLAR CÓMO O CUÁNDO NUESTROS USUARIOS UTILIZAN NUESTROS SERVICIOS O LAS CARACTERÍSTICAS, SERVICIOS E INTERFACES QUE PROPORCIONAN NUESTROS SERVICIOS. NO SOMOS RESPONSABLES Y NO ESTAMOS OBLIGADOS A CONTROLAR LAS ACCIONES O LA INFORMACIÓN (INCLUYENDO EL CONTENIDO) DE NUESTROS USUARIOS U OTROS TERCEROS. USTED NOS LIBERA A NOSOTROS, NUESTRAS SUBSIDIARIAS, AFILIADOS Y NUESTROS Y LA DIRECTORES, FUNCIONARIOS, EMPLEADOS, SOCIOS Y AGENTES DE IR (JUNTOS, LAS 'PARTES DE MyFreeQr ') DE CUALQUIER RECLAMO, QUEJA, CAUSA DE ACCIÓN, CONTROVERSIA O DISPUTA (JUNTOS, 'RECLAMO') Y DAÑOS, CONOCIDOS Y DESCONOCIDOS , QUE SURJA DE, O ESTÉ DE ALGUNA MANERA RELACIONADA CON DICHA RECLAMACIÓN QUE USTED TIENE CONTRA TERCEROS. USTED RENUNCIA A CUALQUIER DERECHO QUE PUEDA TENER BAJO EL CÓDIGO CIVIL DE CALIFORNIA §1542, O CUALQUIER OTRO ESTATUTO O LEY APLICABLE SIMILAR DE CUALQUIER OTRA JURISDICCIÓN, QUE DICE QUE: UNA LIBERACIÓN GENERAL NO SE EXTIENDE A RECLAMACIONES QUE EL ACREEDOR NO CONOCE O SOSPECHA SU FAVOR AL MOMENTO DE EJECUTAR LA LIBERACIÓN, QUE SI LO CONOCÍA ELLA O ELLA DEBERÁ HABER AFECTADO MATERIALMENTE SU ACUERDO CON EL DEUDOR. ",
    "pt-terms-of-service-limitation-liability-title":
        "Limitación de responsabilidad",
    "pt-terms-of-service-limitation-liability-subtitle":
        "LAS PARTES DE MyFreeQr  NO SERÁN RESPONSABLES ANTE USTED POR LA PÉRDIDA DE BENEFICIOS O DAÑOS CONSECUENTES, ESPECIALES, PUNITIVOS, INDIRECTOS O INCIDENTALES RELACIONADOS CON, QUE SURJAN O DE CUALQUIER MANERA EN RELACIÓN CON NUESTROS TÉRMINOS, NOSOTROS O NUESTROS SERVICIOS, INCLUSO SI LAS PARTES DE MyFreeQr  HAN SIDO ADVERTIDAS DE LA POSIBILIDAD DE DICHOS DAÑOS. NUESTRA RESPONSABILIDAD AGREGADA RELACIONADA CON NUESTROS TÉRMINOS O DE CUALQUIER MODO EN RELACIÓN CON NOSOTROS O NUESTROS SERVICIOS NO EXCEDERÁN EL MAYOR DE CIEN DÓLARES (USD 100) O LA CANTIDAD QUE NOS HA PAGADO EN LOS ÚLTIMOS DOCE MESES. LA RENUNCIA ANTERIOR DE CIERTOS DAÑOS Y LIMITACIÓN DE RESPONSABILIDAD SE APLICARÁ EN LA MEDIDA MÁXIMA PERMITIDA POR LEY. LAS LEYES DE ALGUNOS ESTADOS O JURISDICCIONES PUEDEN NO PERMITIR LA EXCLUSIÓN O LIMITACIÓN DE CIERTOS DAÑOS, POR LO QUE ALGUNAS O TODAS LAS EXCLUSIONES Y LIMITACIONES ESTABLECIDAS ANTERIORMENTE PUEDEN NO APLICARSE EN SU CASO. , LA RESPONSABILIDAD DE LAS PARTES DE MyFreeQr  SE LIMITARÁ A LA MEDIDA MÁXIMA PERMITIDA POR LA LEY APLICABLE. ",
    "pt-terms-of-service-indemnification-title": "Indemnización",
    "pt-terms-of-service-indemnification-subtitle":
        "Usted acepta defender, indemnizar y eximir de responsabilidad a las Partes de MyFreeQr  frente a todas las responsabilidades, daños, pérdidas y gastos de cualquier tipo (incluidos los honorarios y costos legales razonables ) relacionado con, que surja de, o de alguna manera en conexión con cualquiera de los siguientes: (a) su acceso o uso de nuestros Servicios, incluida la información proporcionada en relación con los mismos; (b) su incumplimiento o presunto incumplimiento de nuestros Términos ; o (c) cualquier tergiversación realizada por usted. Cooperará tan plenamente como lo solicitemos en la defensa o resolución de cualquier Reclamación. ",
    "pt-terms-of-service-dispute-resolution-title": "Resolución de disputas",
    "pt-terms-of-service-form-venue": "Foro y lugar",
    "pt-terms-of-service-form-venue-desc":
        "Si es un usuario de MyFreeQr  ubicado en los Estados Unidos o Canadá, la sección 'Disposición especial de arbitraje para usuarios de Estados Unidos o Canadá' a continuación se aplica a usted. Por favor Lea también esa sección con atención y en su totalidad. Si no está sujeto a la sección 'Disposición especial de arbitraje para usuarios de Estados Unidos o Canadá' a continuación, acepta que usted y MyFreeQr  resolverán cualquier Reclamación relacionada con, que surja de, o de alguna manera en relación con nuestros Términos, nosotros o nuestros Servicios (cada uno, una 'Disputa' y, en conjunto, 'Disputas') exclusivamente en los tribunales del Reino Unido, y usted acepta someterse a la jurisdicción personal de dichos tribunales con el propósito de litigar todas esas Disputas. Sin perjuicio de lo anterior, usted acepta que, a nuestro exclusivo criterio, podemos optar por resolver cualquier Disputa que tengamos con usted en cualquier tribunal competente del país en el que reside que tenga jurisdicción sobre la Disputa. ",
    "pt-terms-of-service-governing-law": "Ley aplicable.",
    "pt-terms-of-service-governing-law-desc":
        "Las leyes del Reino Unido rigen nuestros Términos, así como cualquier Disputa, ya sea en un tribunal o arbitraje, que pueda surgir entre MyFreeQr  y usted, sin tener en cuenta disposiciones sobre conflicto de leyes. ",
    "pt-terms-of-service-language-clause": "Cláusula de idioma",
    "pt-terms-of-service-language-clause-desc":
        "El texto completo del presente Contrato, así como los documentos derivados del mismo, incluidos los de los Anexos, se han redactado en varios idiomas, siendo todas las versiones se considera auténtico, pero a efectos legales, el texto en inglés debe tener prioridad de interpretación. ",
    "pt-terms-of-service-availability-termination":
        "Disponibilidad y terminación de nuestros servicios",
    "pt-terms-of-service-availability-services":
        "Disponibilidad de nuestros servicios",
    "pt-terms-of-service-availability-services-desc":
        "Nuestros Servicios pueden ser interrumpidos, incluso por mantenimiento, reparaciones, actualizaciones o fallas en la red o el equipo. Podemos descontinuar algunos o todos nuestros Servicios, incluidas ciertas características y el soporte para ciertos dispositivos y plataformas, en cualquier momento. Eventos fuera de nuestro control pueden afectar nuestros Servicios, como eventos en la naturaleza y otros eventos de fuerza mayor. ",
    "pt-terms-of-service-termination": "Terminación.",
    "pt-terms-of-service-termination-desc":
        "Podemos modificar, suspender o cancelar su acceso o uso de nuestros Servicios en cualquier momento y por cualquier motivo, como si viola la letra o el espíritu de nuestros Términos o crea daño, riesgo o posible exposición legal para nosotros, nuestros usuarios u otros. Las siguientes disposiciones sobrevivirán a la terminación de su relación con MyFreeQr : 'Licencias', 'Renuncias', 'Limitación de responsabilidad', 'Indemnización', 'Disputa Resolución, '' Disponibilidad y terminación de nuestros servicios, '' Otros 'y' Disposición especial de arbitraje para usuarios de Estados Unidos o Canadá '",
    "pt-terms-of-service-other": "Otro",
    "pt-terms-of-service-other-1":
        "A menos que un acuerdo mutuamente ejecutado entre usted y nosotros establezca lo contrario, nuestros Términos constituyen el acuerdo completo entre usted y nosotros con respecto a MyFreeQr  y nuestros Servicios, y reemplazan cualquier acuerdo anterior. ",
    "pt-terms-of-service-other-2":
        "Es posible que le solicitemos que acepte términos adicionales para algunos de nuestros Servicios en el futuro, que regirán en la medida en que exista un conflicto entre nuestros Términos y dichos términos adicionales. . ",
    "pt-terms-of-service-other-3":
        "Nuestros Servicios no están destinados a ser distribuidos ni utilizados en ningún país donde dicha distribución o uso infringiría las leyes locales o nos sometería a cualquier normativa de otro país. Nos reservamos el derecho a limitar nuestros Servicios en cualquier país. ",
    "pt-terms-of-service-other-4":
        "Cumplirá con todas las leyes de control de exportaciones y sanciones comerciales de EE. UU. y fuera de EE. UU. ('Leyes de exportación'). No exportará, re -exportar, proporcionar o transferir nuestros Servicios: (a) a cualquier individuo, entidad o país prohibido por las leyes de exportación; (b) a cualquier persona en listas de partes restringidas del gobierno de EE. UU. o fuera de EE. UU.; o (c) para cualquier propósito prohibidas por las leyes de exportación, incluidas las armas nucleares, químicas o biológicas, o las aplicaciones de tecnología de misiles sin las autorizaciones gubernamentales requeridas. No utilizará ni descargará nuestros Servicios si se encuentra en un país restringido, si actualmente figura en cualquier EE. UU. o lista de partes restringidas no estadounidenses, o para cualquier propósito prohibido por las leyes de exportación, y no disfrazará su ubicación a través de proxy de IP u otros métodos. ",
    "pt-terms-of-service-other-5":
        "Nuestras Condiciones están escritas en inglés (EE. UU.). Cualquier versión traducida se proporciona únicamente para su conveniencia. En la medida en que cualquier versión traducida de nuestras Condiciones entre en conflicto con la versión en inglés, los controles de la versión en inglés. ",
    "pt-terms-of-service-other-6":
        "Cualquier enmienda o renuncia a nuestras Condiciones requiere nuestro consentimiento expreso",
    "pt-terms-of-service-other-7":
        "Podemos enmendar o actualizar estos Términos. Le notificaremos las enmiendas a nuestros Términos, según corresponda, y actualizaremos la fecha de 'Última modificación' en la parte superior de nuestra Términos. El uso continuado de nuestros Servicios confirma su aceptación de nuestros Términos, en su forma enmendada. Si no está de acuerdo con nuestros Términos, en su forma enmendada, debe dejar de usar nuestros Servicios. Por favor, revise nuestros Términos de vez en cuando. ",
    "pt-terms-of-service-other-8":
        "Todos nuestros derechos y obligaciones bajo nuestros Términos son libremente asignables por nosotros a cualquiera de nuestras afiliadas o en relación con una fusión, adquisición, reestructuración o venta de activos, o por operación de la ley o de otra manera, y podemos transferir su información a cualquiera de nuestras afiliadas, entidades sucesoras o nuevo propietario. ",
    "pt-terms-of-service-other-9":
        "No transferirá ninguno de sus derechos u obligaciones bajo nuestros Términos a nadie más sin nuestro consentimiento previo por escrito.",
    "pt-terms-of-service-other-10":
        "Nada en nuestros Términos impedirá que cumplamos con la ley",
    "pt-terms-of-service-other-11":
        "Salvo lo contemplado en el presente, nuestros Términos no otorgan derechos a ningún tercero beneficiario",
    "pt-terms-of-service-other-12":
        "Si no cumplimos con alguno de nuestros Términos, no se considerará una renuncia",
    "pt-terms-of-service-other-13":
        "Si alguna disposición de estos Términos se considera ilegal, nula o inaplicable por cualquier motivo, dicha disposición se considerará separable de nuestros Términos y no afectará la validez y aplicabilidad de las disposiciones restantes, excepto lo establecido en la sección 'Disposición especial de arbitraje para usuarios de Estados Unidos o Canadá' - 'Divisibilidad' a continuación. ",
    "pt-terms-of-service-other-14":
        "Nos reservamos todos los derechos que no le otorguemos expresamente. En ciertas jurisdicciones, usted puede tener derechos legales como consumidor, y nuestras Condiciones no tienen la intención de limitar dicho consumidor. derechos legales que no pueden ser renunciados por contrato. ",
    "pt-terms-of-service-other-15":
        "Siempre agradecemos sus comentarios u otras sugerencias sobre MyFreeQr  y nuestros Servicios, pero comprende que podemos utilizar sus comentarios o sugerencias sin ninguna obligación de compensarlo por ellos (solo ya que no tienes obligación de ofrecerlos). ",
    "pt-terms-of-service-attribution-notice-title": "Aviso de atribución",
    "pt-terms-of-service-attribution-notice-subtitle":
        "Agradecemos a las siguientes organizaciones e individuos por sus contribuciones:",

    /* --------------- COMPONENTS --------------- */

    /* --- Ads --- */
    loadMenu: "Cargando tu menú...",
    adProduct1: "Apple Cider Vinegar Gummy Vitamins",
    adProduct2: "REVLON One-Step Hair Dryer And Volumizer",
    adProduct3: "Furbo Dog Camera: Treat Tossing",
    adProduct4: "Lodge Miniature Skillet",
    adProduct5: "Wool Dryer Balls",
    adProduct6: "Jabra Elite Active 75t True Wireless Bluetooth Earbuds",
    adProduct7: "High Waist Biker Shorts Yoga Workout Running",
    adProduct8: "LEVOIT Air Purifier",

    /* --- Footer --- */
    freeQRMenu: "Menú QR Gratuito",
    createAccountNow: "Crea una cuenta ahora",
    termsPrivacy: "Términos & Privacidad",
    blog: "Blog",

    /* --- Gift Button --- */
    // Modal
    giftModalSubtitle1: "Te suscribiste hace <bold>unos minutos</bold>",
    giftModalSubtitle2:
        "Te suscribiste hace <bold>{{timeCreated}} horas</bold>",

    /* --- Help Button --- */
    helpButtonTitle: "Soporte",
    // Modal
    helpButtonModalTitle: "Contacta Nuestro Equipo de Soporte",

    /* --- Mobile Empty --- */
    mobileEmptyTitle: "Tu cuenta esta lista!",
    mobileEmptyDescription:
        "Inicia sesión en tu computador para empezar a usar MyFreeQr",

    /* --- My Card Panel --- */
    getStarted: "Empezar ahora",
    selectPlan: "Escoger plan",
    currentPlan: "Tu plan actual",
    feedbackDescription:
        "Plan <bold><a>Gratis sin Anuncios</a></bold> registrandote a nuestro<bold><a> Programa de Encuesta! </a></bold>",
    limitedSeats: "Cupos Limitados",

    /* --- Plans --- */
    save20: "Ahorra 20%",
    billedMonthly: "MENSUAL",
    Monthly: "MENSUAL",
    billedYearly: "ANUAL",
    Yearly: "ANUAL",

    freePlanTitle: "Gratis",
    freePlanDescription:
        "3 códigos QR dinámicos. Scans ilimitados. Reportes. Anuncios de 3 segundos",

    feedbackPlanTitle: "Encuesta",
    feedbackPlanDescription:
        "3 códigos QR dinámicos. Scans ilimitados. Reportes. Sin anuncios",

    /* --- Side Content --- */
    upgradePlan: "Actualiza tu Plan",

    /* -- Account -- */
    accountTitle: "Detalles de Cuenta",

    tab1: "Cuenta",
    tab2: "Facturas",
    tab3: "Tu Producto",

    // Tab 1 - Account
    yourAccount: "Tu Cuenta",
    personalInfo: "Información Personal",

    yourCompany: "Tu Restaurante",

    firstName: "Nombres",
    lastName: "Apellidos",
    emailAddress: "Email",
    phoneNumber: "Teléfono",

    currentPassword: "Contraseña Actual",
    newPassword: "Nueva Contraseña",
    confirmNewPassword: "Confirmar Nueva Contraseña",

    timeZone: "Zona Horaria",

    companyName: "Nombre del Restaurante",

    streetAddress: "Dirección",
    country: "País",
    city: "Ciudad",
    stateProvince: "Estado/Provincia",
    postalCode: "Código Postal",

    // Tab 2 - Invoices
    invoices: "Facturas",
    billing: "Facturación",
    invoicesEmptySectionTitle: "Tus facturas van aquí",
    invoicesStatus: "Aceptada",
    invoicePaymentMethod: "Método de Pago",
    invoiceCard:
        "{{brand}} Finalizada en {{last4}} Expira el {{month}}/{{year}}",

    // Tab 3 - Your Product
    yourPlan: "Tu Plan",
    planFreeAds: "Sin anuncios",
    planShowAds: "Mostrando anuncios",
    cancelPlan: "Cancelar Plan",
    emailsSent: "emails enviados este mes",
    inUse: "{{count}} Menú en uso",
    inUse_plural: "{{count}} menús en uso",

    /* --- Side Nav --- */
    recoverPassword: "¿No tienes contraseña aún? <bold>Haz click aquí</bold>",

    /* -- Sub Menu -- */

    /* -- Menu -- */

    /* -- Sub Menu Click -- */

    /* -- Menus -- */
    newMenuWord: "Nuevo Menú",
    addNewMenuWord: "Añadir Nuevo Menú",
    addMenu: "Nuevo Menú",

    /* -- Profile -- */
    getHelp: "Ayuda",
    logOut: "Cerrar Sesión",
    profileTitle: "Actualiza tu Plan",
    profileContent:
        "Elimina los anuncios cuando tus clientes escanean tu código. <br> <br> Elimina los anuncios de tu código cuando pasas a un plan pago.",
    profileButton: "Actualizar Ahora",

    /* --- Temp Toast --- */
    tempToast1Content:
        "Haz recolectado el número máximo de clientes permitidos por tu plan. Para seguir recolectando clientes, por favor",
    tempToast1Link: "actualiza tu plan",

    tempToast2Content:
        "Tu periodo de prueba gratis expirará {{leftTrialDays}} días. Para continuar disfrutando MyFreeQr",
    tempToast2Link: "por favor haz click aquí",

    tempToast3Content:
        "Tu periodo de prueba ha expirado. Para continuar disfrutando MyFreeQr",
    tempToast3Link: "por favor haz click aquí",

    tempToast4Content:
        "Parece que tu conexión a Internet tiene problemas. Es posible que algunas acciones no funcionen hasta que vuelva a estar en línea",

    tempToast5Content:
        "Parece que algo ha salido mal. La página se recargara automáticamente en 5 segundos",
    tempToast5Link: "por favor haz click aquí",

    /* --------------- TOAST --------------- */

    /* --- Error --- */
    toastUnexpectedError: "Error inesperado, por favor inténtalo de nuevo",
    toastErrorFirstName: "Por favor ingresa tus nombre",
    toastErrorLastName: "Por favor ingresa tus apellidos",
    toastErrorEmail: "Por favor ingresa tu email",
    toastErrorCompany: "Por favor ingresa el nombre de tu empresa",
    toastErrorPass: "Por favor ingresa tu contraseña",
    toastErrorNewPass: "Por favor ingresa tu nueva contraseña",
    toastErrorConfirmNewPass: "Por favor confirma tu nueva contraseña",
    toastErrorAddress: "Ingresa tu dirección",
    toastErrorCountry: "Por favor ingresa tu país",
    toastErrorCity: "Por favor ingresa tu ciudad",
    toastErrorState: "Por favor ingresa tu estado/provincia",
    toastErrorPostalCode: "Por favor ingresa tu código postal",
    toastErrorUrl: "Por favor ingresa una URL válida",
    toastErrorWhatsApp: "Por favor ingresa un WhatsApp valido",
    toastErrorMenuName: "Por favor ingresa el nombre de tu menú",
    toastErrorLoadImage:
        "Error cargando la imagen, por favor inténtalo de nuevo",
    toastErrorEmailNotSent:
        "Error enviando tu código, por favor inténtalo de nuevo",
    toastErrorShare:
        "Error compartiendo tu código, por favor inténtalo de nuevo",
    toastErrorSizeExceed: "El archivo no debe sobrepasar 100 MB",
    toastErrorUploadPdrOrImage: "Por favor sube una imagen o un PDF",
    toastErrorInvalidImageFormat: "Por favor sube una imagen .jpeg o .png",
    toastErrorCustomUrl: "Por favor ingresa tu URL personalizada",
    toastErrorInvalidEmail: "Por favor ingresa un email válido",
    toastErrorInvalidPass: "Tu contraseña debe contener 6 o más caracteres",
    toastErrorPassNotMatch:
        "Tu nueva contraseña no concuerda con la contraseña de confirmación",
    toastErrorCustomUrlNotAvailable:
        "Esta URL personalizada no está disponible, por favor intenta una diferente",
    toastErrorEmailNotFound:
        "La dirección de email no fue encontrada, por favor inténtalo de nuevo",
    toastErrorAccountNotDeleted:
        "Tu cuenta no pudo ser eliminada, por favor contacta a soporte",
    toastErrorStripeCustomerNoCreated:
        "Tu usuario de compra no pudo ser creado, por favor inténtalo de nuevo",
    toastErrorStripePaymentNoCreated:
        "Tu compra no pudo ser creada, por favor inténtalo de nuevo",
    toastErrorStripeInvoiceNoSent:
        "La factura de tu compra no pudo ser enviada, por favor contacta a soporte",
    toastErrorStripeNoConfirmPayment:
        "Tu pago no pudo ser realizado, por favor inténtalo de nuevo",
    toastErrorStripeNoGetPayment:
        "La información de tu compra no pudo ser validadad, por favor inténtalo de nuevo",
    toastErrorStripePaymentMethodNoDeleted:
        "Tu Método de pago no pudo ser eliminado, por favor contacta a soporte",
    toastLimitTimeExceeded:
        "Ha ocurrido un error inesperado, esta página será refrescada porque el tiempo ha sido excedido, inténtalo de nuevo después de la recarga.",
    toastErrorQRcodeNotCreated:
        "Tu código qr no pudo ser creado, por favor inténtalo de nuevo",

    /* --- Info --- */

    /* --- Success --- */
    toastSuccessQRSent:
        "Tu código QR fue enviado a tu correo. Por favor revisa el folder de no deseados si no llega al principal.",
    toastSuccessEmailSent: "Tu email fue enviado",
    toastSuccessLinkCopied: "Link copiado",
    toastSuccessPassUpdated: "Tu nueva contraseña fue actualizada",
    toastSuccessLinkPassSent:
        "Un link para restablecer tu contraseña fue enviado a tu email",
    toastSuccessEmailUpdated: "Tu email fue actualizado",
    toastSuccessUnsubscribed: "Se ha dado de baja",
    toastSuccessInfoUpdated: "Tu información fue actualizada",
    toastSuccessMenuDeleted: "Tu menú fue eliminado",
    toastSuccessAccountDeleted: "Tu cuenta fue eliminada",
    toastSuccessInvoiceSent:
        "Tu factura fue enviado a tu correo. Por favor revisa el folder de no deseados si no llega al principal.",

    /* --------------- ERRORS AUTH FIREBASE --------------- */

    "admin-restricted-operation":
        "Esta operación está restringida solo a administradores.",
    "argument-error": "",
    "app-not-authorized":
        "Esta aplicación, identificada por el dominio donde está alojada, no está autorizada para usar Firebase Authentication con la clave de API proporcionada. Revise la configuración de su clave en la consola de API de Google.",
    "app-not-installed":
        "La aplicación móvil solicitada correspondiente al identificador (nombre del paquete de Android o ID del paquete de iOS) proporcionado no está instalada en este dispositivo.",
    "captcha-check-failed":
        "El token de respuesta reCAPTCHA proporcionado no es válido, está caducado, ya se usó o el dominio asociado con él no coincide con la lista de dominios incluidos en la lista blanca.",
    "code-expired":
        "El código SMS ha caducado. Vuelva a enviar el código de verificación para intentarlo de nuevo.",
    "cordova-not-ready": "El marco de Córdoba no está listo.",
    "cors-unsupported": "Este navegador no es compatible.",
    "credential-already-in-use":
        "Esta credencial ya está asociada con una cuenta de usuario diferente.",
    "custom-token-mismatch":
        "El token personalizado corresponde a una audiencia diferente.",
    "requires-recent-login":
        "Esta operación es sensible y requiere autenticación reciente. Inicie sesión de nuevo antes de volver a intentar esta solicitud.",
    "dynamic-link-not-activated":
        "Active Dynamic Links en Firebase Console y acepte los términos y condiciones.",
    "email-change-needs-verification":
        "Los usuarios de múltiples factores siempre deben tener un correo electrónico verificado.",
    "email-already-in-use":
        "La dirección de correo electrónico ya está siendo utilizada por otra cuenta.",
    "expired-action-code": "El código de acción ha caducado.",
    "cancelled-popup-request":
        "Esta operación se canceló debido a que se abrió otra ventana emergente conflictiva.",
    "internal-error": "ha ocurrido un error interno.",
    "invalid-app-credential":
        "La solicitud de verificación telefónica contiene un verificador de aplicación no válido. La respuesta del token reCAPTCHA no es válida o ha caducado.",
    "invalid-app-id":
        "El identificador de la aplicación móvil no está registrado para el proyecto actual.",
    "invalid-user-token":
        "La credencial de este usuario no es válida para este proyecto. Esto puede suceder si el token del usuario ha sido manipulado o si el usuario no pertenece al proyecto asociado con esta clave de API.",
    "invalid-auth-event": "ha ocurrido un error interno.",
    "invalid-verification-code":
        "El código de verificación de SMS utilizado para crear la credencial de autenticación del teléfono no es válido. Vuelva a enviar el código de verificación sms y asegúrese de utilizar el código de verificación proporcionado por el usuario.",
    "invalid-continue-uri":
        "La URL de continuación proporcionada en la solicitud no es válida.",
    "invalid-cordova-configuration":
        "Se deben instalar los siguientes complementos de Cordova para habilitar el inicio de sesión de OAuth: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser y cordova-plugin-customurlscheme.",
    "invalid-custom-token":
        "El formato del token personalizado es incorrecto. Por favor revisa la documentación.",
    "invalid-dynamic-link-domain":
        "El dominio de enlace dinámico proporcionado no está configurado ni autorizado para el proyecto actual.",
    "invalid-email": "La dirección de correo electrónico está mal formateada.",
    "invalid-api-key":
        "Su clave de API no es válida, verifique que la haya copiado correctamente.",
    "invalid-cert-hash":
        "El hash del certificado SHA-1 proporcionado no es válido.",
    "invalid-credential":
        "La credencial de autenticación proporcionada tiene un formato incorrecto o ha caducado.",
    "invalid-message-payload":
        "La plantilla de correo electrónico correspondiente a esta acción contiene caracteres no válidos en su mensaje. Para solucionarlo, vaya a la sección Plantillas de correo electrónico de autenticación en Firebase Console.",
    "invalid-multi-factor-session":
        "La solicitud no contiene una prueba válida del inicio de sesión exitoso del primer factor.",
    "invalid-oauth-provider":
        "EmailAuthProvider no es compatible con esta operación. Esta operación solo admite proveedores de OAuth.",
    "invalid-oauth-client-id":
        "El ID de cliente de OAuth proporcionado no es válido o no coincide con la clave de API especificada.",
    "unauthorized-domain":
        "Este dominio no está autorizado para operaciones de OAuth para su proyecto de Firebase. Edite la lista de dominios autorizados desde Firebase console.",
    "invalid-action-code":
        "El código de acción no es válido. Esto puede suceder si el código está mal formado, vencido o ya se ha utilizado.",
    "wrong-password":
        "La contraseña no es válida o el usuario no tiene contraseña.",
    "invalid-persistence-type":
        "El tipo de persistencia especificado no es válido. Solo puede ser local, de sesión o ninguna.",
    "invalid-phone-number":
        "El formato del número de teléfono proporcionado es incorrecto. Introduzca el número de teléfono en un formato que pueda analizarse en formato E.164. Los números de teléfono E.164 están escritos en el formato [+] [código de país] [número de abonado incluido el código de área].",
    "invalid-provider-id": "El ID de proveedor especificado no es válido.",
    "invalid-recipient-email":
        "El correo electrónico correspondiente a esta acción no se pudo enviar porque la dirección de correo electrónico del destinatario proporcionada no es válida.",
    "invalid-sender":
        "La plantilla de correo electrónico correspondiente a esta acción contiene un correo electrónico o un nombre de remitente no válido. Para solucionarlo, vaya a la sección Plantillas de correo electrónico de autenticación en Firebase Console.",
    "invalid-verification-id":
        "El ID de verificación utilizado para crear la credencial de autenticación del teléfono no es válido.",
    "invalid-tenant-id":
        "El ID de inquilino de la instancia de autenticación no es válido.",
    "multi-factor-info-not-found":
        "El usuario no tiene un segundo factor que coincida con el identificador proporcionado.",
    "multi-factor-auth-required":
        "Se requiere prueba de propiedad de un segundo factor para completar el inicio de sesión.",
    "missing-android-pkg-name":
        "Se debe proporcionar un nombre de paquete de Android si es necesario instalar la aplicación de Android.",
    "auth-domain-config-required":
        "Asegúrese de incluir authDomain cuando llame a firebase.initializeApp (), siguiendo las instrucciones en la consola de Firebase.",
    "missing-app-credential":
        "A la solicitud de verificación del teléfono le falta una afirmación del verificador de la aplicación. Se debe proporcionar un token de respuesta reCAPTCHA.",
    "missing-verification-code":
        "La credencial de autenticación del teléfono se creó con un código de verificación de SMS vacío.",
    "missing-continue-uri":
        "Se debe proporcionar una URL de continuación en la solicitud.",
    "missing-iframe-start": "ha ocurrido un error interno.",
    "missing-ios-bundle-id":
        "Se debe proporcionar un ID de paquete de iOS si se proporciona un ID de App Store.",
    "missing-multi-factor-info":
        "No se proporciona un segundo identificador de factor.",
    "missing-multi-factor-session":
        "A la solicitud le falta una prueba del inicio de sesión correcto del primer factor.",
    "missing-or-invalid-nonce":
        "La solicitud no contiene un nonce válido. Esto puede ocurrir si el hash SHA-256 del nonce sin procesar proporcionado no coincide con el nonce con hash en la carga útil del token de ID.",
    "missing-phone-number":
        "Para enviar códigos de verificación, proporcione un número de teléfono para el destinatario.",
    "missing-verification-id":
        "La credencial de autenticación del teléfono se creó con un ID de verificación vacío.",
    "app-deleted": "Se borró esta instancia de FirebaseApp.",
    "account-exists-with-different-credential":
        "Ya existe una cuenta con la misma dirección de correo electrónico pero diferentes credenciales de inicio de sesión. Inicie sesión con un proveedor asociado a esta dirección de correo electrónico.",
    "network-request-failed":
        "Se ha producido un error de red (como tiempo de espera, conexión interrumpida o host inaccesible).",
    "no-auth-event": "ha ocurrido un error interno.",
    "no-such-provider":
        "El usuario no estaba vinculado a una cuenta con el proveedor especificado.",
    "null-user":
        "Se proporcionó un objeto de usuario nulo como argumento para una operación que requiere un objeto de usuario no nulo.",
    "operation-not-allowed":
        "El proveedor de inicio de sesión proporcionado está inhabilitado para este proyecto de Firebase. Habilítelo en la consola de Firebase, en la pestaña de método de inicio de sesión de la sección Auth.",
    "operation-not-supported-in-this-environment":
        'Esta operación no es compatible con el entorno en el que se ejecuta esta aplicación. "location.protocol" debe ser http, https o chrome-extension y el almacenamiento web debe estar habilitado.',
    "popup-blocked":
        "No se pudo establecer una conexión con la ventana emergente. Es posible que el navegador lo haya bloqueado.",
    "popup-closed-by-user":
        "El usuario ha cerrado la ventana emergente antes de finalizar la operación.",
    "provider-already-linked":
        "El usuario solo puede estar vinculado a una identidad para el proveedor dado.",
    "quota-exceeded":
        "Se ha superado la cuota del proyecto para esta operación.",
    "redirect-cancelled-by-user":
        "La operación de redireccionamiento ha sido cancelada por el usuario antes de finalizar.",
    "redirect-operation-pending":
        "Ya está pendiente una operación de inicio de sesión de redireccionamiento.",
    "rejected-credential":
        "La solicitud contiene credenciales incorrectas o que no coinciden.",
    "second-factor-already-in-use":
        "El segundo factor ya está inscrito en esta cuenta.",
    "maximum-second-factor-count-exceeded":
        "Se superó el número máximo permitido de segundos factores en un usuario.",
    "tenant-id-mismatch":
        "El ID de inquilino proporcionado no coincide con el ID de inquilino de la instancia de Auth",
    timeout: "La operación ha agotado el tiempo.",
    "user-token-expired":
        "La credencial del usuario ya no es válida. El usuario debe iniciar sesión nuevamente.",
    "too-many-requests":
        "Hemos bloqueado todas las solicitudes de este dispositivo debido a una actividad inusual. Vuelve a intentarlo más tarde.",
    "unauthorized-continue-uri":
        "El dominio de la URL de continuación no está incluido en la lista blanca. Incluya el dominio en la lista blanca en Firebase console.",
    "unsupported-first-factor":
        "Inscribir un segundo factor o iniciar sesión con una cuenta multifactor requiere iniciar sesión con un primer factor compatible.",
    "unsupported-persistence-type":
        "El entorno actual no admite el tipo de persistencia especificado.",
    "unsupported-tenant-operation":
        "Esta operación no se admite en un contexto de varios inquilinos.",
    "unverified-email":
        "La operación requiere un correo electrónico verificado.",
    "user-cancelled":
        "El usuario no otorgó a su aplicación los permisos que solicitó.",
    "user-not-found":
        "No existe un registro de usuario que corresponda al identificador proporcionado.",
    "user-disabled":
        "La cuenta de usuario ha sido inhabilitada por un administrador.",
    "user-mismatch":
        "Las credenciales proporcionadas no corresponden al usuario que inició sesión previamente.",
    "user-signed-out": "",
    "weak-password": "La contraseña debe tener 6 caracteres o más.",
    "web-storage-unsupported":
        "Este navegador no es compatible o las cookies y los datos de terceros pueden estar deshabilitados.",

    /* --------------- CUSTOM ERRORS AUTH FIREBASE --------------- */

    "o-unavailable":
        "Parece que la conexión es inestable, lo estamos intentando de nuevo",
    "o-user-not-found": "No hemos encontrado a un usuario con este email",
    "o-popup-closed-by-user": "Haz cerrado la ventana emergente",
    "o-email-already-in-use": "El email ya está en uso por otra cuenta",

    /* TEMPORALS */
    deleteLogo: "Eliminar Logo",
    uploadLogo: "Subir Logo",
}
