import i18n from "i18next";

export function ListFirebaseCustomError({ code }) {
    const List = [
        { code: "unavailable", message: i18n.t("o-unavailable") },
        { code: "user-not-found", message: i18n.t("o-user-not-found") },
        { code: "popup-closed-by-user", message: i18n.t("o-popup-closed-by-user") },
        { code: "email-already-in-use", message: i18n.t("o-email-already-in-use") },
    ];

    let errorMessage = "";

    // Validate if exist a custom message to the code
    const error = List.find((element) => element.code === code);
    errorMessage = error ? (error.message ? error.message : "") : "";

    // Validate if the error message don't exist
    if (!errorMessage.trim()) {
        errorMessage = i18n.t(code);
    }

    // Validate if the error message don't exist
    // May be the exact error don't exist yet
    if (!errorMessage.trim()) {
        errorMessage = i18n.t("toastUnexpectedError");
    }

    return errorMessage;
}