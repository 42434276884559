/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

/* Constants or States */
const initData = {
    pageActive: "",
}

const UPDATE_PAGE = "UPDATE_PAGE"

/* Reducer (Save call API in constant) */
export default function sideNavMobileReducer(state = initData, action) {
    switch (action.type) {
        case UPDATE_PAGE:
            return {...state, pageActive: action.payload.pageName }
        default:
            return {...state }
    }
}

/* Actions (Calls API) */
export const changePage =
    ({ pageName = "Account" }) =>
    (dispatch) => {
        dispatch({
            type: UPDATE_PAGE,
            payload: {
                pageName,
            },
        })
    }