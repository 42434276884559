/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

/* Constants or States */
const initData = {
    active: "",
}

const MENUS = "MENUS"
const MARKETING = "MARKETING"
const NONE_MENU = "NONE_MENU"

/* Reducer (Save call API in constant) */
export default function subMenuReducer(state = initData, action) {
    switch (action.type) {
        case MENUS:
            return {...state, active: "menu" }
        case MARKETING:
            return {...state, active: "marketing" }
        case NONE_MENU:
            return {...initData }
        default:
            return {...state }
    }
}

/* Actions (Calls API) */
export const openSubMenuMenus = () => (dispatch) => {
    dispatch({
        type: MENUS,
    })
}

export const openSubMenuMarketing = () => (dispatch) => {
    dispatch({
        type: MARKETING,
    })
}

export const closeSubMenu = () => (dispatch) => {
    dispatch({
        type: NONE_MENU,
    })
}