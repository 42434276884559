import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend"; // <---- add this

import { translationEn } from "./en";
import { translationEs } from "./es";

const options = {
    order: ["querystring", "navigator"],
    lookupQuerystring: "lng",
};

i18n
    .use(XHR) // <---- add this
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: translationEn },
            es: { translation: translationEs },
        },
        //lng: "es",
        detection: options,
        fallbackLng: "en", // use en if detected lng is not available

        supportedLngs: ["en", "es"],
        interpolation: { escapeValue: false },
        debug: false,
    });

export default i18n;