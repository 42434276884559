import React from "react"

/* Styles */
import "./style.scss"

/* Components */
import GoogleAds from "components/googleAds/GoogleAds"
import { Row, Col, ProgressBar } from "react-materialize"
import { openToast, ERROR } from "components/toast/Toast"

/* Helpers */
import { HideAd } from "helpers/HideAd"
import { LogEvent } from "helpers/LogEvents"

/* Redux */
import { useSelector } from "react-redux"

/* I18n */
import { useTranslation } from "react-i18next"

const AdsMenu = () => {
  // Constant to translation
  const { t } = useTranslation()

  // Redux states
  const { showAds, existMenu, timeToHideAd } = useSelector(
    (store) => store.scan.data
  )
  const { user, venue, qrFlow } = useSelector((store) => store.scan.userData)

  // Component states
  const [globalTime, setGlobalTime] = React.useState(5)
  const [time, setTime] = React.useState(5)
  const [progressBarValue, setProgressBarValue] = React.useState(0.000001)
  const [timer, setTimer] = React.useState()

  // Start timer
  React.useEffect(() => {
    setTimer(
      setInterval(() => {
        setGlobalTime((t) => t - 0.5)
      }, 500)
    )
  }, [])

  // Validate data to decrease time
  React.useEffect(() => {
    if (globalTime <= 3) {
      if (showAds !== null) {
        setTime((t) => t - 0.5)
      }
    } else if (globalTime <= 5) {
      setTime((t) => t - 0.5)
    }
  }, [globalTime])

  // Actions depend of time
  React.useEffect(() => {
    if (time < 3) {
      const width = 100 - (Math.round(time) * 100) / 3
      setProgressBarValue(width)
    }

    // Exit if the time is over
    if (time <= timeToHideAd || showAds === false || existMenu === false) {
      // Stop timer
      clearInterval(timer)
      // Hide Ad
      HideAd()
      return
    } else {
      // Block scroll while is showing ad
      document.body.style.overflow = "hidden"
      document.documentElement.style.overflow = "hidden"

      document.body.style.height = "100%"
      document.documentElement.style.height = "100%"
    }

    if (time <= -7) {
      // Stop timer
      clearInterval(timer)

      LogEvent("reload_automatically", {
        new_user_id: user,
        venue_id: venue,
        qr_flow_id: qrFlow,
        time: 7,
        method: "Ads in scan page",
        description: `${user} - ${venue} - ${qrFlow} - 7 - Ads in scan page`,
      })

      // Show toast
      openToast({
        content: t("toastLimitTimeExceeded"),
        type: ERROR,
        callback: () => {
          window.location.reload()
        },
      })
    }
  }, [time, showAds, existMenu, timeToHideAd, timer, user, venue, qrFlow])

  return (
    <div id="google-ad-container">
      {(time > 3 || showAds === null || existMenu === null) && (
        <div className="o-blank" />
      )}

      <div className="o-ads-container container">
        <Row>
          <Col s={12} className="center-align">
            <b>{t("siteName")}</b>
          </Col>
          <Col s={12} className="center-align">
            <p>{t("loadMenu")}</p>
          </Col>
          <Col s={12}>
            <p>
              {t("adWord")} 0:0
              {time >= 0 && time <= 3 ? Math.round(time) : time > 3 ? 3 : 0}
            </p>
          </Col>
          <Col s={12}>
            <ProgressBar progress={progressBarValue} />
          </Col>
        </Row>

        {/* <div>
          <a href="/">
            <Button
              style={{
                width: "100%",
              }}
            >
              {t("createYourMenuWord")}
            </Button>
          </a>
        </div> */}

        {/* DISABLED GOOGLE ADS */}

        <GoogleAds
          style={{
            display: "block",
          }}
        />
      </div>
    </div>
  )
}

export default AdsMenu
