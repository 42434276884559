import "./App.scss"

/* Styles */
import Routes from "routes/Routes"

/* Components */
import { Suspense } from "react"
import TempToast from "components/desktop/tempToast/TempToast"
import AdsMenu from "components/adsMenu/AdsMenu"

/* Components */
import { MainLoading } from "components/mainLoading/MainLoading"

/* I18n */
import "i18n/i18n"

function App() {
  return (
    <div className="App">
      <Suspense fallback={<MainLoading />}>
        <TempToast />
        {(window.location.href.includes("/scan") ||
          window.location.href.includes("/check-in") ||
          window.location.href.includes("/qr")) && <AdsMenu />}
        <Routes />
      </Suspense>
    </div>
  )
}

export default App
