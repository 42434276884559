export function GetFileSizeFromURL(url, callback) {
    return new Promise((resolve) => {
        var xhr = new XMLHttpRequest()
        xhr.open("GET", url, true) // Notice "HEAD" instead of "GET",
            //  to get only the header
        xhr.onreadystatechange = function() {
            if (this.readyState === this.DONE) {
                callback(parseInt(xhr.getResponseHeader("Content-Length")))
                resolve("d")
            }
        }
        xhr.send()
    })
}