/* History */
import { createBrowserHistory } from "history"

const history = createBrowserHistory()
history.listen((location) => {
    const gtag = window.gtag

    if (typeof gtag === "function") {
        gtag("config", process.env.REACT_APP_GA, {
            page_title: document.title,
            page_location: window.location.href,
            page_path: location.pathname,
        })

        gtag("set", "page", location.pathname + window.dataLayer[0].ga)
        gtag("send", "pageview", location.pathname)
    }
})

export default history