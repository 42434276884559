/* Libraries */
import { Helmet } from "react-helmet-async"

/* Assets */
import imageBlog1 from "assets/img/jpg/phone-with-qr-code.jpeg"
import imageBlog2 from "assets/img/jpg/people-in-restaurant.webp"
import imageBlog3 from "assets/img/jpg/person-creating-qr-menu.webp"
import imageBlog4 from "assets/img/jpg/people-have-dinner-in-cafe-scanning-qr-code.webp"
import imageBlog5 from "assets/img/jpg/computer.webp"
import imageBlog6 from "assets/img/jpg/people-in-futuristic-restaurant.webp"
import imageBlog7 from "assets/img/jpg/people-walking-on-street.webp"
import imageBlog8 from "assets/img/jpg/cafe-owner-preparing-qr-code-menu-for-their-guests.webp"
import imageBlog9 from "assets/img/jpg/people-on-restaurant-making-a-presentation-about-qr-code.webp"
import imageBlog10 from "assets/img/jpg/infographic-about-how-create-a-a-qr-code-menu.webp"
import imageBlog11 from "assets/img/jpg/qr-code-in-restaurant-table.webp"
import imageBlog12 from "assets/img/jpg/person-in-restaurant-with-qr-code-to-scan.webp"
import imageBlog13 from "assets/img/jpg/futuristic-restaurant.webp"
import imageBlog14 from "assets/img/jpg/sophisticated-restaurant-scene-highlighting-the-innovative-use-of-a-free-qr-menu.webp"
import imageBlog15 from "assets/img/jpg/modern-restaurant-scene-where-the-use-of-qr-codes-enhances-the-efficiency-of-service.webp"
import imageBlog16 from "assets/img/jpg/atmosphere-of-a-street-food-market-where-qr-codes-provide-easy-and-fun-access-to-a-wide-variety-of-culinary-delights.webp"
import imageBlog17 from "assets/img/jpg/process-of-creating-a-qr-code-for-restaurant-menus.webp"
import imageBlog18 from "assets/img/jpg/restaurant-scene-where-qr-codes-are-seamlessly-integrated-into-the-dining-experience.webp"
import imageBlog19 from "assets/img/jpg/restaurant-scene-where-qr-codes-are-used-to-enhance-the-efficiency-of-table-service.webp"
import imageBlog20 from "assets/img/jpg/promotional-poster-design-that-highlights-the-benefits-of-adopting-qr-codes-for-menus.webp"
import imageBlog21 from "assets/img/jpg/infographic-that-outlines-the-process-of-creating-a-qr-code-menu.webp"
import imageBlog22 from "assets/img/jpg/elegant-dining-setting-where-qr-codes-are-seamlessly-integrated.webp"
import imageBlog23 from "assets/img/jpg/restaurant-that-highlights-the-innovative-use-of-qr-code-menus-to-enhance-dining-experiences.webp"
import imageBlog24 from "assets/img/jpg/innovative-dining-scene-that-blends-classic-elegance-with-modern-technology.webp"

/* Components */
import Footer from "components/desktop/footer/MyFooter"
import Nav from "components/desktop/nav/Nav"

const BLOGS = [
    {
        title: "Revolutionize Your Dining Experience with a Free QR Code Menu",
        url: "revolutionize-your-dining-experience-with-a-free-qr-code-menu",
        description:
            "Explore a digital dining revolution with a free QR Code menu, enhancing safety, convenience, and engagement for a modern restaurant experience.",
        img: imageBlog24,
        date: "March 13, 2024",
    },

    {
        title: "Unleash Your Restaurant's Potential with QR Code Menus",
        url: "unleash-your-Restaurant-potential-with-qr-code-menus",
        description:
            "Explore QR Code menus for a modern dining experience, enhancing safety and customer engagement in restaurants.",
        img: imageBlog23,
        date: "March 12, 2024",
    },

    {
        title: "Enhancing Customer Service Why QR Codes for Restaurant Menus Are the Future",
        url: "enhancing-customer-service-why-qr-codes-for-restaurant-menus-are-the-future",
        description:
            "Discover how QR codes revolutionize dining with easy menu access, personalized service, and seamless reservations. Enhance your experience!",
        img: imageBlog22,
        date: "March 11, 2024",
    },

    {
        title: "Step-by-Step Guide How to Create a Free QR Code Menu for Your Eatery",
        url: "step-by-step-guide-how-to-create-a-free-qr-code-menu-for-your-eatery",
        description:
            "Learn to create a free QR code menu for your eatery with our guide on choosing platforms, customizing menus, and boosting sales.",
        img: imageBlog21,
        date: "March 08, 2024",
    },

    {
        title: "Cut Costs and Go Digital Get Your Free QR Code for Menus Today",
        url: "cut-costs-and-go-digital-get-your-free-qr-code-for-menus-today",
        description:
            "Discover how QR codes revolutionize menus, offering cost savings and digital efficiency for businesses. Get your free QR code now!",
        img: imageBlog20,
        date: "March 07, 2024",
    },

    {
        title: "Optimize Your Table Service with QR Codes for Restaurant Menus",
        url: "optimize-your-table-service-with-qr-codes-for-restaurant-menus",
        description:
            "Optimize your restaurant with a QR code menu. Enhance dining, cut costs, and improve service with modern digital solutions.",
        img: imageBlog19,
        date: "March 06, 2024",
    },

    {
        title: "Enhancing Dining Experience How QR Codes Revolutionize Restaurant Menus",
        url: "enhancing-dining-experience-how-qr-codes-revolutionize-restaurant-menus",
        description:
            "Discover how QR codes are transforming the dining scene by enhancing menus, customer experience, and operational efficiency.",
        img: imageBlog18,
        date: "March 05, 2024",
    },

    {
        title: "The Ultimate Guide on How to Create a Complimentary QR Code for Your Restaurant Menu",
        url: "the-ultimate-guide-on-how-to-create-a-complimentary-qr-code-for-your-restaurant-menu",
        description:
            "Explore how QR codes revolutionize dining with efficient service, digital menus, and enhanced customer experiences in our in-depth guide.",
        img: imageBlog17,
        date: "March 04, 2024",
    },

    {
        title: "Access Your Foodie Favorites with a Free QR Code for Menus",
        url: "access-your-foodie-favorites-with-a-free-qr-code-for-menus",
        description:
            "Learn to create a free QR code menu for your eatery, enhance customer experience, and leverage marketing analytics effectively.",
        img: imageBlog16,
        date: "March 01, 2024",
    },

    {
        title: "Tech-Savvy Dining Harnessing QR Codes for Efficient Restaurant Service",
        url: "tech-savvy-dining-harnessing-qr-codes-for-efficient-restaurant-service",
        description:
            "Streamline dining with QR code menus. Discover the benefits, setup tips, and POS integration for a tech-savvy restaurant experience.",
        img: imageBlog15,
        date: "February 29, 2024",
    },

    {
        title: "Elevate Your Dining Business with a Free QR Menu Solution",
        url: "elevate-your-dining-business-with-a-free-qr-menu-solution",
        description:
            "Boost your restaurant's efficiency and customer satisfaction with our free QR Menu solution. Embrace the future of dining today!",
        img: imageBlog14,
        date: "February 28, 2024",
    },

    {
        title: "Introducing the QR Code Menu The Future of Ordering",
        url: "introducing-the-qr-code-menu-the-future-of-ordering",
        description:
            "Explore the evolution of dining with QR Code Menus, enhancing ordering, customer experience, and restaurant management.",
        img: imageBlog13,
        date: "February 27, 2024",
    },

    {
        title: "Creating a Cost-Free Menu QR Code for Your Eatery",
        url: "creating-a-cost-free-menu-qr-code-for-you-eatery",
        description:
            "Learn to create a free QR code menu for your eatery, enhance customer experience, and leverage marketing analytics effectively.",
        img: imageBlog12,
        date: "February 26, 2024",
    },

    {
        title: "Streamline Your Service with a QR Code for Your Restaurant Menu",
        url: "streamline-your-service-with-a-qr-code-for-your-restaurant-menu",
        description:
            "Discover how to boost your restaurant's service with a custom QR code menu. Learn the benefits, design tips, and operational integration.",
        img: imageBlog11,
        date: "February 23, 2024",
    },

    {
        title: "5 Steps to Set Up a QR Code Menu for Your Restaurant for Free",
        url: "5-steps-to-set-up-a-qr-code-menu-for-your-restaurant-for-free",
        description:
            "Discover how to create a free QR code menu for your restaurant in 5 easy steps. Enhance your dining experience now!",
        img: imageBlog10,
        date: "February 22, 2024",
    },

    {
        title: "Discover How to Implement a QR Menu at No Cost for Your Restaurant",
        url: "discover-how-to-implement-a-qr-menu-at-no-cost-for-your-restaurant",
        description:
            "Learn to set up a free QR menu for your restaurant, enhance customer experience, and stay ahead with tech trends.",
        img: imageBlog9,
        date: "February 21, 2024",
    },

    {
        title: "How to Offer Your Guests a QR Code Menu at No Cost",
        url: "how-to-offer-your-guests-a-qr-code-menu-at-no-cost",
        description:
            "Learn how to offer your guests a QR code menu at no cost. Understand, create, implement, promote, maintain, and update your QR code menu effectively.",
        img: imageBlog8,
        date: "February 20, 2024",
    },

    {
        title: "Boost Your Restaurant's Appeal with a Free QR Code Menu Service",
        url: "boost-your-restaurant-appeal-with-a-free-qr-code-menu-service",
        description:
            "Discover the benefits of using QR code menus to enhance your restaurant's appeal and improve customer experience. Implementing, integrating, and maximizing the impact of QR codes for efficient and cost-effective solutions.",
        img: imageBlog7,
        date: "February 19, 2024",
    },

    {
        title: "Revolutionizing Dining The Rise of QR Menus in Restaurants",
        url: "revolutionizing-dining-the-rise-of-qr-menus-in-restaurants",
        description:
            "Discover how QR menus are revolutionizing dining with enhanced customer experience, efficient ordering, and contactless payments. Learn about implementing QR menus and overcoming challenges in restaurants.",
        img: imageBlog6,
        date: "February 16, 2024",
    },

    {
        title: "DIY Guide Creating Your Own QR Code Menu for Free",
        url: "diy-guide-creating-your-own-qr-code-menu-for-free",
        description:
            "Learn how to create a QR code menu for free with this DIY guide. Choose the right generator, design, print, display, and promote effectively.",
        img: imageBlog5,
        date: "February 15, 2024",
    },

    {
        title: "Attract More Diners with a Free Menu QR Code",
        url: "attract-more-diners-with-a-free-menu-qr-code",
        description:
            "Learn how to attract more diners to your restaurant with a free menu QR code. Enhance customer experience, increase table turnover, and implement effective marketing strategies.",
        img: imageBlog4,
        date: "February 14, 2024",
    },

    {
        title: "Effortlessly Convert Your Restaurant Menu to QR Code",
        url: "effortlessly-convert-your-restaurant-menu-to-qr-code",
        description:
            "Effortlessly convert your restaurant menu to QR code for enhanced customer experience, contactless ordering, and cost-effective solutions. Learn how to create and implement QR codes in your restaurant with best practices.",
        img: imageBlog3,
        date: "February 13, 2024",
    },

    {
        title: "Enhancing Customer Experience with QR Code Menus",
        url: "enhancing-customer-experience-with-qr-code-menus",
        description:
            "Learn how QR code menus enhance customer experience in restaurants. Benefits, design tips, and implementation strategies included.",
        img: imageBlog2,
        date: "February 12, 2024",
    },

    {
        title: "The Future is Here: Using QR Codes for Your Restaurant Menu",
        url: "the-future-is-here-using-qr-codes-for-your-restaurant-menu",
        description:
            "Discover how QR codes can revolutionize your restaurant experience by enhancing customer service, boosting efficiency, and ensuring safety standards.",
        img: imageBlog1,
        date: "February 09, 2024",
    },
]

export function Blogs({ history }) {
    const handleSignUp = () => {
        history.push("/auth/signUp/home")
    }

    return (
        <>
            <Helmet>
                <title>Blogs</title>

                <meta
                    name="description"
                    content="Here you will find all the blogs related to QR codes and how they can help you in your business."
                />

                <meta name="keywords" content="QR Code Menu" />

                {/* hreflang */}
                <link
                    rel="alternate"
                    hreflang="en"
                    href="https://myfreeqr.com/blog"
                />
                <link
                    rel="alternate"
                    hreflang="es"
                    href="https://myfreeqr.com/blog"
                />
                <link
                    rel="alternate"
                    hreflang="x-default"
                    href="https://myfreeqr.com/blog"
                />
            </Helmet>

            <Nav from="home" createAccountAction={() => handleSignUp()} />

            <div className="blogs">
                <h1>Blogs</h1>

                <div className="blogs__container">
                    {BLOGS.map((blog, index) => (
                        <div className="blogs__blog" key={index}>
                            <a
                                href={`https://myfreeqr.com/blog/${blog.url}`}
                                className="blogs__blog__link"
                            >
                                <div className="blogs__blog__img">
                                    <img src={blog.img} alt={blog.title} />
                                </div>
                                <h2 className="blogs__blog__title">
                                    {blog.title}
                                </h2>
                                <p className="blogs__blog__date">{blog.date}</p>
                                <p className="blogs__blog__description">
                                    {blog.description}
                                </p>
                            </a>
                        </div>
                    ))}
                </div>
            </div>

            <Footer from="home" />
        </>
    )
}

export default Blogs
