/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

/* Components */
import { openToast, ERROR, SUCCESS } from "components/toast/Toast"

/* Helpers */
import { CreateRandomId } from "helpers/CreateRandomId"
import { GetFileSizeFromURL } from "helpers/GetFileSizeFromURL"
import {
    CreateQRCodeFast,
    CreateQrCodeWithLogoFast,
} from "../helpers/CreateQrCode"
import { CreateCustomUrl } from "helpers/CreateCustomUrl"
import { ConvertUrlToBase64 } from "helpers/ConvertUrlToBase64"
import { checkImageExist } from "helpers/CheckImageExist"
import { LogEvent } from "helpers/LogEvents"

/* Redux */
import { reloadDataToken, updateQrFlows } from "./userDucks"

/* Firebase */
import { db, firebase, storage, functions } from "../controller/firebase"

/* I18n */
import i18n from "i18next"

/* Constants or States */
const initData = {
    loading: true,
    loadingMenu: false,
    loadingAddMenu: false,
    loadingCustomers: false,
    loadingQr: false,
    flowSelected: 0,
    flows: [{}],
    newImagesAdding: false,
    numNewImagesUploaded: 0,
    totalNewImagesUploaded: 0,
    percentage: 0,
}

/* const userIdTest = "eb0g1ITVjOhXC6S3antjatqYDT73"
const venueIdTest = "mzVKagwWA70bbuRL6zjz"
const qrFlowIdTest = "4DSKwmcHnWiU0uQiwx9Y" */

const LOADING_QR_FLOW = "LOADING_QR_FLOW"
const LOADING_QR_FLOW_MENU = "LOADING_QR_FLOW_MENU"
const LOADING_QR_FLOW_CUSTOMERS = "LOADING_QR_FLOW_CUSTOMERS"
const LOADING_QR_FLOW_QR = "LOADING_QR_FLOW_QR"

const START_LOADING_FLOW_MENU = "START_LOADING_FLOW_MENU"
const END_LOADING_FLOW_MENU = "END_LOADING_FLOW_MENU"

const CHANGE_QR_FLOW_SELECTED = "CHANGE_QR_FLOW_SELECTED"

const GET_QR_FLOW_EXIT = "GET_QR_FLOW_EXIT"
const GET_QR_FLOW_ERROR = "GET_QR_FLOW_ERROR"

const UPDATE_IMAGES_QR_FLOW_EXIT = "UPDATE_IMAGES_QR_FLOW_EXIT"
const UPDATE_IMAGES_QR_FLOW_ERROR = "UPDATE_IMAGES_QR_FLOW_ERROR"

const UPDATE_IMAGES_ADDING = "UPDATE_IMAGES_ADDING"

const SORT_IMAGES_QR_FLOW_EXIT = "SORT_IMAGES_QR_FLOW_EXIT"
const SORT_IMAGES_QR_FLOW_ERROR = "SORT_IMAGES_QR_FLOW_ERROR"

const DELETE_IMAGES_QR_FLOW_EXIT = "DELETE_IMAGES_QR_FLOW_EXIT"
const DELETE_IMAGES_QR_FLOW_ERROR = "DELETE_IMAGES_QR_FLOW_ERROR"

const START_LOADING_FLOW = "START_LOADING_FLOW"
const END_LOADING_FLOW = "END_LOADING_FLOW"

const START_LOADING_QR_FLOW = "START_LOADING_QR_FLOW"
const END_LOADING_QR_FLOW = "END_LOADING_QR_FLOW"

const START_LOADING_ADD_MENU = "START_LOADING_ADD_MENU"
const END_LOADING_ADD_MENU = "END_LOADING_ADD_MENU"

const CREATE_QR_FLOW_EXIT = "CREATE_QR_FLOW_EXIT"
const CREATE_QR_FLOW_ERROR = "CREATE_QR_FLOW_ERROR"

const DELETE_QR_FLOW_EXIT = "DELETE_QR_FLOW_EXIT"
const DELETE_QR_FLOW_ERROR = "DELETE_QR_FLOW_ERROR"

const UPDATE_QR_FLOW_EXIT = "UPDATE_QR_FLOW_EXIT"
const UPDATE_QR_FLOW_ERROR = "UPDATE_QR_FLOW_ERROR"

const UPDATE_WELCOME_QR_FLOW_EXIT = "UPDATE_WELCOME_QR_FLOW_EXIT"
const UPDATE_WELCOME_QR_FLOW_ERROR = "UPDATE_WELCOME_QR_FLOW_ERROR"

const UPDATE_SIGN_UP_QR_FLOW_EXIT = "UPDATE_SIGN_UP_QR_FLOW_EXIT"
const UPDATE_SIGN_UP_QR_FLOW_ERROR = "UPDATE_SIGN_UP_QR_FLOW_ERROR"

const UPDATE_QR_SELECTED_EXIT = "UPDATE_QR_SELECTED_EXIT"
const UPDATE_QR_SELECTED_ERROR = "UPDATE_QR_SELECTED_ERROR"

const UPDATE_CUSTOM_URL_EXIT = "UPDATE_CUSTOM_URL_EXIT"
const UPDATE_CUSTOM_URL_ERROR = "UPDATE_CUSTOM_URL_ERROR"

const CREATE_QR_CUSTOM_LOGO_EXIT = "CREATE_QR_CUSTOM_LOGO_EXIT"
const CREATE_QR_CUSTOM_LOGO_ERROR = "CREATE_QR_CUSTOM_LOGO_ERROR"

const DELETE_QR_CUSTOM_LOGO_EXIT = "DELETE_QR_CUSTOM_LOGO_EXIT"
const DELETE_QR_CUSTOM_LOGO_ERROR = "DELETE_QR_CUSTOM_LOGO_ERROR"

const RESTART_QR_FLOW = "RESTART_QR_FLOW"

/* Reducer (Save call API in constant) */
export default function qrFlowReducer(state = initData, action) {
    switch (action.type) {
        case LOADING_QR_FLOW:
            return { ...state, loading: true }
        case LOADING_QR_FLOW_MENU:
            return { ...state, loadingMenu: true }
        case LOADING_QR_FLOW_CUSTOMERS:
            return { ...state, loadingCustomers: true }
        case LOADING_QR_FLOW_QR:
            return { ...state, loadingQr: true }

        case START_LOADING_FLOW:
            return { ...state, loading: true }
        case END_LOADING_FLOW:
            return { ...state, loading: false }

        case START_LOADING_FLOW_MENU:
            return { ...state, loadingMenu: true }
        case END_LOADING_FLOW_MENU:
            return { ...state, loadingMenu: false }

        case START_LOADING_ADD_MENU:
            return {
                ...state,
                loadingAddMenu: true,
                numNewImagesUploaded: 0,
                totalNewImagesUploaded: 0,
                percentage: 0,
            }
        case END_LOADING_ADD_MENU:
            return { ...state, loadingAddMenu: false }

        case CHANGE_QR_FLOW_SELECTED:
            return { ...state, flowSelected: action.payload }

        case GET_QR_FLOW_EXIT:
            return { ...state, flows: [...action.payload], loading: false }
        case GET_QR_FLOW_ERROR:
            return { ...initData }

        case UPDATE_IMAGES_QR_FLOW_EXIT:
            return {
                ...state,
                flows: [...action.payload],
                loadingAddMenu: false,
                newImagesAdding: false,
                numNewImagesUploaded: 0,
                totalNewImagesUploaded: 0,
                percentage: 0,
            }
        case UPDATE_IMAGES_QR_FLOW_ERROR:
            return { ...state, loadingMenu: false }
        case UPDATE_IMAGES_ADDING:
            return {
                ...state,
                ...action.payload,
                newImagesAdding: true,
            }
        case SORT_IMAGES_QR_FLOW_EXIT:
            return { ...state, flows: [...action.payload], loadingMenu: false }
        case SORT_IMAGES_QR_FLOW_ERROR:
            return { ...state, loadingMenu: false }
        case DELETE_IMAGES_QR_FLOW_EXIT:
            return { ...state, flows: [...action.payload], loadingMenu: false }
        case DELETE_IMAGES_QR_FLOW_ERROR:
            return { ...state, loadingMenu: false }

        case UPDATE_WELCOME_QR_FLOW_EXIT:
            return {
                ...state,
                flows: [...action.payload],
                loadingCustomers: false,
            }
        case UPDATE_WELCOME_QR_FLOW_ERROR:
            return { ...state, loadingCustomers: false }

        case UPDATE_SIGN_UP_QR_FLOW_EXIT:
            return {
                ...state,
                flows: [...action.payload],
                loadingCustomers: false,
            }
        case UPDATE_SIGN_UP_QR_FLOW_ERROR:
            return { ...state, loadingCustomers: false }

        case UPDATE_CUSTOM_URL_EXIT:
            return { ...state, flows: [...action.payload], loading: false }
        case UPDATE_CUSTOM_URL_ERROR:
            return { ...state, loading: false }

        case START_LOADING_QR_FLOW:
            return { ...state, loadingQr: true }
        case END_LOADING_QR_FLOW:
            return { ...state, loadingQr: false }

        case UPDATE_QR_SELECTED_EXIT:
            return { ...state, flows: [...action.payload], loadingQr: false }
        case UPDATE_QR_SELECTED_ERROR:
            return { ...state, loadingQr: false }
        case CREATE_QR_CUSTOM_LOGO_EXIT:
            return { ...state, flows: [...action.payload], loadingQr: false }
        case CREATE_QR_CUSTOM_LOGO_ERROR:
            return { ...state, loadingQr: false }
        case DELETE_QR_CUSTOM_LOGO_EXIT:
            return { ...state, flows: [...action.payload], loadingQr: false }
        case DELETE_QR_CUSTOM_LOGO_ERROR:
            return { ...state, loadingQr: false }

        case CREATE_QR_FLOW_EXIT:
            return {
                ...state,
                flows: [...action.payload.flow],
                loading: false,
                flowSelected: action.payload.index,
            }
        case CREATE_QR_FLOW_ERROR:
            return { ...state, loading: false }
        case DELETE_QR_FLOW_EXIT:
            return {
                ...state,
                flows: [...action.payload],
                loading: false,
                flowSelected: 0,
            }
        case DELETE_QR_FLOW_ERROR:
            return { ...state, loading: false }
        case UPDATE_QR_FLOW_EXIT:
            return { ...state, flows: [...action.payload], loading: false }
        case UPDATE_QR_FLOW_ERROR:
            return { ...state, loading: false }

        case RESTART_QR_FLOW:
            return { ...initData }
        default:
            return { ...state }
    }
}

/* Actions (Calls API) */

export const getQrFlowsData =
    ({ readDB = false }) =>
    async (dispatch) => {
        // Init loading to create user
        if (!readDB) {
            dispatch({
                type: LOADING_QR_FLOW,
            })
        }

        // Validate if the data exist in the browser
        if (localStorage.getItem("qrFlow") && !readDB) {
            const qrFlows = JSON.parse(localStorage.getItem("qrFlow")).data
            qrFlows.forEach((value) => {
                if (value.showAds) {
                    value.showAds.offUntil = new Date(
                        Date.parse(value.showAds.offUntil)
                    )
                }
            })

            dispatch({
                type: GET_QR_FLOW_EXIT,
                payload: qrFlows,
            })
            return
        }

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            let uid = JSON.parse(localStorage.getItem("auth")).uid
            let venueId = JSON.parse(localStorage.getItem("user")).venueId
            let qrFlowsId = JSON.parse(localStorage.getItem("user")).qrFlowsId
            let data = []

            // Use this data if want change some user menu
            /* uid = userIdTest
            venueId = venueIdTest
            qrFlowsId = [qrFlowIdTest] */

            try {
                await Promise.all(
                    qrFlowsId.map(async (id) => {
                        const qrFlow = await db
                            .collection("users")
                            .doc(uid)
                            .collection("venues")
                            .doc(venueId)
                            .collection("qrFlows")
                            .doc(id)
                            .get()
                        data.push({ ...qrFlow.data(), venueId, qrFlowId: id })
                    })
                )

                // Convert logos into base64
                for (let i in data) {
                    const qrFlow = data[i]
                    if (qrFlow.qrCode.logo) {
                        const logoBase64 = await ConvertUrlToBase64(
                            qrFlow.qrCode.logo
                        )
                        data[i].qrCode.logo = logoBase64
                    }
                    if (qrFlow.showAds) {
                        data[i].showAds.offUntil = new Date(
                            qrFlow.showAds.offUntil.toDate()
                        )
                    }
                }

                if (data.length > 0) {
                    dispatch({
                        type: GET_QR_FLOW_EXIT,
                        payload: data,
                    })

                    // Save in the browser
                    localStorage.setItem(
                        "qrFlow",
                        JSON.stringify({
                            data,
                        })
                    )
                }
            } catch (error) {
                console.log(error)
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L261 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: GET_QR_FLOW_ERROR,
                })
            }
        }
    }

export const changeQrFlowSelected =
    ({ index = 0 }) =>
    (dispatch, getState) => {
        dispatch({
            type: CHANGE_QR_FLOW_SELECTED,
            payload: index,
        })
    }

/* Menus */

export const addImagesQrFlow =
    ({ data = {}, images = [], waitData = {} }) =>
    async (dispatch, getState) => {
        if (localStorage.getItem("auth")) {
            try {
                // Get user's uid from browser
                let uid = JSON.parse(localStorage.getItem("auth")).uid
                let newImages = []
                const newImagesAnalytics = []

                // Percentage value
                let numImages = 0

                // Use this data if want change some user menu
                /* uid = userIdTest
                data.venueId = venueIdTest
                data.qrFlowId = qrFlowIdTest */

                // Get data to save wait upload
                const waitDataDB = await db
                    .collection("waitUpload")
                    .doc(uid)
                    .get()
                const waitDataSaved = []
                const percentageGlobal = [0]

                if (waitDataDB.exists) {
                    waitDataSaved.push(...waitDataDB.data().data)
                } else {
                    await db.collection("waitUpload").doc(uid).set({
                        data: [],
                    })
                }

                waitDataSaved.push({
                    date: new Date(),
                    ...waitData,
                    venueId: data.venueId,
                    qrFlowId: data.qrFlowId,
                    percentage: percentageGlobal,
                })

                let weight = 100
                let isPdf = false

                if (waitData.format === "pdf" || waitData.format === "mixed") {
                    weight = 65
                    isPdf = true
                }

                let currentNumFIle = -1
                let currentPdfId = ""

                dispatch({
                    type: UPDATE_IMAGES_ADDING,
                    payload: {
                        totalNewImagesUploaded: images.length,
                    },
                })

                // Read all image to add in data base
                await Promise.all(
                    images.map(async (image, index) => {
                        // Create random ID to the image
                        let randomID = CreateRandomId(20)
                        if (image.pdf) {
                            if (image.numFile === currentNumFIle) {
                                randomID = randomID + "_" + currentPdfId
                            } else {
                                currentPdfId = CreateRandomId(5)
                                currentNumFIle = image.numFile
                                randomID = randomID + "_" + currentPdfId
                            }
                        }

                        if (data.flowSequence.showMenu.length !== undefined) {
                            if (image.pdf) {
                                // Validate if the PDF id exist in another file
                                let imageSave = data.flowSequence.showMenu.find(
                                    (element) =>
                                        element.id.split("_")[1] ===
                                        randomID.split("_")[1]
                                )

                                while (imageSave !== undefined) {
                                    currentPdfId = CreateRandomId(5)
                                    randomID =
                                        CreateRandomId(20) + "_" + currentPdfId
                                    imageSave = data.flowSequence.showMenu.find(
                                        (element) =>
                                            element.id.split("_")[1] ===
                                            randomID.split("_")[1]
                                    )
                                }
                            } else {
                                // Validate if the randomID exist in another file
                                let imageSave = data.flowSequence.showMenu.find(
                                    (element) => element.id === randomID
                                )

                                while (imageSave !== undefined) {
                                    randomID = CreateRandomId(20)
                                    imageSave = data.flowSequence.showMenu.find(
                                        (element) => element.id === randomID
                                    )
                                }
                            }
                        }

                        // Save image in the storage
                        const metaData = { contentType: image.type }
                        const res = await storage
                            .ref()
                            .child(
                                uid +
                                    "/" +
                                    data.venueId +
                                    "/" +
                                    data.qrFlowId +
                                    "/images/"
                            )
                            .child(randomID + ".png")
                            .put(image, metaData)
                        const imageURL = await res.ref.getDownloadURL()

                        newImages.push({ url: imageURL, id: randomID, index })
                        newImagesAnalytics.push({ url: imageURL, image: image })

                        // Update percentage images
                        numImages++
                        updateImageAdding({
                            numImage: numImages,
                            totalImages: images.length,
                            weight,
                            isPdf,
                        })(dispatch)

                        // Update percentage to waitData
                        const percentage = Math.round(
                            (numImages * 100) / images.length
                        )
                        percentageGlobal.push(percentage)
                        waitDataSaved[waitDataSaved.length - 1].percentage =
                            percentageGlobal
                        // Save waitData in DB
                        db.collection("waitUpload")
                            .doc(uid)
                            .update({ data: [...waitDataSaved] })
                    })
                )

                // Order images
                newImages.sort((a, b) => a.index - b.index)

                // Remove index
                newImages = newImages.map((item, index) => {
                    return { url: item.url, id: item.id }
                })

                // Update firestore
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        "flowSequence.showMenu":
                            firebase.firestore.FieldValue.arrayUnion(
                                ...newImages
                            ),
                        "flowSequence.format": waitData.format,
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                // Create new flow with the showMenu updated
                //const flow = {...data, flowSequence: {...data.flowSequence, showMenu: [...data.flowSequence.showMenu, ...newImages] } }

                // Update the flow with the current flow
                const { flows } = getState().qrFlow
                const newFlow = flows.map((flow) =>
                    flow.qrFlowId === data.qrFlowId
                        ? {
                              ...flow,
                              flowSequence: {
                                  ...flow.flowSequence,
                                  showMenu: [
                                      ...flow.flowSequence.showMenu,
                                      ...newImages,
                                  ],
                                  format: waitData.format,
                              },
                          }
                        : { ...flow }
                )

                dispatch({
                    type: UPDATE_IMAGES_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )

                // Save analytics
                for (let i = 0; i < newImagesAnalytics.length; i++) {
                    const image = newImagesAnalytics[i].image
                    const imageURL = newImagesAnalytics[i].url

                    // Save the size in Kilobytes
                    const sizeKbBefore = (image.size / 1024).toFixed(1)

                    // Get image info from url
                    const httpsReference = storage.refFromURL(imageURL)
                    const nameAfter = httpsReference.name
                    const formatAfter = httpsReference.name.split(".")[1]
                    let sizeKbAfter = 0
                    await GetFileSizeFromURL(imageURL, function (size) {
                        sizeKbAfter = (size / 1024).toFixed(1)
                    })

                    // Validate formats
                    let weirdFormat = false
                    if (
                        formatAfter !== "png" ||
                        (image.type !== "image/png" &&
                            image.type !== "image/jpg" &&
                            image.type !== "image/jpeg")
                    ) {
                        weirdFormat = true
                    }
                    // Validate size
                    let sizeDiff = (
                        ((sizeKbAfter - sizeKbBefore) / sizeKbBefore) *
                        100
                    ).toFixed(0)

                    // Create descriptions with all parameters
                    const imageDescription = `${image.type}-${sizeKbBefore} + ${uid}-${nameAfter}-${formatAfter}-${sizeKbAfter} + ${weirdFormat}-${sizeDiff}`
                    const pathDescription = `${uid}-${data.venueId}-${data.qrFlowId} + ${weirdFormat}-${sizeDiff}`

                    LogEvent("menu_file_uploaded", {
                        format_before: image.type,
                        name_before: image.name,
                        size_before: sizeKbBefore,

                        format_after: formatAfter,
                        name_after: nameAfter,
                        size_after: sizeKbAfter,

                        weird_format: weirdFormat,
                        size_diff: sizeDiff,

                        new_user_id: uid,
                        venue_id: data.venueId,
                        qr_flow_id: data.qrFlowId,

                        image_description: imageDescription,
                        path_description: pathDescription,
                    })
                }
            } catch (error) {
                console.log(error)
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L423 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_IMAGES_QR_FLOW_ERROR,
                })
            }
        } else {
            LogEvent("new_error", {
                description: `L644 @ qrFlowDucks.jsx | error qr code - no auth`,
            })

            dispatch({
                type: UPDATE_IMAGES_QR_FLOW_ERROR,
            })
        }
    }

export const updateImageAdding =
    ({ numImage = 0, totalImages = 0, weight = 100, isPdf = false }) =>
    (dispatch) => {
        let percentage = (numImage * weight) / totalImages

        if (percentage) {
            percentage = Math.round(percentage)
        } else {
            percentage = 0
        }

        if (isPdf) {
            percentage += 35
        }

        dispatch({
            type: UPDATE_IMAGES_ADDING,
            payload: {
                numImage: numImage,
                percentage: percentage,
            },
        })
    }

export const sortImagesQrFlow =
    ({ images = {} }) =>
    async (dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_QR_FLOW_MENU,
        })

        if (localStorage.getItem("auth")) {
            try {
                // Get user's uid from browser
                let uid = JSON.parse(localStorage.getItem("auth")).uid
                const { flows } = getState().qrFlow
                const { flowSelected } = getState().qrFlow

                const data = flows[flowSelected]

                // Use this data if want change some user menu
                /* uid = userIdTest
                data.venueId = venueIdTest
                data.qrFlowId = qrFlowIdTest */

                // update from data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        "flowSequence.showMenu": images,
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const flow = {
                    ...data,
                    flowSequence: { ...data.flowSequence, showMenu: images },
                }

                // Update the flow with the current flow
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId ? { ...flow } : { ...item }
                )

                dispatch({
                    type: SORT_IMAGES_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )
            } catch (error) {
                console.log(error)
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L491 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: SORT_IMAGES_QR_FLOW_ERROR,
                })
            }
        } else {
            LogEvent("new_error", {
                description: `L755 @ qrFlowDucks.jsx | error qr code - no auth`,
            })

            dispatch({
                type: SORT_IMAGES_QR_FLOW_ERROR,
            })
        }
    }

export const deleteBrokenImageQrFlow =
    ({ data = {}, brokenImages = [] }) =>
    async (dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_QR_FLOW_MENU,
        })

        if (localStorage.getItem("auth")) {
            try {
                // Get user's uid from browser
                const uid = JSON.parse(localStorage.getItem("auth")).uid
                const { flows } = getState().qrFlow

                // Get the current data in the menu
                const currentShowMenu = await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .get()

                const deleteIds = []

                brokenImages.forEach((item) => {
                    // Create descriptions with all parameters
                    const imageDescription = `${uid} + ${item.id}`
                    const pathDescription = `${uid}-${data.venueId}-${data.qrFlowId}`

                    LogEvent("delete_broken_thumbnail", {
                        new_user_id: uid,
                        venue_id: data.venueId,
                        qr_flow_id: data.qrFlowId,

                        file_name: item.id,

                        image_description: imageDescription,
                        path_description: pathDescription,
                    })

                    // Save id
                    deleteIds.push(item.id)
                })

                // Delete image in the index send
                const images = currentShowMenu
                    .data()
                    .flowSequence.showMenu.filter(
                        (item, index) => !deleteIds.includes(item.id)
                    )

                // Delete from data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        "flowSequence.showMenu": images,
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const flow = {
                    ...data,
                    flowSequence: { ...data.flowSequence, showMenu: images },
                }

                // Update the flow with the current flow
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId ? { ...flow } : { ...item }
                )

                dispatch({
                    type: DELETE_IMAGES_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L706 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: DELETE_IMAGES_QR_FLOW_ERROR,
                })
            }
        } else {
            LogEvent("new_error", {
                description: `L870 @ qrFlowDucks.jsx | error qr code - no auth`,
            })

            dispatch({
                type: DELETE_IMAGES_QR_FLOW_ERROR,
            })
        }
    }

export const deleteAllImagesQrFlow =
    ({ data = {} }) =>
    async (dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_QR_FLOW_MENU,
        })

        if (localStorage.getItem("auth")) {
            try {
                // Get user's uid from browser
                const uid = JSON.parse(localStorage.getItem("auth")).uid
                const { flows } = getState().qrFlow

                Promise.all(
                    data.flowSequence.showMenu.map(async (item, index) => {
                        // Get deleted image
                        const deletedImage = item

                        // Get image info from url
                        const httpsReference = storage.refFromURL(
                            deletedImage.url
                        )
                        const imageDeletedFormat =
                            httpsReference.name.split(".")[1]
                        let imageDeletedSizeKb = 0
                        await GetFileSizeFromURL(
                            deletedImage.url,
                            function (size) {
                                imageDeletedSizeKb = (size / 1024).toFixed(1)
                            }
                        )

                        // Create descriptions with all parameters
                        const imageDescription = `${uid} + ${"NaN"}-${
                            deletedImage.id
                        }-${imageDeletedSizeKb}-${imageDeletedFormat}`
                        const pathDescription = `${uid}-${data.venueId}-${data.qrFlowId}`

                        LogEvent("delete_thumbnail", {
                            new_user_id: uid,
                            venue_id: data.venueId,
                            qr_flow_id: data.qrFlowId,
                            num_pages: "NaN",

                            file_name: deletedImage.id,
                            file_size: imageDeletedSizeKb,
                            file_format: imageDeletedFormat,

                            image_description: imageDescription,
                            path_description: pathDescription,
                        })

                        // Delete from the storage
                        await storage
                            .ref()
                            .child(
                                uid +
                                    "/" +
                                    data.venueId +
                                    "/" +
                                    data.qrFlowId +
                                    "/images/"
                            )
                            .child(deletedImage.id + ".png")
                            .delete()
                    })
                )

                // Delete image in the index send
                const images = []

                let format = ""

                // Delete from data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        "flowSequence.showMenu": images,
                        "flowSequence.format": format,
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const flow = {
                    ...data,
                    flowSequence: {
                        ...data.flowSequence,
                        showMenu: images,
                        format,
                    },
                }

                // Update the flow with the current flow
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId ? { ...flow } : { ...item }
                )

                dispatch({
                    type: DELETE_IMAGES_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L978 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: DELETE_IMAGES_QR_FLOW_ERROR,
                })
            }
        } else {
            LogEvent("new_error", {
                description: `L1011 @ qrFlowDucks.jsx | error qr code - no auth`,
            })

            dispatch({
                type: DELETE_IMAGES_QR_FLOW_ERROR,
            })
        }
    }

export const deleteBatchImagesQrFlow =
    ({ data = {}, files = [], filesDelete = [] }) =>
    async (dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_QR_FLOW_MENU,
        })

        if (localStorage.getItem("auth")) {
            try {
                // Get user's uid from browser
                let uid = JSON.parse(localStorage.getItem("auth")).uid
                const { flows } = getState().qrFlow

                // Use this data if want change some user menu
                /* uid = userIdTest
                data.venueId = venueIdTest
                data.qrFlowId = qrFlowIdTest */

                Promise.all(
                    filesDelete.map(async (item, index) => {
                        // Get deleted image
                        const deletedImage = item

                        // Get image info from url
                        const httpsReference = storage.refFromURL(
                            deletedImage.url
                        )
                        const imageDeletedFormat =
                            httpsReference.name.split(".")[1]
                        let imageDeletedSizeKb = 0
                        await GetFileSizeFromURL(
                            deletedImage.url,
                            function (size) {
                                imageDeletedSizeKb = (size / 1024).toFixed(1)
                            }
                        )

                        // Create descriptions with all parameters
                        const imageDescription = `${uid} + ${"NaN"}-${
                            deletedImage.id
                        }-${imageDeletedSizeKb}-${imageDeletedFormat}`
                        const pathDescription = `${uid}-${data.venueId}-${data.qrFlowId}`

                        LogEvent("delete_thumbnail", {
                            new_user_id: uid,
                            venue_id: data.venueId,
                            qr_flow_id: data.qrFlowId,
                            num_pages: "NaN",

                            file_name: deletedImage.id,
                            file_size: imageDeletedSizeKb,
                            file_format: imageDeletedFormat,

                            image_description: imageDescription,
                            path_description: pathDescription,
                        })

                        // Delete from the storage
                        await storage
                            .ref()
                            .child(
                                uid +
                                    "/" +
                                    data.venueId +
                                    "/" +
                                    data.qrFlowId +
                                    "/images/"
                            )
                            .child(deletedImage.id + ".png")
                            .delete()
                    })
                )

                // Delete image in the index send
                let images = files.filter(
                    (item, index) =>
                        !item.id.includes(`_${filesDelete[0].id.split("_")[1]}`)
                )

                let format = ""

                // Delete from data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        "flowSequence.showMenu": images,
                        "flowSequence.format": format,
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const flow = {
                    ...data,
                    flowSequence: {
                        ...data.flowSequence,
                        showMenu: images,
                        format,
                    },
                }

                // Update the flow with the current flow
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId ? { ...flow } : { ...item }
                )

                dispatch({
                    type: DELETE_IMAGES_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")

                // Save analytics
                LogEvent("new_error", {
                    description: `L1114 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: DELETE_IMAGES_QR_FLOW_ERROR,
                })
            }
        } else {
            LogEvent("new_error", {
                description: `L1161 @ qrFlowDucks.jsx | error qr code - no auth`,
            })

            dispatch({
                type: DELETE_IMAGES_QR_FLOW_ERROR,
            })
        }
    }

export const deleteImageQrFlow =
    ({ data = {}, idImage, urlImage, numPage, files }) =>
    async (dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_QR_FLOW_MENU,
        })

        if (localStorage.getItem("auth")) {
            try {
                // Get user's uid from browser
                let uid = JSON.parse(localStorage.getItem("auth")).uid
                const { flows } = getState().qrFlow

                // Use this data if want change some user menu
                /* uid = userIdTest
                data.venueId = venueIdTest
                data.qrFlowId = qrFlowIdTest */

                // Get deleted image
                const deletedImage = files.find((item) => item.id === idImage)

                // Get image info from url
                const httpsReference = storage.refFromURL(deletedImage.url)
                const imageDeletedFormat = httpsReference.name.split(".")[1]
                let imageDeletedSizeKb = 0
                await GetFileSizeFromURL(deletedImage.url, function (size) {
                    imageDeletedSizeKb = (size / 1024).toFixed(1)
                })

                // Create descriptions with all parameters
                const imageDescription = `${uid} + ${numPage + 1}-${
                    deletedImage.id
                }-${imageDeletedSizeKb}-${imageDeletedFormat}`
                const pathDescription = `${uid}-${data.venueId}-${data.qrFlowId}`

                LogEvent("delete_thumbnail", {
                    new_user_id: uid,
                    venue_id: data.venueId,
                    qr_flow_id: data.qrFlowId,
                    num_pages: numPage + 1,

                    file_name: deletedImage.id,
                    file_size: imageDeletedSizeKb,
                    file_format: imageDeletedFormat,

                    image_description: imageDescription,
                    path_description: pathDescription,
                })

                // Delete image in the index send
                const images = files.filter(
                    (item, index) => item.id !== idImage
                )

                let format = data.flowSequence.format
                if (images.length <= 0) {
                    format = ""
                }

                // Delete from the storage
                await storage
                    .ref()
                    .child(
                        uid +
                            "/" +
                            data.venueId +
                            "/" +
                            data.qrFlowId +
                            "/images/"
                    )
                    .child(idImage + ".png")
                    .delete()

                // Delete from data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        "flowSequence.showMenu": images,
                        "flowSequence.format": format,
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const flow = {
                    ...data,
                    flowSequence: {
                        ...data.flowSequence,
                        showMenu: images,
                        format,
                    },
                }

                // Update the flow with the current flow
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId ? { ...flow } : { ...item }
                )

                dispatch({
                    type: DELETE_IMAGES_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )
            } catch (error) {
                // Validate if the error is due to image broken
                checkImageExist(urlImage)
                    .then((res) => {
                        if (res) {
                            // Show toast unexpected error reload
                            document
                                .getElementById("toast-unexpected-error")
                                .classList.remove("hide")
                            // Save analytics
                            LogEvent("new_error", {
                                description: `L830 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                            })
                        } else {
                            deleteBrokenImageQrFlow({ data, idImage })(
                                dispatch,
                                getState
                            )
                        }
                    })
                    .catch((err) => {
                        // Show toast unexpected error reload
                        document
                            .getElementById("toast-unexpected-error")
                            .classList.remove("hide")
                        // Save analytics
                        LogEvent("new_error", {
                            description: `L846 @ qrFlowDucks.js | ${err.code} - ${err.message}`,
                        })
                    })

                dispatch({
                    type: DELETE_IMAGES_QR_FLOW_ERROR,
                })
            }
        } else {
            LogEvent("new_error", {
                description: `L1161 @ qrFlowDucks.jsx | error qr code - no auth`,
            })

            dispatch({
                type: DELETE_IMAGES_QR_FLOW_ERROR,
            })
        }
    }

export const starLoading = () => async (dispatch) => {
    dispatch({
        type: START_LOADING_FLOW,
    })
}

export const endLoading = () => async (dispatch) => {
    dispatch({
        type: END_LOADING_FLOW,
    })
}

export const starMenuLoading = () => async (dispatch) => {
    dispatch({
        type: START_LOADING_FLOW_MENU,
    })
}

export const endMenuLoading = () => async (dispatch) => {
    dispatch({
        type: END_LOADING_FLOW_MENU,
    })
}

export const starAddMenuLoading = () => async (dispatch) => {
    dispatch({
        type: START_LOADING_ADD_MENU,
    })
}

export const endAddMenuLoading = () => async (dispatch) => {
    dispatch({
        type: END_LOADING_ADD_MENU,
    })
}

/* Customers */

/* export const updateWelcomeQrFlow =
    ({ data = {}, welcomeData = {} }) =>
    async(dispatch, getState) => {
        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            const { flows } = getState().qrFlow
            let flow

            try {
                if (welcomeData.file) {
                    // Init loading
                    dispatch({
                        type: LOADING_QR_FLOW_CUSTOMERS,
                    })

                    if (welcomeData.file === "DELETE") {
                        // Update data base
                        await db
                            .collection("users")
                            .doc(uid)
                            .collection("venues")
                            .doc(data.venueId)
                            .collection("qrFlows")
                            .doc(data.qrFlowId)
                            .update({
                                "flowSequence.newsLetterWelcome": {
                                    ...data.flowSequence.newsLetterWelcome,
                                    image: null,
                                },
                            })

                        // Reload data token
                        reloadDataToken()(dispatch)

                        flow = {
                            ...data,
                            flowSequence: {
                                ...data.flowSequence,
                                newsLetterWelcome: {
                                    ...data.flowSequence.newsLetterWelcome,
                                    image: null,
                                },
                            },
                        }
                    } else {
                        const image = welcomeData.file

                        // Save image in the storage
                        const metaData = { contentType: image.type }
                        const res = await storage
                            .ref()
                            .child(
                                uid +
                                "/" +
                                data.venueId +
                                "/" +
                                data.qrFlowId +
                                "/sign up/"
                            )
                            .child("logo.png")
                            .put(image, metaData)
                        const imageURL = await res.ref.getDownloadURL()

                        // Update data base
                        await db
                            .collection("users")
                            .doc(uid)
                            .collection("venues")
                            .doc(data.venueId)
                            .collection("qrFlows")
                            .doc(data.qrFlowId)
                            .update({
                                "flowSequence.newsLetterWelcome": {
                                    ...data.flowSequence.newsLetterWelcome,
                                    image: imageURL,
                                },
                            })

                        // Reload data token
                        reloadDataToken()(dispatch)

                        flow = {
                            ...data,
                            flowSequence: {
                                ...data.flowSequence,
                                newsLetterWelcome: {
                                    ...data.flowSequence.newsLetterWelcome,
                                    image: imageURL,
                                },
                            },
                        }
                    }
                } else {
                    // Update data base
                    await db
                        .collection("users")
                        .doc(uid)
                        .collection("venues")
                        .doc(data.venueId)
                        .collection("qrFlows")
                        .doc(data.qrFlowId)
                        .update({
                            "flowSequence.newsLetterWelcome": {
                                ...data.flowSequence.newsLetterWelcome,
                                ...welcomeData,
                            },
                        })

                    // Reload data token
                    reloadDataToken()(dispatch)

                    flow = {
                        ...data,
                        flowSequence: {
                            ...data.flowSequence,
                            newsLetterWelcome: {
                                ...data.flowSequence.newsLetterWelcome,
                                ...welcomeData,
                            },
                        },
                    }
                }

                // Update the flow with the current flow
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId ? {...flow } : {...item }
                )

                dispatch({
                    type: UPDATE_WELCOME_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                    // Save analytics
                LogEvent("new_error", {
                    description: `L725 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_WELCOME_QR_FLOW_ERROR,
                })
            }
        }
    } */

/* export const updateSignUpQrFlow =
    ({ data = {}, signUpData = {} }) =>
    async(dispatch, getState) => {
        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            const { flows } = getState().qrFlow

            try {
                // Update data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        "flowSequence.newsLetterSignUp": {
                            ...data.flowSequence.newsLetterSignUp,
                            ...signUpData,
                        },
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const flow = {
                    ...data,
                    flowSequence: {
                        ...data.flowSequence,
                        newsLetterSignUp: {
                            ...data.flowSequence.newsLetterSignUp,
                            ...signUpData,
                        },
                    },
                }

                // Update the flow with the current flow
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId ? {...flow } : {...item }
                )

                dispatch({
                    type: UPDATE_SIGN_UP_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                    // Save analytics
                LogEvent("new_error", {
                    description: `L794 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_SIGN_UP_QR_FLOW_ERROR,
                })
            }
        }
    } */

/* QR */

/* export const updateQrSelected =
    ({ data = {}, selectedOption = "noLogo" }) =>
    async(dispatch, getState) => {
        // Init loading
        dispatch({
            type: LOADING_QR_FLOW_QR,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            const { flows } = getState().qrFlow

            try {
                // Update data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        "qrCode.selectedOption": selectedOption,
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const flow = {
                    ...data,
                    qrCode: {...data.qrCode, selectedOption: selectedOption },
                }

                // Update the flow with the current flow
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId ? {...flow } : {...item }
                )

                dispatch({
                    type: UPDATE_QR_SELECTED_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                    // Save analytics
                LogEvent("new_error", {
                    description: `L861 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_QR_SELECTED_ERROR,
                })
            }
        }
    } */

export const updateQrCustomLogo =
    ({ data = {}, file = null, logo = null, config = {}, style = "normal" }) =>
    async (dispatch, getState) => {
        if (localStorage.getItem("auth")) {
            try {
                // Get user's uid from browser
                const uid = JSON.parse(localStorage.getItem("auth")).uid
                const { flows } = getState().qrFlow

                // Get prices type from the user
                const { pricesType } = getState().user.data
                let prices = 6
                // Validate that the prices type exist
                if (pricesType) {
                    prices = pricesType
                }

                const configQR = { ...config }
                // Validate style
                if (style === "inverted") {
                    const bodyColor = configQR.bodyColor
                    const bgColor = configQR.bgColor

                    configQR.bgColor = bodyColor
                    configQR.bodyColor = bgColor
                    configQR.eye1Color = bgColor
                    configQR.eye2Color = bgColor
                    configQR.eye3Color = bgColor
                    configQR.eyeBall1Color = bgColor
                    configQR.eyeBall2Color = bgColor
                    configQR.eyeBall3Color = bgColor
                }

                const qrCustomLogo = await CreateQrCodeWithLogoFast({
                    url: `${window.origin}/scan/${uid}/${data.venueId}/${data.qrFlowId}`,
                    logo: file,
                    pricesType: prices,
                    userId: uid,
                    venueId: data.venueId,
                    qrFlowId: data.qrFlowId,
                    config: configQR,
                })

                const flow = {
                    ...data,
                    qrCode: {
                        ...data.qrCode,
                        config: { ...config },
                        style: style,
                        logo: logo,
                        options: {
                            ...data.qrCode.options,
                            customLogo: qrCustomLogo.image,
                        },
                        selectedOption: "customLogo",
                    },
                }

                // Update the flow with the current flow
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId ? { ...flow } : { ...item }
                )

                dispatch({
                    type: CREATE_QR_CUSTOM_LOGO_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )

                // Save image in the storage
                const metaDataQrDefaultLogo = {
                    contentType: qrCustomLogo.file.type,
                }
                const resQrDefaultLogoURL = await storage
                    .ref()
                    .child(
                        uid + "/" + data.venueId + "/" + data.qrFlowId + "/QR/"
                    )
                    .child("customLogo.png")
                    .put(qrCustomLogo.file, metaDataQrDefaultLogo)
                const qrCustomLogoURL =
                    await resQrDefaultLogoURL.ref.getDownloadURL()

                // Save logo in the storage
                const metaDataLogo = { contentType: file.type }
                const resLogoURL = await storage
                    .ref()
                    .child(
                        uid + "/" + data.venueId + "/" + data.qrFlowId + "/QR/"
                    )
                    .child("logo.png")
                    .put(file, metaDataLogo)
                const logoURL = await resLogoURL.ref.getDownloadURL()

                // Update data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        "qrCode.config": { ...config },
                        "qrCode.style": style,
                        "qrCode.logo": logoURL,
                        "qrCode.options.customLogo": qrCustomLogoURL,
                        "qrCode.selectedOption": "customLogo",
                    })

                // Update reminders
                // Save pdf in storage
                /* const { html } = await createEasyPdfHtml(qrCustomLogoURL)

                // Config canvas options
                let html2canvasOpts = {
                    dpi: 192,
                    scale: 3,
                    letterRendering: true,
                    useCORS: true,
                    width: 794,
                    height: 992,
                }

                const pdfBase64 = await html2pdf()
                    .set({
                        html2canvas: html2canvasOpts,
                        jsPDF: {
                            unit: "mm",
                            format: "a4",
                            orientation: "portrait",
                        },
                    })
                    .from(html.children[0])
                    .outputPdf("datauristring")

                //const resPdf = await storage
                await storage
                    .ref()
                    .child(
                        uid + "/" + data.venueId + "/" + data.qrFlowId + "/QR/"
                    )
                    .child("reminder.pdf")
                    .putString(pdfBase64, "data_url") */

                // URL of qr pdf
                //const pdfQrURL = await resPdf.ref.getDownloadURL()

                // Reload data token
                reloadDataToken()(dispatch)
            } catch (error) {
                console.log(error)

                // Show toast unexpected error reload
                //document.getElementById("toast-unexpected-error").classList.remove("hide")

                // Show toast
                openToast({
                    content: i18n.t("toastErrorQRcodeNotCreated"),
                    type: ERROR,
                })

                // Save analytics
                LogEvent("new_error", {
                    description: `L985 @ qrFlowDucks.js | ${error.message}`,
                })

                dispatch({
                    type: CREATE_QR_CUSTOM_LOGO_ERROR,
                })
            }
        } else {
            LogEvent("new_error", {
                description: `L1802 @ qrFlowDucks.jsx | error qr code - no auth`,
            })

            dispatch({
                type: CREATE_QR_CUSTOM_LOGO_ERROR,
            })
        }
    }

export const updateQrNoLogo =
    ({ data = {}, config = {}, style = "normal" }) =>
    async (dispatch, getState) => {
        if (localStorage.getItem("auth")) {
            try {
                // Get user's uid from browser
                const uid = JSON.parse(localStorage.getItem("auth")).uid
                const { flows } = getState().qrFlow

                // Get prices type from the user
                const { pricesType } = getState().user.data
                let prices = 6

                // Validate that the prices type exist
                if (pricesType) {
                    prices = pricesType
                }

                const configQR = { ...config }

                // Validate style
                if (style === "inverted") {
                    const bodyColor = configQR.bodyColor
                    const bgColor = configQR.bgColor

                    configQR.bgColor = bodyColor
                    configQR.bodyColor = bgColor
                    configQR.eye1Color = bgColor
                    configQR.eye2Color = bgColor
                    configQR.eye3Color = bgColor
                    configQR.eyeBall1Color = bgColor
                    configQR.eyeBall2Color = bgColor
                    configQR.eyeBall3Color = bgColor
                }

                const qrNoLogo = await CreateQRCodeFast({
                    url: `${window.origin}/scan/${uid}/${data.venueId}/${data.qrFlowId}`,
                    pricesType: prices,
                    userId: uid,
                    venueId: data.venueId,
                    qrFlowId: data.qrFlowId,
                    config: configQR,
                })

                const flow = {
                    ...data,
                    qrCode: {
                        ...data.qrCode,
                        config: { ...config },
                        style: style,
                        logo: null,
                        options: {
                            ...data.qrCode.options,
                            noLogo: qrNoLogo.image,
                        },
                        selectedOption: "noLogo",
                    },
                }

                // Update the flow with the current flow
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId ? { ...flow } : { ...item }
                )

                dispatch({
                    type: CREATE_QR_CUSTOM_LOGO_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )

                // Save image in the storage
                const metaDataQrDefaultLogo = {
                    contentType: qrNoLogo.file.type,
                }
                const resQrDefaultLogoURL = await storage
                    .ref()
                    .child(
                        uid + "/" + data.venueId + "/" + data.qrFlowId + "/QR/"
                    )
                    .child("noLogo.png")
                    .put(qrNoLogo.file, metaDataQrDefaultLogo)
                const qrNoLogoURL =
                    await resQrDefaultLogoURL.ref.getDownloadURL()

                // Update data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        "qrCode.config": { ...config },
                        "qrCode.style": style,
                        "qrCode.logo": null,
                        "qrCode.options.noLogo": qrNoLogoURL,
                        "qrCode.selectedOption": "noLogo",
                    })

                // Update reminders
                // Save pdf in storage
                /* const { html } = await createEasyPdfHtml(qrNoLogoURL)

                // Config canvas options
                let html2canvasOpts = {
                    dpi: 192,
                    scale: 3,
                    letterRendering: true,
                    useCORS: true,
                    width: 794,
                    height: 992,
                }
                const pdfBase64 = await html2pdf()
                    .set({
                        html2canvas: html2canvasOpts,
                        jsPDF: {
                            unit: "mm",
                            format: "a4",
                            orientation: "portrait",
                        },
                    })
                    .from(html.children[0])
                    .outputPdf("datauristring")

                //const resPdf = await storage
                await storage
                    .ref()
                    .child(
                        uid + "/" + data.venueId + "/" + data.qrFlowId + "/QR/"
                    )
                    .child("reminder.pdf")
                    .putString(pdfBase64, "data_url") */

                // URL of qr pdf
                //const pdfQrURL = await resPdf.ref.getDownloadURL()

                // Reload data token
                reloadDataToken()(dispatch)
            } catch (error) {
                // Show toast unexpected error reload
                //document.getElementById("toast-unexpected-error").classList.remove("hide")

                // Show toast
                openToast({
                    content: i18n.t("toastErrorQRcodeNotCreated"),
                    type: ERROR,
                })

                // Save analytics
                LogEvent("new_error", {
                    description: `L1098 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: CREATE_QR_CUSTOM_LOGO_ERROR,
                })
            }
        } else {
            LogEvent("new_error", {
                description: `L1977 @ qrFlowDucks.jsx | error qr code - no auth`,
            })

            dispatch({
                type: CREATE_QR_CUSTOM_LOGO_ERROR,
            })
        }
    }

export const starQrLoading = () => (dispatch) => {
    dispatch({
        type: START_LOADING_QR_FLOW,
    })
}

export const endQrLoading = () => (dispatch) => {
    dispatch({
        type: END_LOADING_QR_FLOW,
    })
}

export const createQrFlow =
    ({ venueId = "", name = "", index }) =>
    async (dispatch, getState) => {
        // Init loading
        dispatch({
            type: LOADING_QR_FLOW,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            const qrFlowsId = JSON.parse(localStorage.getItem("user")).qrFlowsId
            const { flows } = getState().qrFlow
            let idFlow = ""

            // Data from user
            const userData = getState().user.data

            // Get prices type from the user
            const { pricesType } = getState().user.data
            let prices = 6
            // Validate that the prices type exist
            if (pricesType) {
                prices = pricesType
            }

            try {
                // Flow structure
                const newFlow = {
                    name: name,
                    qrCode: {
                        config: {
                            body: "square",
                            eye: "square",
                            eyeBall: "square",
                            erf1: ["fh"],
                            brf1: ["fh"],
                            erf3: ["fv", "fh"],
                            brf3: ["fv", "fh"],
                            bodyColor: "#000",
                            bgColor: "#fff",
                            eye1Color: "#000",
                            eye2Color: "#000",
                            eye3Color: "#000",
                            eyeBall1Color: "#000",
                            eyeBall2Color: "#000",
                            eyeBall3Color: "#000",
                        },
                        style: "normal",
                        logo: null,
                        options: {
                            noLogo: null,
                            defaultLogo: null,
                            customLogo: null,
                        },
                        selectedOption: "defaultLogo",
                    },
                    flowSequence: {
                        newsLetterSignUp: {
                            active: false,
                            text: "Subscribe to our newsletter",
                            firstName: false,
                            lastName: false,
                            emailAddress: true,
                            phoneNumber: false,
                            birthday: false,
                            image: null,
                            answerYes: "Subscribe",
                            answerNo: "No, thanks",
                        },
                        showMenu: [],
                    },
                    sequence: ["newsLetterSignUp", "showMenu"],
                    customUrl: "",
                    version: 1,
                }

                const qrFlow = await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(venueId)
                    .collection("qrFlows")
                    .add({
                        ...newFlow,
                    })

                // Save id
                idFlow = qrFlow.id

                // Create customs URL
                const customUrl = await CreateCustomUrl({
                    userId: uid,
                    venueId: venueId,
                    qrFlowId: idFlow,
                })

                // Update in DataBase
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(venueId)
                    .collection("qrFlows")
                    .doc(idFlow)
                    .update({
                        customUrl: customUrl,
                    })

                // new qr flows
                qrFlowsId.push(idFlow)

                // Update data user
                await updateQrFlows({
                    data: {
                        ...userData,
                    },
                    newQrFlows: qrFlowsId,
                })(dispatch)

                // Create QR codes
                createQRs({
                    uid: uid,
                    venueId: venueId,
                    qrFlowId: idFlow,
                    pricesType: prices,
                })(dispatch)

                const flow = [
                    ...flows,
                    {
                        ...newFlow,
                    },
                ]

                // Reload data token
                reloadDataToken()(dispatch)

                dispatch({
                    type: CREATE_QR_FLOW_EXIT,
                    payload: { flow: flow, index: index },
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...flow],
                    })
                )
            } catch (error) {
                console.log(error)
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L1328 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                // Delete structure
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(venueId)
                    .collection("qrFlows")
                    .doc(idFlow)
                    .delete()

                dispatch({
                    type: CREATE_QR_FLOW_ERROR,
                })
            }
        }
    }

export const deleteQrFlow =
    ({ data = {} }) =>
    async (dispatch, getState) => {
        // Init loading
        dispatch({
            type: LOADING_QR_FLOW,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            let qrFlowsId = JSON.parse(localStorage.getItem("user")).qrFlowsId
            const { flows } = getState().qrFlow

            // Data from user
            const userData = getState().user.data

            try {
                // Delete from data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .delete()

                // Delete last custom url from in DataBase
                await db.collection("customUrls").doc(data.customUrl).delete()

                // Delete from storage
                const dataApi = {
                    userId: uid,
                    venueId: data.venueId,
                    qrFlowId: data.qrFlowId,
                }

                const deleteFolderStorage = functions.httpsCallable(
                    "deleteFolderStorage"
                )
                deleteFolderStorage(dataApi)

                // new qr flows
                qrFlowsId = qrFlowsId.filter(
                    (item, index) => item !== data.qrFlowId
                )

                // Update data user
                await updateQrFlows({
                    data: {
                        ...userData,
                    },
                    newQrFlows: qrFlowsId,
                })(dispatch)

                // Delete from redux
                const newFlow = flows.filter(
                    (item, index) => item.qrFlowId !== data.qrFlowId
                )

                // Reload data token
                reloadDataToken()(dispatch)

                dispatch({
                    type: DELETE_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )

                // Show toast
                openToast({
                    content: i18n.t("toastSuccessMenuDeleted"),
                    type: SUCCESS,
                })
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L1412 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: DELETE_QR_FLOW_ERROR,
                })
            }
        }
    }

export const updateCustomUrl =
    ({ data = {}, customUrl = "" }) =>
    async (dispatch, getState) => {
        // Init loading
        dispatch({
            type: LOADING_QR_FLOW,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            const { flows } = getState().qrFlow

            try {
                // Create customs URL
                const customUrlRes = await CreateCustomUrl({
                    userId: uid,
                    venueId: data.venueId,
                    qrFlowId: data.qrFlowId,
                    customUrl: customUrl,
                })

                if (customUrlRes === "Exit") {
                    // Update in DataBase
                    await db
                        .collection("users")
                        .doc(uid)
                        .collection("venues")
                        .doc(data.venueId)
                        .collection("qrFlows")
                        .doc(data.qrFlowId)
                        .update({
                            customUrl: customUrl,
                        })

                    // Reload data token
                    reloadDataToken()(dispatch)

                    // Delete last custom url from in DataBase
                    await db
                        .collection("customUrls")
                        .doc(data.customUrl)
                        .delete()

                    const flow = { ...data, customUrl: customUrl }

                    // Update the flow with the current flow
                    const newFlow = flows.map((item) =>
                        item.qrFlowId === data.qrFlowId
                            ? { ...flow }
                            : { ...item }
                    )

                    LogEvent("create_custom_url", {
                        new_user_id: uid,
                        venue_id: data.venueId,
                        qr_flow_id: data.qrFlowId,
                        custom_url: customUrl,
                        description: `${customUrl} - ${uid} - ${data.venueId} - ${data.qrFlowId}`,
                    })

                    dispatch({
                        type: UPDATE_CUSTOM_URL_EXIT,
                        payload: newFlow,
                    })

                    // Save in the browser
                    localStorage.setItem(
                        "qrFlow",
                        JSON.stringify({
                            data: [...newFlow],
                        })
                    )
                } else {
                    openToast({
                        content: i18n.t("toastErrorCustomUrlNotAvailable"),
                        type: ERROR,
                    })

                    dispatch({
                        type: UPDATE_CUSTOM_URL_ERROR,
                    })
                }
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L1508 @ qrFlowDucks.js | ${error.code} - ${error.message} - uid:${uid}`,
                })

                dispatch({
                    type: UPDATE_CUSTOM_URL_ERROR,
                })
            }
        }
    }

export const updateQrFlowName =
    ({ data = {}, name = "New Menu", index = -1 }) =>
    async (dispatch, getState) => {
        // Init loading
        dispatch({
            type: LOADING_QR_FLOW,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            const { flows } = getState().qrFlow

            try {
                // Combine the name with the number of menu
                let newMenuName = `Menu ${index + 1} - ${name}`

                // Delete from data base
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .update({
                        name: newMenuName,
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                // Delete from redux
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId
                        ? { ...data, name: newMenuName }
                        : item
                )

                dispatch({
                    type: UPDATE_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L2525 @ qrFlowDucks.js | ${error.code} - ${error.message} - uid:${uid}`,
                })

                dispatch({
                    type: UPDATE_QR_FLOW_ERROR,
                })
            }
        }
    }

export const updateShowAds =
    ({ data = {}, months = 1 }) =>
    async (dispatch, getState) => {
        // Init loading
        dispatch({
            type: LOADING_QR_FLOW,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            const { flows } = getState().qrFlow

            try {
                // Date until ads will be off
                const adsOffDate = new Date()
                adsOffDate.setMonth(adsOffDate.getMonth() + months)

                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(data.venueId)
                    .collection("qrFlows")
                    .doc(data.qrFlowId)
                    .set(
                        {
                            showAds: {
                                offUntil: adsOffDate,
                            },
                        },
                        { merge: true }
                    )

                // Reload data token
                reloadDataToken()(dispatch)

                // Delete from redux
                const newFlow = flows.map((item) =>
                    item.qrFlowId === data.qrFlowId
                        ? {
                              ...data,
                              showAds: {
                                  offUntil: adsOffDate,
                              },
                          }
                        : item
                )

                dispatch({
                    type: UPDATE_QR_FLOW_EXIT,
                    payload: newFlow,
                })

                // Save in the browser
                localStorage.setItem(
                    "qrFlow",
                    JSON.stringify({
                        data: [...newFlow],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                // Save analytics
                LogEvent("new_error", {
                    description: `L2603 @ qrFlowDucks.js | ${error.code} - ${error.message} - uid:${uid}`,
                })

                dispatch({
                    type: UPDATE_QR_FLOW_ERROR,
                })
            }
        }
    }

export const createQRs =
    ({ uid = "", venueId = "", qrFlowId = "", pricesType = 6 }) =>
    async (dispatch, getState) => {
        dispatch({
            type: START_LOADING_QR_FLOW,
        })

        try {
            // Create QR codes
            const qrNoLogo = await CreateQRCodeFast({
                url: `${window.origin}/scan/${uid}/${venueId}/${qrFlowId}`,
                pricesType: pricesType,
                userId: uid,
                venueId: venueId,
                qrFlowId: qrFlowId,
            })
            // Save image in the storage
            const metaDataQrNoLogo = { contentType: qrNoLogo.file.type }
            const resQrNoLogo = await storage
                .ref()
                .child(uid + "/" + venueId + "/" + qrFlowId + "/QR/")
                .child("noLogo.png")
                .put(qrNoLogo.file, metaDataQrNoLogo)
            const qrNoLogoURL = await resQrNoLogo.ref.getDownloadURL()

            const qrDefaultLogo = await CreateQrCodeWithLogoFast({
                url: `${window.origin}/scan/${uid}/${venueId}/${qrFlowId}`,
                pricesType: pricesType,
                userId: uid,
                venueId: venueId,
                qrFlowId: qrFlowId,
            })
            // Save image in the storage
            const metaDataQrDefaultLogo = {
                contentType: qrDefaultLogo.file.type,
            }
            const resQrDefaultLogoURL = await storage
                .ref()
                .child(uid + "/" + venueId + "/" + qrFlowId + "/QR/")
                .child("defaultLogo.png")
                .put(qrDefaultLogo.file, metaDataQrDefaultLogo)
            const qrDefaultLogoURL =
                await resQrDefaultLogoURL.ref.getDownloadURL()

            // Update data base
            await db
                .collection("users")
                .doc(uid)
                .collection("venues")
                .doc(venueId)
                .collection("qrFlows")
                .doc(qrFlowId)
                .update({
                    "qrCode.options.noLogo": qrNoLogoURL,
                    "qrCode.options.defaultLogo": qrDefaultLogoURL,
                })

            // Reload data token
            reloadDataToken()(dispatch)

            // Update redux state
            getQrFlowsData({ readDB: true })(dispatch)

            dispatch({
                type: END_LOADING_QR_FLOW,
            })
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")
            // Save analytics
            LogEvent("new_error", {
                description: `L1648 @ qrFlowDucks.js | ${error.code} - ${error.message}`,
            })

            // Delete structure
            /*  await db
                .collection("users")
                .doc(uid)
                .collection("venues")
                .doc(venueId)
                .collection("qrFlows")
                .doc(qrFlowId)
                .delete() */

            dispatch({
                type: END_LOADING_QR_FLOW,
            })
        }
    }

/* Reset qr flow */

export const restartDataQrFlow = () => async (dispatch) => {
    dispatch({
        type: RESTART_QR_FLOW,
    })
}
