import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import "firebase/compat/auth"
import "firebase/compat/storage"
import "firebase/compat/functions"
import "firebase/compat/analytics"
import "firebase/compat/database"

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONF)

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
//firebase.analytics();

// Active emulator to functions only
//firebase.firestore().useEmulator("localhost", 8080)
//firebase.functions().useEmulator("localhost", 5001)
//firebase.auth().useEmulator("http://localhost:4000/auth ", 9099)
//firebase.storage().useEmulator("http://localhost:4000/storage", 9199)
//firebase.database().useEmulator("localhost", 9000)

const db = firebase.firestore()
const functions = firebase.functions()
const auth = firebase.auth()
const storage = firebase.storage()
const analytics = firebase.analytics()
const database = firebase.database()

export { db, auth, functions, firebase, storage, analytics, database }
