import React from "react"
import { Router, Switch, Route, Redirect } from "react-router-dom"

/* History */
import history from "helpers/ListenHistory"

/* Firebase */
import { auth } from "controller/firebase"

/* Redux */
import { useDispatch } from "react-redux"
import { logout } from "redux/authDucks"

/* Device */
import { isBrowser } from "react-device-detect"

/* Pages Desktop */
import Scan from "pages/desktop/scan/Scan"
import CustomScan from "pages/desktop/customScan/CustomScan"

/* Components */
import RedirectUrl from "components/redirectUrl/RedirectUrl"
import Blog_1 from "pages/blogs/the-future-is-here-using-qr-codes-for-your-restaurant-menu"
import Blogs from "pages/blogs/Blogs"
import Blog_2 from "pages/blogs/enhancing-customer-experience-with-qr-code-menus"
import Blog_3 from "pages/blogs/effortlessly-convert-your-restaurant-menu-to-qr-code"
import Blog_4 from "pages/blogs/attract-more-diners-with-a-free-menu-qr-code"
import Blog_5 from "pages/blogs/diy-guide-creating-your-own-qr-code-menu-for-free"
import Blog_6 from "pages/blogs/revolutionizing-dining-the-rise-of-qr-menus-in-restaurants"
import Blog_7 from "pages/blogs/boost-your-restaurant-appeal-with-a-free-qr-code-menu-service"
import Blog_8 from "pages/blogs/how-to-offer-your-guests-a-qr-code-menu-at-no-cost"
import Blog_9 from "pages/blogs/discover-how-to-implement-a-qr-menu-at-no-cost-for-your-restaurant"
import Blog_10 from "pages/blogs/5-steps-to-set-up-a-qr-code-menu-for-your-restaurant-for-free"
import Blog_11 from "pages/blogs/streamline-your-service-with-a-qr-code-for-your-restaurant-menu"
import Blog_12 from "pages/blogs/creating-a-cost-free-menu-qr-code-for-you-eatery"
import Blog_13 from "pages/blogs/introducing-the-qr-code-menu-the-future-of-ordering"
import Blog_14 from "pages/blogs/elevate-your-dining-business-with-a-free-qr-menu-solution"
import Blog_15 from "pages/blogs/tech-savvy-dining-harnessing-qr-codes-for-efficient-restaurant-service"
import Blog_16 from "pages/blogs/access-your-foodie-favorites-with-a-free-qr-code-for-menus"
import Blog_17 from "pages/blogs/the-ultimate-guide-on-how-to-create-a-complimentary-qr-code-for-your-restaurant-menu"
import Blog_18 from "pages/blogs/enhancing-dining-experience-how-qr-codes-revolutionize-restaurant-menus"
import Blog_19 from "pages/blogs/optimize-your-table-service-with-qr-codes-for-restaurant-menus"
import Blog_20 from "pages/blogs/cut-costs-and-go-digital-get-your-free-qr-code-for-menus-today"
import Blog_21 from "pages/blogs/step-by-step-guide-how-to-create-a-free-qr-code-menu-for-your-eatery"
import Blog_22 from "pages/blogs/enhancing-customer-service-why-qr-codes-for-restaurant-menus-are-the-future"
import Blog_23 from "pages/blogs/unleash-your-Restaurant-potential-with-qr-code-menus"
import Blog_24 from "pages/blogs/revolutionize-your-dining-experience-with-a-free-qr-code-menu"

/* Pages */
const PageNotFound = React.lazy(() => import("pages/404/PageNotFound"))
const MenuNotFound = React.lazy(() => import("pages/menuNotFound/MenuNotFound"))
const Logout = React.lazy(() => import("pages/logout/Logout"))

/* Pages Desktop */
const Home_3 = React.lazy(() => import("pages/desktop/home/Home_3"))
const Home_2 = React.lazy(() => import("pages/desktop/home/Home_2"))
const Home_1 = React.lazy(() => import("pages/desktop/home/Home_1"))
const Dashboard = React.lazy(() => import("pages/desktop/dashboard/Dashboard"))
const DashboardSignUp = React.lazy(() =>
  import("pages/desktop/dashboard/DashboardSignUp")
)
const Auth = React.lazy(() => import("pages/desktop/auth/Auth"))
const Terms = React.lazy(() => import("pages/desktop/terms/Terms"))
const DirectSignUp = React.lazy(() => import("pages/directSignUp/DirectSignUp"))
const Profile = React.lazy(() => import("pages/desktop/profile/Profile"))

/* Pages Mobile */
const DashboardMobile = React.lazy(() =>
  import("pages/mobile/dashboard/Dashboard")
)
const AccountMobile = React.lazy(() => import("pages/mobile/account/Account"))
const InvoicesMobile = React.lazy(() =>
  import("pages/mobile/invoices/Invoices")
)
const ProductsMobile = React.lazy(() =>
  import("pages/mobile/products/Products")
)
const HomeMobile = React.lazy(() => import("pages/mobile/home/Home"))
const AuthMobile = React.lazy(() => import("pages/mobile/auth/Auth"))

const Routes = () => {
  // Const to call action to redux
  const dispatch = useDispatch()

  // Component states
  const [firebaseUser, setFirebaseUser] = React.useState(false)

  React.useEffect(() => {
    const fetchUser = () => {
      auth.onAuthStateChanged((user) => {
        if (user && user !== undefined) {
          setFirebaseUser(user)
        } else {
          setFirebaseUser(null)
        }
      })
    }

    fetchUser()
  }, [])

  /* Make dashboard a private route */
  const PrivateRoute = ({ component, path, ...rest }) => {
    // Validate if the user exist in the browser to enable the path
    if (localStorage.getItem("auth") && firebaseUser) {
      // If the user exist in the browser and is equals to the user got in firebase show the component
      const userBrowser = JSON.parse(localStorage.getItem("auth"))
      if (userBrowser.uid === firebaseUser.uid) {
        return <Route component={component} path={path} {...rest} />
      } else {
        dispatch(logout())
        const version = rest.computedMatch.params.version
        return <Redirect to={`/home-${version}`} {...rest} />
      }
    } else {
      dispatch(logout())
      const version = rest.computedMatch.params.version
      return <Redirect to={`/home-${version}`} {...rest} />
    }
  }

  return firebaseUser !== false ? (
    <Router history={history}>
      <Switch>
        {/* Blogs */}
        <Route
          path="/blog/revolutionize-your-dining-experience-with-a-free-qr-code-menu"
          component={Blog_24}
        />
        <Route
          path="/blog/unleash-your-Restaurant-potential-with-qr-code-menus"
          component={Blog_23}
        />
        <Route
          path="/blog/enhancing-customer-service-why-qr-codes-for-restaurant-menus-are-the-future"
          component={Blog_22}
        />
        <Route
          path="/blog/step-by-step-guide-how-to-create-a-free-qr-code-menu-for-your-eatery"
          component={Blog_21}
        />
        <Route
          path="/blog/cut-costs-and-go-digital-get-your-free-qr-code-for-menus-today"
          component={Blog_20}
        />
        <Route
          path="/blog/optimize-your-table-service-with-qr-codes-for-restaurant-menus"
          component={Blog_19}
        />
        <Route
          path="/blog/enhancing-dining-experience-how-qr-codes-revolutionize-restaurant-menus"
          component={Blog_18}
        />
        <Route
          path="/blog/the-ultimate-guide-on-how-to-create-a-complimentary-qr-code-for-your-restaurant-menu"
          component={Blog_17}
        />
        <Route
          path="/blog/access-your-foodie-favorites-with-a-free-qr-code-for-menus"
          component={Blog_16}
        />
        <Route
          path="/blog/tech-savvy-dining-harnessing-qr-codes-for-efficient-restaurant-service"
          component={Blog_15}
        />
        <Route
          path="/blog/elevate-your-dining-business-with-a-free-qr-menu-solution"
          component={Blog_14}
        />
        <Route
          path="/blog/introducing-the-qr-code-menu-the-future-of-ordering"
          component={Blog_13}
        />
        <Route
          path="/blog/creating-a-cost-free-menu-qr-code-for-you-eatery"
          component={Blog_12}
        />
        <Route
          path="/blog/streamline-your-service-with-a-qr-code-for-your-restaurant-menu"
          component={Blog_11}
        />
        <Route
          path="/blog/5-steps-to-set-up-a-qr-code-menu-for-your-restaurant-for-free"
          component={Blog_10}
        />
        <Route
          path="/blog/discover-how-to-implement-a-qr-menu-at-no-cost-for-your-restaurant"
          component={Blog_9}
        />
        <Route
          path="/blog/how-to-offer-your-guests-a-qr-code-menu-at-no-cost"
          component={Blog_8}
        />
        <Route
          path="/blog/boost-your-restaurant-appeal-with-a-free-qr-code-menu-service"
          component={Blog_7}
        />
        <Route
          path="/blog/revolutionizing-dining-the-rise-of-qr-menus-in-restaurants"
          component={Blog_6}
        />
        <Route
          path="/blog/diy-guide-creating-your-own-qr-code-menu-for-free"
          component={Blog_5}
        />
        <Route
          path="/blog/attract-more-diners-with-a-free-menu-qr-code"
          component={Blog_4}
        />
        <Route
          path="/blog/effortlessly-convert-your-restaurant-menu-to-qr-code"
          component={Blog_3}
        />
        <Route
          path="/blog/enhancing-customer-experience-with-qr-code-menus"
          component={Blog_2}
        />
        <Route
          path="/blog/the-future-is-here-using-qr-codes-for-your-restaurant-menu"
          component={Blog_1}
        />
        <Route path="/blog" component={Blogs} />

        {/* Scan */}
        <Route path="/scan/:user/:venue/:qrFlow" component={Scan} />
        {/* Sing in - Sing up with country*/}
        <Route
          path="/auth/:type/:from/:country"
          component={isBrowser ? Auth : AuthMobile}
        />
        <Route
          path="/auth/:type/:from"
          component={isBrowser ? Auth : AuthMobile}
        />
        {/* Custom scan */}
        <Route path="/qr/:url" component={CustomScan} />
        {/* Direct sign up with country*/}
        <Route path="/direct-signup/:country" component={DirectSignUp} exact />
        {/* Home with country*/}
        <Route
          path="/home-3/:country"
          component={isBrowser ? Home_3 : HomeMobile}
          exact
        />
        <Route
          path="/home-2/:country"
          component={isBrowser ? Home_2 : HomeMobile}
          exact
        />
        <Route
          path="/home-1/:country"
          component={isBrowser ? Home_1 : HomeMobile}
          exact
        />
        <Route
          path="/home/:country"
          component={isBrowser ? Home_1 : HomeMobile}
          exact
        />

        {/* Profile */}
        <PrivateRoute path="/dashboard/:version/profile/:tab" exact>
          {isBrowser ? (
            <Profile />
          ) : (
            <Redirect to={"/dashboard/account"}></Redirect>
          )}
        </PrivateRoute>

        <PrivateRoute path="/dashboard/:version/profile" exact>
          {isBrowser ? (
            <Redirect to={"/dashboard/:version/profile/account"}></Redirect>
          ) : (
            <Redirect to={"/dashboard/account"}></Redirect>
          )}
        </PrivateRoute>

        <PrivateRoute path="/dashboard/profile" exact>
          <Redirect to={"/dashboard/1/profile/account"}></Redirect>
        </PrivateRoute>

        {/* Account */}
        <PrivateRoute path="/dashboard/:version/account" exact>
          {isBrowser ? (
            <Redirect to={"/dashboard/profile"}></Redirect>
          ) : (
            <AccountMobile />
          )}
        </PrivateRoute>
        <PrivateRoute path="/dashboard/account" exact>
          <Redirect to={"/dashboard/1/account"}></Redirect>
        </PrivateRoute>

        {/* Invoices */}
        <PrivateRoute path="/dashboard/:version/invoices" exact>
          {isBrowser ? (
            <Redirect to={"/dashboard/profile"}></Redirect>
          ) : (
            <InvoicesMobile />
          )}
        </PrivateRoute>
        <PrivateRoute path="/dashboard/invoices" exact>
          <Redirect to={"/dashboard/1/invoices"}></Redirect>
        </PrivateRoute>

        {/* Products */}
        <PrivateRoute path="/dashboard/:version/products" exact>
          {isBrowser ? (
            <Redirect to={"/dashboard/profile"}></Redirect>
          ) : (
            <ProductsMobile />
          )}
        </PrivateRoute>
        <PrivateRoute path="/dashboard/products" exact>
          <Redirect to={"/dashboard/1/products"}></Redirect>
        </PrivateRoute>

        {/* Dashboard Sign up */}
        <Route path="/new-dashboard/:country" component={DashboardSignUp} />
        <Route path="/new-dashboard" component={DashboardSignUp} />

        {/* Dashboard */}
        <PrivateRoute
          path="/dashboard/:version"
          component={isBrowser ? Dashboard : DashboardMobile}
          exact
        />
        <PrivateRoute
          path="/dashboard"
          component={isBrowser ? Dashboard : DashboardMobile}
          exact
        >
          <Redirect to={"/dashboard/1"}></Redirect>
        </PrivateRoute>

        {/* Menu not found */}
        <Route path="/menu-not-found" component={MenuNotFound} />
        {/* Redirect to scan */}
        <Route path="/check-in" component={RedirectUrl} />
        {/* Logout */}
        <Route path="/logout" component={Logout} />
        {/* Terms */}
        <Route path="/terms" component={Terms} />
        {/* Direct sign up*/}
        <Route path="/direct-signup" component={DirectSignUp} exact />
        {/* Sing in - Sing up */}
        <Route path="/auth" component={isBrowser ? Auth : AuthMobile} exact />
        {/* Home */}
        <Route
          path="/home-3"
          component={isBrowser ? Home_3 : HomeMobile}
          exact
        />
        <Route
          path="/home-2"
          component={isBrowser ? Home_2 : HomeMobile}
          exact
        />
        <Route
          path="/home-1"
          component={isBrowser ? Home_1 : HomeMobile}
          exact
        />
        <Route path="/home" component={isBrowser ? Home_1 : HomeMobile} exact />
        <Route
          path="/:country"
          component={isBrowser ? Home_1 : HomeMobile}
          exact
        />
        <Route path="/" component={isBrowser ? Home_1 : HomeMobile} exact />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </Router>
  ) : null
}

export default Routes
