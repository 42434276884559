import React from "react"

/* Router */
import { withRouter, useParams, Link } from "react-router-dom"

/* Styles */
import "./style.scss"

/* Components */
import { Button } from "react-materialize"

/* Helpers */
import { LogEvent } from "helpers/LogEvents"

/* Images */
import logo from "assets/img/png/large-logo.png"

/* i18n */
import { useTranslation } from "react-i18next"

const Nav = ({
  history,
  from = "home",
  showAuth = () => {},
  createAccountAction = () => {},
  authPage = false,
}) => {
  // Const to translation
  const { t } = useTranslation()

  // Params get from the url
  const { country } = useParams()

  const handlePushAuth = () => {
    if (country) {
      LogEvent("sign_up_clicked", { from: from, country: country })
      //history.push(`/auth/signUp/${from}/${country}`)
    } else {
      LogEvent("sign_up_clicked", { from: from, country: "NaN" })
      //history.push(`/auth/signUp/${from}/`)
    }

    showAuth(true)
    createAccountAction()
  }

  const handlePushHome = () => {
    if (from === "home-2") {
      from = "home"
    } else {
      from = ""
    }

    if (country && from === "home") {
      if (authPage) {
        history.push(`/${from}/${country}`)
      }
      showAuth(false)
    } else if (from === "home") {
      if (authPage) {
        history.push(`/${from}`)
      }
      showAuth(false)
    } else if (country) {
      if (authPage) {
        history.push(`/${country}`)
      }
      showAuth(false)
    } else {
      if (authPage) {
        history.push(`/`)
      }
      showAuth(false)
    }
  }

  return (
    <div className="o-nav-container">
      <nav>
        <div className="nav-wrapper">
          <div className="brand-logo">
            <img
              className="responsive-img"
              width="120px"
              src={logo}
              alt="Logo MyFreeQr"
              onClick={() => handlePushHome()}
            ></img>
          </div>
          <ul id="nav-mobile" className="right hide-on-med-and-down">
            <li>
              {authPage ? (
                <Link
                  to={country ? `/${from}/${country}` : `/${from}/`}
                  className="o-login-btn"
                >
                  {t("signUpWord")}
                </Link>
              ) : (
                <Link
                  to={
                    country
                      ? `/auth/signIn/${from}/${country}`
                      : `/auth/signIn/${from}/`
                  }
                  className="o-login-btn"
                >
                  {t("loginWord")}
                </Link>
              )}
            </li>
            <li className="right hide-on-med-and-down">
              <Button
                className="btn o-btn"
                onClick={() => {
                  handlePushAuth()
                }}
              >
                {t("createQRMenu")}
              </Button>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  )
}

export default withRouter(Nav)
