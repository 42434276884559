/* Component */
import { openToast, ERROR, SUCCESS } from "components/toast/Toast"

/* Helpers */
import { LogEvent } from "./LogEvents"
import { LoadingTimer } from "./LoadingTimer"

/* Firebase */
import { functions } from "controller/firebase"

/* I18n */
import i18n from "i18next"

export function CreateCustomerId({ email, userId, venueId, qrFlowId }) {
    return new Promise(async(resolve, reject) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 90,
            eventName: "create stripe customer id",
            userId: userId,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        try {
            const createStripeCustomer = functions.httpsCallable(
                "createStripeCustomer"
            )
            const customer = await createStripeCustomer({
                email,
            })

            if (customer.data.status === 400) {
                const error = customer.data.error
                    // Save error
                openToast({
                    content: i18n.t("toastErrorStripeCustomerNoCreated"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L31 @ stripe.js | ${error.code} - ${error.message}`,
                })
                stopTimer(interval)
                reject()
            } else {
                stopTimer(interval)
                resolve({
                    customer: customer.data.customer,
                    payment: customer.data.payment,
                })
            }
        } catch (error) {
            // Save error
            openToast({
                content: i18n.t("toastErrorStripeCustomerNoCreated"),
                type: ERROR,
            })
            LogEvent("new_error", {
                description: `L26 @ stripe.js | ${error.code} - ${error.message}`,
            })
            stopTimer(interval)
            reject(error)
        }
    })
}

export function CreatePaymentIntentNoMethod({
    amount,
    customerId,
    userId,
    venueId,
    qrFlowId,
}) {
    return new Promise(async(resolve, reject) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 90,
            eventName: "create stripe payment intent no method",
            userId: userId,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        try {
            const createPaymentIntentNoMethod = functions.httpsCallable(
                "createPaymentIntentNoMethod"
            )
            const paymentIntent = await createPaymentIntentNoMethod({
                amount,
                customer: customerId,
                userId,
            })

            if (paymentIntent.data.status === 400) {
                const error = paymentIntent.data.error
                    // Save error
                openToast({
                    content: i18n.t("toastErrorStripePaymentNoCreated"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L74 @ stripe.js | ${error.code} - ${error.message}`,
                })
                stopTimer(interval)
                reject()
            } else {
                stopTimer(interval)
                resolve(paymentIntent.data.payment)
            }
        } catch (error) {
            // Save error
            openToast({
                content: i18n.t("toastErrorStripePaymentNoCreated"),
                type: ERROR,
            })
            LogEvent("new_error", {
                description: `L61 @ stripe.js | ${error.code} - ${error.message}`,
            })
            stopTimer(interval)
            reject(error)
        }
    })
}

export function CreatePaymentIntentWithPaymentMethod({
    amount,
    customerId,
    paymentMethodId,
    userId,
    venueId,
    qrFlowId,
}) {
    return new Promise(async(resolve, reject) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 90,
            eventName: "create stripe payment intent with method",
            userId: userId,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        try {
            const createPaymentIntentWithMethod = functions.httpsCallable(
                "createPaymentIntentWithMethod"
            )
            const paymentIntent = await createPaymentIntentWithMethod({
                amount,
                customer: customerId,
                userId,
                paymentMethodId,
            })

            if (paymentIntent.data.status === 400) {
                const error = paymentIntent.data.error
                    // Save error
                openToast({
                    content: i18n.t("toastErrorStripePaymentNoCreated"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L120 @ stripe.js | ${error.code} - ${error.message}`,
                })
                stopTimer(interval)
                reject()
            } else {
                stopTimer(interval)
                resolve(paymentIntent.data.payment)
            }
        } catch (error) {
            // Save error
            openToast({
                content: i18n.t("toastErrorStripePaymentNoCreated"),
                type: ERROR,
            })
            LogEvent("new_error", {
                description: `L94 @ stripe.js | ${error.code} - ${error.message}`,
            })
            stopTimer(interval)
            reject(error)
        }
    })
}

export function CancelPaymentIntent({ invoiceId, userId, venueId, qrFlowId }) {
    return new Promise(async(resolve, reject) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 90,
            eventName: "cancel stripe payment intent",
            userId: userId,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        try {
            const cancelPaymentIntent = functions.httpsCallable(
                "cancelPaymentIntent"
            )
            const paymentIntent = await cancelPaymentIntent({
                invoiceId,
            })

            if (paymentIntent.data.status === 400) {
                const error = paymentIntent.data.error
                    // Save error
                LogEvent("new_error", {
                    description: `L157 @ stripe.js | ${error.code} - ${error.message}`,
                })
                stopTimer(interval)
                reject()
            } else {
                stopTimer(interval)
                resolve(paymentIntent.data.payment)
            }
        } catch (error) {
            // Save error
            LogEvent("new_error", {
                description: `L70 @ stripe.js | ${error.code} - ${error.message}`,
            })
            stopTimer(interval)
            reject(error)
        }
    })
}

export function SendInvoice({ invoiceId, email, language }) {
    return new Promise(async(resolve, reject) => {
        try {
            const getInvoice = functions.httpsCallable("getInvoice")
            const invoice = await getInvoice({
                invoiceId,
                email,
                language,
            })

            if (invoice.data.status === 400) {
                const error = invoice.data.error
                    // Save error
                openToast({
                    content: i18n.t("toastErrorStripeInvoiceNoSent"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L157 @ stripe.js | ${error.code} - ${error.message}`,
                })
                reject()
            } else {
                openToast({
                    content: i18n.t("toastSuccessInvoiceSent"),
                    type: SUCCESS,
                })

                resolve(invoice.data.invoice)
            }
        } catch (error) {
            // Save error
            openToast({
                content: i18n.t("toastErrorStripeInvoiceNoSent"),
                type: ERROR,
            })
            LogEvent("new_error", {
                description: `L118 @ stripe.js | ${error.code} - ${error.message}`,
            })
            reject(error)
        }
    })
}

export function ConfirmPaymentWithPaymentMethod({
    paymentId,
    userId,
    venueId,
    qrFlowId,
}) {
    return new Promise(async(resolve, reject) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 90,
            eventName: "confirm stripe payment intent with method",
            userId: userId,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        try {
            const confirmPayment = functions.httpsCallable("confirmPayment")
            const paymentIntent = await confirmPayment({
                paymentId,
            })

            if (paymentIntent.data.status === 400) {
                const error = paymentIntent.data.error
                    // Save error
                openToast({
                    content: i18n.t("toastErrorStripeNoConfirmPayment"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L188 @ stripe.js | ${error.code} - ${error.message}`,
                })
                stopTimer(interval)
                reject(paymentIntent.data)
            } else {
                stopTimer(interval)
                resolve(paymentIntent.data.payment)
            }
        } catch (error) {
            // Save error
            openToast({
                content: i18n.t("toastErrorStripeNoConfirmPayment"),
                type: ERROR,
            })
            LogEvent("new_error", {
                description: `L141 @ stripe.js | ${error.code} - ${error.message}`,
            })
            stopTimer(interval)
            reject(error)
        }
    })
}

export function GetPayment({ paymentId, userId, venueId, qrFlowId }) {
    return new Promise(async(resolve, reject) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 90,
            eventName: "get stripe payment",
            userId: userId,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        try {
            const retrievePayment = functions.httpsCallable("retrievePayment")
            const paymentIntent = await retrievePayment({
                paymentId,
            })

            const payment = {...paymentIntent.data.payment }

            if (paymentIntent.data.status === 400) {
                const error = paymentIntent.data.error
                    // Save error
                openToast({
                    content: i18n.t("toastErrorStripeNoGetPayment"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L226 @ stripe.js | ${error.code} - ${error.message}`,
                })
                stopTimer(interval)
                reject()
            } else {
                stopTimer(interval)
                resolve(payment)
            }
        } catch (error) {
            // Save error
            openToast({
                content: i18n.t("toastErrorStripeNoGetPayment"),
                type: ERROR,
            })
            LogEvent("new_error", {
                description: `L166 @ stripe.js | ${error.code} - ${error.message}`,
            })
            stopTimer(interval)
            reject()
        }
    })
}

export function DeletePaymentMethod({
    paymentMethodId,
    userId,
    venueId,
    qrFlowId,
}) {
    return new Promise(async(resolve, reject) => {
        // Start timer with a max limit
        const { interval, stopTimer } = LoadingTimer({
            maxTime: 90,
            eventName: "delete stripe payment method",
            userId: userId,
            venueId: venueId,
            qrFlowId: qrFlowId,
        })

        try {
            const detachPaymentMethod = functions.httpsCallable(
                "detachPaymentMethod"
            )
            const paymentMethod = await detachPaymentMethod({
                paymentMethodId,
            })

            if (paymentMethod.data.status === 400) {
                const error = paymentMethod.data.error
                    // Save error
                openToast({
                    content: i18n.t("toastErrorStripePaymentMethodNoDeleted"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L264 @ stripe.js | ${error.code} - ${error.message}`,
                })
                stopTimer(interval)
                reject()
            } else {
                stopTimer(interval)
                resolve(paymentMethod)
            }
        } catch (error) {
            // Save error
            openToast({
                content: i18n.t("toastErrorStripePaymentMethodNoDeleted"),
                type: ERROR,
            })
            LogEvent("new_error", {
                description: `L91 @ stripe.js | ${error.code} - ${error.message}`,
            })
            stopTimer(interval)
            reject(error)
        }
    })
}