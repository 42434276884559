import React from "react"

/* Styles */
import "./style.scss"

/* Components */
import { Preloader } from "react-materialize"

export const MainLoading = () => {
  return (
    <div className="o-main-loading-container" id="main-loading">
      <Preloader active color="blue" flashing={false} size="big" />
    </div>
  )
}
