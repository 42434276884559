import { LogEvent } from "./LogEvents"

export function ConvertBase64ToFile({ dataUrl = "", fileName = "" }) {
    try {
        var arr = dataUrl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }

        return new File([u8arr], fileName, { type: mime })
    } catch (error) {
        // Show toast unexpected error reload
        document
            .getElementById("toast-unexpected-error")
            .classList.remove("hide")
            // Save analytics
        LogEvent("new_error", {
            description: `L23 @ ConvertBase64ToFile.js | ${error.code} - ${error.message} - ${dataUrl}`,
        })

        return
    }
}