export const translationEn = {
    /* --------------- WORDS --------------- */
    acceptWord: "Accept",
    accountWord: "Account",
    addressWord: "Address",
    adWord: "Ad",
    cancelWord: "Cancel",
    changeWord: "Change",
    closeWord: "Close",
    codeWord: "Code",
    companyWord: "Restaurant",
    congratulationsWord: "Congratulations",
    continueWord: "Continue",
    cookiesWord: "Cookies",
    customizeWord: "Customize",
    customWord: "Custom",
    downloadWord: "Download",
    shareWord: "Share",
    emailWord: "Email",
    facebookWord: "Facebook",
    freeWord: "Free",
    helpWord: "Help",
    instagramWord: "Instagram",
    loginWord: "Login",
    logoWord: "Logo",
    menusWord: "Menus",
    menuWord: "Menu",
    monthWord: "month",
    nameWord: "Name",
    orWord: "OR",
    pageWord: "Page",
    passWord: "Password",
    plansWord: "Plans",
    planWord: "Plan",
    previewWord: "See Menu",
    printWord: "Print",
    recoverWord: "Recover",
    removeWord: "Remove",
    reportsWord: "Reports",
    saveWord: "Save",
    sendWord: "send",
    signInWord: "Sign In",
    signUpWord: "Sign Up",
    siteName: "MyFreeQr",
    socialsWord: "Socials",
    tikTokWord: "TikTok",
    timezoneWord: "Timezone",
    todayWord: "Today",
    twitterWord: "Twitter",
    usageWord: "Usage",
    whatsAppWord: "WhatsApp",
    yesterdayWord: "Yesterday",
    typeWord: "Type",
    yesWord: "Yes",
    noWord: "No",
    advertisementWord: "Advertisement",
    createYourMenuWord: "Create Your QR Menu For Free",

    /* --------------- SITE --------------- */

    /* --- Home --- */
    siteTitleHome: "Create Free QR Menu For Restaurants, Bars & Cafés",
    siteDescriptionHome:
        "Create your free QR menu for your restaurant. It is free forever and it never expires. Just upload your menu and download your QR code.",
    siteKeyWordsHome:
        "free qr menu, qr menu, qr code menu, free qr code menu, dynamic free qr menu, restaurants",

    /* --- Scan --- */
    siteTitleScan: "Your Menu",
    siteDescriptionScan: "Take a look at the restaurant menu here.",
    siteKeyWordsScan:
        "free, qr, qr code, free qr code, dynamic menu, restaurants, scan",

    /* --- Terms --- */
    siteTitleTerms: "Terms",
    siteDescriptionTerms: "Terms and privacy of MyFreeQr",
    siteKeyWordsTerms:
        "free, qr, qr code, free qr code, dynamic menu, restaurants, terms, privacy",

    /* --- Profile --- */
    siteTitleProfile: "Profile",
    siteDescriptionProfile: "Change the data of your account",
    siteKeyWordsProfile:
        "free, qr, qr code, free qr code, dynamic menu, restaurants, account, invoices, payment method, products, plans",

    /* --- Account --- */
    siteTitleAccount: "Account",
    siteDescriptionAccount: "Change the data of your account",
    siteKeyWordsAccount:
        "free, qr, qr code, free qr code, dynamic menu, restaurants, account",

    /* --- Invoices --- */
    siteTitleInvoices: "Invoices",
    siteDescriptionInvoices: "Get your invoices",
    siteKeyWordsInvoices:
        "free, qr, qr code, free qr code, dynamic menu, restaurants, invoices, payment method",

    /* --- Products --- */
    siteTitleProducts: "Products",
    siteDescriptionProducts: "Change the data of your account",
    siteKeyWordsProducts:
        "free, qr, qr code, free qr code, dynamic menu, restaurants, products, plans",

    /* --- Auth --- */
    siteTitleAuth: "Auth",
    siteDescriptionAuth: "Create your QR Code for free in 35 seconds",
    siteKeyWordsAuth:
        "free, qr, qr code, free qr code, dynamic menu, restaurants, auth",

    /* --- Dashboard --- */
    siteTitleDashboard: "Dashboard",
    siteDescriptionDashboard: "Customize your QR Code and menu",
    siteKeyWordsDashboard:
        "free, qr, qr code, free qr code, dynamic menu, restaurants, customize, pages, files, images",

    /* --- 404 --- */
    siteTitleNotFound: "404 - Not Found",
    siteDescriptionNotFound: "Create your QR Code for free in 35 seconds",
    siteKeyWordsNotFound:
        "free, qr, qr code, free qr code, dynamic menu, restaurants",

    /* --------------- PAGES --------------- */

    /* --- Home --- */
    createQRMenu: "Create Your Free QR Menu",

    homeTitle: "Create Free QR Menu For Restaurants, Bars & Cafés",
    homeSubTitlePrimary:
        "Create your free QR menu for your restaurant. It is free forever and it never expires. Just upload your menu and download your QR code.",
    homeSubTitle:
        "Upload your menu and download your QR code in <bold>35 seconds</bold>",
    homeContent1:
        " MyFreeQr is and always will be a 100% free QR code menu for restaurants everywhere around the world",

    tag1: "Free Forever",
    tag2: "Never Expires",
    tag3: "Unlimited Menus",
    tag4: "Unlimited Scans",
    tag5: "Free Support",
    tag6: "Scan Reports",
    tag7: "Free",
    tag8: "Unlimited",
    tag9: "Free Forever",

    step1: "Scan Your QR Code",
    step2: "3-Second Ad",
    step3: "See Your Menu",

    section1Title: "Get Your Free QR Menu in 35 Seconds",
    section1SubTitle: "In just 2 simple steps",
    section1Content1: "Upload Your Menu",
    section1Content2: "Print Your QR Menu",

    section2Title: "Unlimited Menus and Scans",
    section2SubTitle: "Keep track of your daily scans",

    section3Title: "Change Your Menu To MyFreeQr",
    section3SubTitle: "Made for restaurants, bars, and cafés everywhere",
    section3Content1: "Join thousands around the world",

    section4Title: "Create Your Free QR Menu Today",
    section4SubTitle: "It's easy and it's free",
    section4Content1: "Human Customer Support",
    section4Content2:
        "Our team is here to help you from Monday to Sunday between 8:00 a.m. and 6:00 p.m. Eastern Time.",

    /* --- Login --- */
    signInTitle: "Sign In",
    signUpTitle: "Sign Up",
    recoverPass: "Recover Password",

    signInSubTitle:
        "Use the <bold>email address you used to sign up</bold> to MyFreeQr",
    signUpSubTitle: "MyFreeQr is <bold>100% completely FREE forever</bold>",

    enterEmail: "Enter your email",

    googleButton: "Continue with google",

    rememberPass: "Remember your password?",
    forgotPass: "Forgot password?",
    recoverNow: "Recover Now",

    createAccount: "Create a new account",
    alreadyAccount: "Already have an account?",
    signInNow: "Sign in now",

    /* --- Dashboard --- */
    QrCode: "See QR Code",
    removeAds: "Do you want to remove Ads from this menu?",
    removeAdsMobile: "Remove Ads from this menu",
    menuFreeAds: "This menu is free of Ads",

    /* --- Add Menu --- */
    addMenuTitle: "Upload Your Menu",

    addMenuToast1Content:
        "You only have to <bold>print your QR code once</bold> but you can change your menu as many times as you want and it will update inmediately.",

    addMenuToast2Title: "Tips for uploading files",
    addMenuToast2Content1:
        "To convert a <bold>any file to a PDF,</bold> <a>click on this link</a>",
    addMenuToast2Content2:
        "To reduce the <bold>size of a PDF,</bold> <a>click on this link</a>",

    addMenuToast3Content:
        "Please select only images or only a PDF. <br>To turn images into a PDF please<bold> <a1>click on this link.</a1></bold> <br>If you want to turn PDF into images please<bold> <a2>click on this link.</a2></bold>",

    addMenuToast4Content:
        "Please select a single PDF file. To merge several PDFs into a single one<bold> <a>click on this link</a></bold>",

    chooseFiles: "Choose files",
    addFiles: "Add files",
    dropFilesHere: "Or drop files here",

    emptySectionTitle: "Your menu goes here",
    emptySectionDescription:
        "Please upload your menu, and it will be displayed here",

    poweredByMyFreeQr: "Create Your Free QR Menu With MyFreeQr",

    // Modal update custom url
    modalPreviewMobile: "Your Menu",

    /* --- Get QR Code --- */
    getQRCodeTitle: "Get Your QR Code",

    getQRCodeToast1Content1:
        "You can change the design of your QR code for your menu and the <bold>old QR codes will continue to work</bold> alongside the new ones without issue.",
    getQRCodeToast2Title: "Tips for uploading files",
    getQRCodeToast2Content1:
        "To convert <bold>any file to a IMAGE,</bold> <a>click on this link</a>",
    getQRCodeToast3Title: "Tips to download your menu",
    getQRCodeToast3Content1: "Tap and hold the QR code to save the image",

    chooseLogoDesign: "Choose logo & design",

    choosePattern: "Pattern",
    chooseColor: "Color",
    chooseCorner: "Corner",
    chooseStyle: "Style",
    chooseImage: "Logo",

    chooseImageButton: "Choose image",
    dropImageHere: "Or drop image here",

    printServices: "Printing Services Near Me",

    // Modal update custom url
    modalUpdateUrlTitle: "Custom URL",
    modalUpdateUrlContent1:
        "<bold>Copy-paste this URL in your social media profiles</bold> like Instagram, Facebook, TikTok or WhatsApp <bold>to send people to your menu.</bold>",
    modalUpdateUrlLabel: "Your Custom URL",
    modalUpdateUrlLabelDescription:
        "(This will be your custom URL <bold>after clicking on save</bold>)",

    // Modal actions
    modalActionsDownloadTitle: "Download Your QR Code",
    modalActionsPrintTitle: "Print Your QR Code",
    modalActionsEmailTitle: "Email Your QR Code",

    // Modal create anonymous account
    modalCreateAnonymousAccountSubtitle:
        "Enter an email and password to save your QR code to your account <br> <br> <bold>You only have to do this once </bold>",

    // Modal update custom qr code
    modalCustomQrCode: "Customization",

    // Modal welcome
    modalWelcomeTitle: "Welcome 🎉",
    modalWelcomeContent1: "<bold>MyFreeQr is 100% FREE forever.</bold>",
    modalWelcomeContent2:
        "Our mission is to power up free QR menus for any restaurant that needs it.",
    modalWelcomeContent3:
        "<bold>We show Ads for a few seconds to cover our costs.</bold>",
    modalWelcomeContent4: "Scan the QR code below to see how it works:",
    modalWelcomeContent5: "<bold>SCAN ME</bold>",

    // Modal sign up
    modalSignUpTitle: "Welcome",
    modalSignUpSubTitle: "MyFreeQr is 100% <bold>free</bold> forever",
    modalSignUpContent1: "Already have an account? <a>Log in</a>",
    modalSignUpContent2: "Join over 5.000 restaurants that use MyFreeQr",

    // Modal create menu
    modalCreateMenuTitle: "New Menu = New QR Code 🎉",
    modalCreateMenuContent1:
        "This <bold>new menu</bold> has a <bold>new QR code.</bold>",
    modalCreateMenuContent2:
        "You only have to <bold>print your QR code once</bold> but you can <bold>change your menu as many times as you want</bold> and it will update immediately.",
    modalCreateMenuContent3:
        "You can change the design of your QR code for your menu and the <bold>old QR codes will continue to work</bold> alongside the new ones without issue.",

    // Modal delete menu
    modalDeleteMenuTitle: "Delete Menu",
    modalDeleteMenuContent:
        "If you delete this menu, all your codes associated with it will stop working. <br><br>Remember that <bold>this action cannot be undone.</bold> <br><br>Are you sure you want to delete <bold>{{menu}}</bold>?",

    // Modal update name menu
    modalUpdateNameMenuTitle: "Menu Name",

    // Modal edit menu
    modalEditMenuTitle: "Edit Menu",
    modalEditMenuContent1: "Edit Name",
    modalEditMenuContent2: "Delete Menu",

    // Modal password
    modalPassTitle: "Enter Your Password",
    placeholderPass: "Your Password",

    // Modal create account
    modalCreateAccountTitle: "Create New Account",
    modalCreateAccountContent:
        "It seems that the email <bold>{{email}}</bold> does not exist. Would you like us to create a new account for you with this email?",

    // Modal sign in
    modalSignInTitle: "Sign In",
    modalSignInContent:
        "It seems that the email <bold>{{email}}</bold> already exist. Would you like try to sign in with this email?",

    // Modal delete account
    modalDeleteAccountTitle: "Delete Account",
    modalDeleteAccountContent:
        "If you delete your account all your QR codes and information will be lost. <br> <br>Remember that <bold>this action can't be undone.</bold> <br> <br>Are you sure you want to delete your account?",

    // Modal reset password
    modalResetPasswordTitle: "Create password",
    modalResetPasswordContent:
        "This action requires to have a password to continue. <br/><br/> Click below to receive an email with the instructions to create a password.",
    modalResetPasswordButton: "Send Email",

    // Modal select pay qr
    modalSelectPayQrTittle1: "Remove Ads",
    modalSelectPayQrTittle2:
        "MyFreeQr is 100% forever free. You can optionally pay to remove the Ads, but it is not mandatory.",
    modalSelectPayQrSubTittle: "{{count}} month",
    modalSelectPayQrSubTittle_plural: "{{count}} months",
    modalSelectPayQrContent1:
        "This plan will remove the Ads from <bold>{{menu}}</bold> for <bold>{{count}} month</bold>, for a total of <bold>{{price}} USD.</bold>",
    modalSelectPayQrContent1_plural:
        "This plan will remove the Ads from <bold>{{menu}}</bold> for <bold>{{count}} months</bold>, for a total of <bold>{{price}} USD.</bold>",
    modalSelectPayQrContent2: "Select the number of months:",
    modalSelectPayQrConfirm: "Pay",
    modalSelectPayQrCancel: "Cancel",

    // Modal confirm pay qr
    modalConfirmSelectPayQrTitle: "Order Confirmation",
    modalConfirmSelectPayQrContent1:
        "Click continue to proceed with the payment of <bold>{{price}} USD,</bold> to remove Ads from <bold>{{menu}}</bold> for a period of <bold>{{count}} month</bold>",
    modalConfirmSelectPayQrContent1_plural:
        "Click continue to proceed with the payment of <bold>{{price}} USD,</bold> to remove Ads from <bold>{{menu}}</bold> for a period of <bold>{{count}} months</bold>",
    modalConfirmSelectPayQrConfirm: "Continue",
    modalConfirmSelectPayQrCancel: "Cancel",

    // Modal stripe
    modalStripeTittle: "Remove Ads",
    modalStripeSubTittle: "{{count}} month",
    modalStripeSubTittle_plural: "{{count}} months",
    modalStripeContent1:
        "This plan will remove the Ads from <bold>{{menu}}</bold> for <bold>{{count}} month</bold>, for a total of <bold>{{price}} USD.</bold>",
    modalStripeContent1_plural:
        "This plan will remove the Ads from <bold>{{menu}}</bold> for <bold>{{count}} months</bold>, for a total of <bold>{{price}} USD.</bold>",
    modalStripeContent2:
        "Do you want to confirm your purchase using this payment method?",
    modalStripeConfirm: "Pay",
    modalStripeCancel: "Cancel",

    // Modal loading stripe
    modalStripeLoadingTittle: "We Are Creating Your Payment",

    // Modal Pay Method
    modalPayMethodTittle: "Remove Ads",
    modalPayMethodSubTittle: "{{count}} month",
    modalPayMethodSubTittle_plural: "{{count}} months",
    modalPayMethodContent1:
        "This plan will remove the Ads from <bold>{{menu}}</bold> for <bold>{{count}} month</bold>, for a total of <bold>{{price}} USD.</bold>",
    modalPayMethodContent1_plural:
        "This plan will remove the Ads from <bold>{{menu}}</bold> for <bold>{{count}} months</bold>, for a total of <bold>{{price}} USD.</bold>",
    modalPayMethodContent2:
        "We will use the following payment method for your purchase:",
    modalPayMethodContent3:
        "<bold>{{brand}}</bold> Ending in <bold>{{last4}}</bold> Expires <bold>{{month}}/{{year}}</bold>",
    modalPayMethodContent4:
        "Do you want to confirm your purchase using this same means of payment?",
    modalPayMethodConfirm: "Pay",
    modalPayMethodAnotherMethod: "New Payment Method",

    // Modal delete payment method
    modalDeletePaymentMethodTittle: "Delete Payment Method",
    modalDeletePaymentMethodContent1:
        "If you delete your payment method, you must add a new one on your next purchase.",
    modalDeletePaymentMethodContent2:
        "<bold>{{brand}}</bold> Ending in <bold>{{last4}}</bold> Expires <bold>{{month}}/{{year}}</bold>",
    modalDeletePaymentMethodContent3:
        "Remember that <bold>this action can't be undone.</bold> <br/> <br/> Are you sure you want to delete your account?",

    /* --- Reports Scan --- */
    reportsScanTitle: "Scans",

    reportsScanToast1Content:
        "The scan reports are updated once a day. <bold>The scans of today will appear tomorrow.</bold>",

    numberScans: "Number of Scans",
    last7Days: "Last 7 days",
    lastWeek: "Last week",
    last30Days: "Last 30 days",
    lastMonth: "Last calendar month",

    sun: "Sun",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",

    /* --- Page 404 --- */
    pageNotFound: "This page could not be found",
    goToHome: "Go to home",

    /* --- Menu Not Found --- */
    menuNotFound:
        "The QR code could not be found.<br><br>If you think this is an error try again.",

    /* --- Terms --- */
    "pt-section-privacy-title": "Privacy Policy",
    "pt-section-privacy-text-1":
        "MyFreeQr provides free QR menus, data collection forms, and other services to users around the world. Our Privacy Policy helps explain our information practices. For example, we talk about what information we collect and how this affects you. We also explain the steps we take to protect your privacy – like building MyFreeQr so contact information of patrons is stored in their phone at all times and only shared when explicitly opts-in in a given establishment.",
    "pt-section-privacy-text-2":
        "When we say “MyFreeQr,” “our,” “we,” or “us,” we’re talking about SuperQr Ltd. This Privacy Policy (“Privacy Policy”) applies to all of our apps, services, features, software, and website (together, “Services”) unless specified otherwise.",
    "pt-section-privacy-text-3":
        "Please also read our Terms of Service (“Terms”), which describes the terms under which you use our Services.",
    "pt-section-privacy-information-we-collect-title": "Information We Collect",
    "pt-section-privacy-information-we-collect-p":
        "MyFreeQr receives or collects information when we operate and provide our Services, including when you install, access, or use our Services.",
    "pt-section-privacy-information-you-provide-title":
        "Information You Provide",
    "pt-section-privacy-your-account-information": "Your Account Information.",
    "pt-section-privacy-your-account-information-desc":
        "You provide your email address to create a MyFreeQr account. You may also add other information to your account, such as a name, logo, and daily message.",
    "pt-section-privacy-your-patrons": "Your Patrons.",
    "pt-section-privacy-your-patrons-desc":
        "We do not retain your patrons information any longer than required by law for contact tracing purposes if the law specifies and you opt-in for this optional feature. We may keep it on our servers for up to 30 days as we try to comply with evolving regulation due to the global pandemic situation. If the information is not required by the authorities 30 days after the mandatory holding period, we delete it.",
    "pt-section-privacy-your-pictures": "Your Pictures.",
    "pt-section-privacy-your-pictures-desc":
        "To help you display your menu easily and efficiently, we may re-size or compress your menu before storing it in our servers. The same applies to your logo.",
    "pt-section-privacy-customer-support": "Customer Support.",
    "pt-section-privacy-customer-support-desc":
        "You may provide us with information related to your use of our Services, including copies of your menu, patrons contact information, and how to contact you so we can provide you customer support. For example, you may send us an email with information relating to our app performance or other issues.",
    "pt-section-privacy-auto-collected-info-title":
        "Automatically Collected Information",
    "pt-section-privacy-usage-and-log": "Usage and Log Information.",
    "pt-section-privacy-usage-and-log-desc":
        "We collect service-related, diagnostic, and performance information. This includes information about your activity (such as how you use our Services, how you interact with others using our Services, and the like), log files, and diagnostic, crash, website, and performance logs and reports.",
    "pt-section-privacy-device-and-connection-info":
        "Device and Connection Information.",
    "pt-section-privacy-device-and-connection-info-desc":
        "We collect device-specific information when you install, access, or use our Services. This includes information such as hardware model, operating system information, browser information, IP address, mobile network information, and device identifiers.",
    "pt-section-privacy-cookies": "Cookies.",
    "pt-section-privacy-cookies-desc":
        "We use cookies to operate and provide our Services, including to provide our Services that are web-based, improve your experiences, understand how our Services are being used, and customize our Services. For example, we use cookies to provide MyFreeQr for web and desktop and other web-based services. We may also use cookies to understand which of our FAQs are most popular and to show you relevant content related to our Services. Additionally, we may use cookies to remember your choices, such as your language preferences, name and phone for check-in, and otherwise to customize our Services for you. Learn more about how we use cookies to provide you our Services.",
    "pt-section-privacy-third-party-info-title": "Third-Party Information",
    "pt-section-privacy-third-party-providers": "Third-Party Providers.",
    "pt-section-privacy-third-party-providers-desc":
        "We work with third-party providers to help us operate, provide, improve, understand, customize, support, and market our Services. For example, we work with companies to distribute our web app(s), provide our infrastructure, delivery, and other systems, supply map and places information, process payments, help us understand how people use our Services, and market our Services. These providers may provide us information about you in certain circumstances; for example, app stores may provide us reports to help us diagnose and fix service issues.",
    "pt-section-privacy-third-party-services": "Third-Party Services.",
    "pt-section-privacy-third-party-services-desc":
        "We allow you to use our Services in connection with third-party services. If you use our Services with such third-party services, we may receive information about you from them; for example, if you use the MyFreeQr share button on a news service to share a news article with your MyFreeQr contacts, groups, or broadcast lists on our Services, or if you choose to access our Services through a mobile carrier’s or device provider’s promotion of our Services. Please note that when you use third-party services, their own terms and privacy policies will govern your use of those services.",
    "pt-section-privacy-how-use-info-title": "How We Use Information",
    "pt-section-privacy-how-use-info-subtitle":
        "We use all the information we have to help us operate, provide, improve, understand, customize, support, and market our Services.",
    "pt-section-privacy-our-services": "Our Services.",
    "pt-section-privacy-our-services-desc":
        "We operate and provide our Services, including providing customer support, and improving, fixing, and customizing our Services. We understand how people use our Services, and analyze and use the information we have to evaluate and improve our Services, research, develop, and test new services and features, and conduct troubleshooting activities. We also use your information to respond to you when you contact us. We use cookies to operate, provide, improve, understand, and customize our Services.",
    "pt-section-privacy-safety-security": "Safety and Security.",
    "pt-section-privacy-safety-security-desc":
        "We verify accounts and activity, and promote safety and security on and off our Services, such as by investigating suspicious activity or violations of our Terms, and to ensure our Services are being used legally.",
    "pt-section-privacy-comns-about-our-services":
        "Communications About Our Services.",
    "pt-section-privacy-comns-about-our-services-desc":
        "We communicate with you about our Services and features and let you know about our terms and policies and other important updates.",
    "pt-section-privacy-info-we-share-title": "Information You And We Share",
    "pt-section-privacy-info-we-share-subtitle":
        "You share your information as you use and communicate through our Services, and we share your information to help us operate, provide, improve, understand, customize, support, and market our Services.",
    "pt-section-privacy-account-info": "Account Information.",
    "pt-section-privacy-account-info-desc":
        "Your email, establishment name number, logo, menu, daily message, contract tracing active status, and visitors book. In particular, your name, logo, menu and daily message may be available to anyone who uses our Services.",
    "pt-section-privacy-contacts-and-others": "Your Contacts and Others.",
    "pt-section-privacy-contacts-and-others-desc":
        "Users with whom you communicate may store or reshare your information with others on and off our Services.",
    "pt-section-privacy-manage-your-info-title": "Managing Your Information",
    "pt-section-privacy-manage-your-info-subtitle":
        "If you would like to manage, change, limit, or delete your information, we allow you to do that through the following tools:",
    "pt-section-privacy-change-email-pass":
        "Changing Your Email, Password, Name, Logo Menu, and Daily Message.",
    "pt-section-privacy-change-email-pass-desc":
        "You can change any of the above at any time in your account or dashboard.",
    "pt-section-privacy-deleting-account": "Deleting Your MyFreeQr Account.",
    "pt-section-privacy-deleting-account-desc-1":
        "You may delete your MyFreeQr account at any time (including if you want to revoke your consent to our use of your information) sending us an email at",
    "pt-section-privacy-deleting-account-desc-2":
        "When you delete your MyFreeQr account, any of your information we no longer need to operate and provide our Services will be deleted. Please remember that when you delete your account, it does not affect the information other users have relating to you, such as any copies they might have of the information you sent them.",
    "pt-section-privacy-law-and-protection-title": "Law And Protection",
    "pt-section-privacy-law-and-protection-subtitle":
        "We may collect, use, preserve, and share your information if we have a good-faith belief that it is reasonably necessary to: (a) respond pursuant to applicable law or regulations, to legal process, or to government requests; (b) enforce our Terms and any other applicable terms and policies, including for investigations of potential violations; (c) detect, investigate, prevent, and address fraud and other illegal activity, security, or technical issues; or (d) protect the rights, property, and safety of our users, MyFreeQr, or others.",
    "pt-section-privacy-updates-policy-title": "Updates To Our Policy",
    "pt-section-privacy-updates-policy-subtitle":
        "We may amend or update our Privacy Policy. We will provide you notice of amendments to this Privacy Policy, as appropriate, and update the “Last Modified” date at the top of this Privacy Policy. Your continued use of our Services confirms your acceptance of our Privacy Policy, as amended. If you do not agree to our Privacy Policy, as amended, you must stop using our Services. Please review our Privacy Policy from time to time.",
    "pt-section-privacy-contact-us-title": "Contact Us",
    "pt-section-privacy-contact-us-subtitle":
        "If you have questions about our Privacy Policy, please contact us.",
    "pt-terms-of-service-title": "Terms Of Service",
    "pt-terms-of-service-subtitle":
        "SuperQr Ltd. (“MyFreeQr,” “our,” “we,” or “us”) provides QR contactless menus, data collection forms, and other services to users around the world. Please read our Terms of Service so you understand what’s up with your use of MyFreeQr. You agree to our Terms of Service (“Terms”) by installing, accessing, or using our apps, services, features, software, or website (together, “Services”).",
    "pt-terms-of-service-about-our-services-title": "About Our Services",
    "pt-terms-of-service-registration": "Registration.",
    "pt-terms-of-service-registration-desc":
        "You must register for our Services using accurate data, provide your current email, and, if you change it, update this email using our in-app change email feature. You agree to receive email messages (from us or our third-party providers).",
    "pt-terms-of-service-age": "Age.",
    "pt-terms-of-service-age-desc":
        "You must be at least 18 years old to use our Services (or such greater age required in your country for you to be authorized to use our Services without parental approval). In addition to being of the minimum required age to use our Services under applicable law, if you are not old enough to have authority to agree to our Terms in your country, your parent or guardian must agree to our Terms on your behalf.",
    "pt-terms-of-service-devices-software": "Devices and Software.",
    "pt-terms-of-service-devices-software-desc":
        "You must provide certain devices, software, and data connections to use our Services, which we otherwise do not supply. For as long as you use our Services, you consent to downloading and installing updates to our Services, including automatically.",
    "pt-terms-of-service-fees-taxes": "Fees and Taxes.",
    "pt-terms-of-service-fees-taxes-desc":
        "You are responsible for all carrier data plan and other fees and taxes associated with your use of our Services. We may charge you for our Services, including applicable taxes. We may refuse or cancel orders. We do not provide refunds for our Services, except as required by law.",
    "pt-terms-of-service-user-data-title": "Privacy policy and user data",
    "pt-terms-of-service-user-data-subtitle":
        "MyFreeQr cares about your privacy. MyFreeQr’s Privacy Policy describes our information (including message) practices, including the types of information we receive and collect from you and how we use and share this information. You agree to our data practices, including the collection, use, processing, and sharing of your information as described in our Privacy Policy, as well as the transfer and processing of your information to the United States and other countries globally where we have or use facilities, service providers, or partners, regardless of where you use our Services. You acknowledge that the laws, regulations, and standards of the country in which your information is stored or processed may be different from those of your own country.",
    "pt-terms-of-service-acceptable-use": "Acceptable use of our services",
    "pt-terms-of-service-terms-and-policies": "Our Terms and Policies.",
    "pt-terms-of-service-terms-and-policies-desc":
        "You must use our Services according to our Terms and posted policies. If we disable your account for a violation of our Terms, you will not create another account without our permission.",
    "pt-terms-of-service-legal-acceptable-use": "Legal and Acceptable Use.",
    "pt-terms-of-service-legal-acceptable-use-desc":
        "You must access and use our Services only for legal, authorized, and acceptable purposes. You will not use (or assist others in using) our Services in ways that: (a) violate, misappropriate, or infringe the rights of MyFreeQr, our users, or others, including privacy, publicity, intellectual property, or other proprietary rights; (b) are illegal, obscene, defamatory, threatening, intimidating, harassing, hateful, racially, or ethnically offensive, or instigate or encourage conduct that would be illegal, or otherwise inappropriate, including promoting violent crimes; (c) involve publishing falsehoods, misrepresentations, or misleading statements; (d) impersonate someone; (e) involve sending illegal or impermissible communications such as bulk messaging, auto-messaging, auto-dialing, and the like; or (f) involve any non-personal use of our Services unless otherwise authorized by us.",
    "pt-terms-of-service-harm-to-us-or-users": "Harm to MyFreeQr or Our Users.",
    "pt-terms-of-service-harm-to-us-or-users-desc":
        "You must not (or assist others to) access, use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sublicense, transfer, display, perform, or otherwise exploit our Services in impermissible or unauthorized manners, or in ways that burden, impair, or harm us, our Services, systems, our users, or others, including that you must not directly or through automated means: (a) reverse engineer, alter, modify, create derivative works from, decompile, or extract code from our Services; (b) send, store, or transmit viruses or other harmful computer code through or onto our Services; (c) gain or attempt to gain unauthorized access to our Services or systems; (d) interfere with or disrupt the integrity or performance of our Services; (e) create accounts for our Services through unauthorized or automated means; (f) collect the information of or about our users in any impermissible or unauthorized manner; (g) sell, resell, rent, or charge for our Services; or (h) distribute or make our Services available over a network where they could be used by multiple devices at the same time.",
    "pt-terms-of-service-keep-account-secure": "Keeping Your Account Secure.",
    "pt-terms-of-service-keep-account-secure-desc":
        "You are responsible for keeping your device and your MyFreeQr account safe and secure, and you must notify us promptly of any unauthorized use or security breach of your account or our Services.",
    "pt-terms-of-service-third-party-services-title": "Third-party services",
    "pt-terms-of-service-third-party-services-subtitle":
        "Our Services may allow you to access, use, or interact with third-party websites, apps, content, and other products and services. Please note that when you use third-party services, their own terms and privacy policies will govern your use of those services.",
    "pt-terms-of-service-licenses": "Licenses",
    "pt-terms-of-service-your-rights":
        "Your Rights. MyFreeQr does not claim ownership of the information that you submit for your MyFreeQr account or through our Services. You must have the necessary rights to such information that you submit for your MyFreeQr account or through our Services and the right to grant the rights and licenses in our Terms.",
    "pt-terms-of-service-our-rights":
        "MyFreeQr’s Rights. We own all copyrights, trademarks, domains, logos, trade dress, trade secrets, patents, and other intellectual property rights associated with our Services.",
    "pt-terms-of-service-license-to-us":
        "Your License to MyFreeQr. In order to operate and provide our Services, you grant MyFreeQr a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, create derivative works of, display, and perform the information (including the content) that you upload, submit, store, send, or receive on or through our Services. The rights you grant in this license are for the limited purpose of operating and providing our Services (such as to allow us to display your name, menu, logo and daily message, transmit this information to patrons, and otherwise as described in our Privacy Policy).",
    "pt-terms-of-service-license-to-you":
        "MyFreeQr’s License to You. We grant you a limited, revocable, non-exclusive, non-sublicensable, and non-transferable license to use our Services, subject to and in accordance with our Terms. This license is for the sole purpose of enabling you to use our Services, in the manner permitted by our Terms. No licenses or rights are granted to you by implication or otherwise, except for the licenses and rights expressly granted to you.",
    "pt-terms-of-service-reporting-third-party-copyright-title":
        "Reporting third-party copyright, trademark, and other intellectual property infringement",
    "pt-terms-of-service-reporting-third-party-copyright-subtitle":
        "To report claims of third-party copyright, trademark, or other intellectual property infringement, please email us at",
    "pt-terms-of-service-disclaimers-title": "Disclaimers",
    "pt-terms-of-service-disclaimers-subtitle":
        "YOU USE OUR SERVICES AT YOUR OWN RISK AND SUBJECT TO THE FOLLOWING DISCLAIMERS. WE ARE PROVIDING OUR SERVICES ON AN “AS IS” BASIS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND FREEDOM FROM COMPUTER VIRUS OR OTHER HARMFUL CODE. WE DO NOT WARRANT THAT ANY INFORMATION PROVIDED BY US IS ACCURATE, COMPLETE, OR USEFUL, THAT OUR SERVICES WILL BE OPERATIONAL, ERROR FREE, SECURE, OR SAFE, OR THAT OUR SERVICES WILL FUNCTION WITHOUT DISRUPTIONS, DELAYS, OR IMPERFECTIONS. WE DO NOT CONTROL, AND ARE NOT RESPONSIBLE FOR, CONTROLLING HOW OR WHEN OUR USERS USE OUR SERVICES OR THE FEATURES, SERVICES, AND INTERFACES OUR SERVICES PROVIDE. WE ARE NOT RESPONSIBLE FOR AND ARE NOT OBLIGATED TO CONTROL THE ACTIONS OR INFORMATION (INCLUDING CONTENT) OF OUR USERS OR OTHER THIRD PARTIES. YOU RELEASE US, OUR SUBSIDIARIES, AFFILIATES, AND OUR AND THEIR DIRECTORS, OFFICERS, EMPLOYEES, PARTNERS, AND AGENTS (TOGETHER, THE “MyFreeQr PARTIES”) FROM ANY CLAIM, COMPLAINT, CAUSE OF ACTION, CONTROVERSY, OR DISPUTE (TOGETHER, “CLAIM”) AND DAMAGES, KNOWN AND UNKNOWN, RELATING TO, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH ANY SUCH CLAIM YOU HAVE AGAINST ANY THIRD PARTIES. YOU WAIVE ANY RIGHTS YOU MAY HAVE UNDER CALIFORNIA CIVIL CODE §1542, OR ANY OTHER SIMILAR APPLICABLE STATUTE OR LAW OF ANY OTHER JURISDICTION, WHICH SAYS THAT: A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.",
    "pt-terms-of-service-limitation-liability-title": "Limitation of liability",
    "pt-terms-of-service-limitation-liability-subtitle":
        "THE MyFreeQr PARTIES WILL NOT BE LIABLE TO YOU FOR ANY LOST PROFITS OR CONSEQUENTIAL, SPECIAL, PUNITIVE, INDIRECT, OR INCIDENTAL DAMAGES RELATING TO, ARISING OUT OF, OR IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES, EVEN IF THE MyFreeQr PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. OUR AGGREGATE LIABILITY RELATING TO, ARISING OUT OF, OR IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES WILL NOT EXCEED THE GREATER OF ONE HUNDRED DOLLARS (USD 100) OR THE AMOUNT YOU HAVE PAID US IN THE PAST TWELVE MONTHS. THE FOREGOING DISCLAIMER OF CERTAIN DAMAGES AND LIMITATION OF LIABILITY WILL APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. THE LAWS OF SOME STATES OR JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THE EXCLUSIONS AND LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU. NOTWITHSTANDING ANYTHING TO THE CONTRARY IN OUR TERMS, IN SUCH CASES, THE LIABILITY OF THE MyFreeQr PARTIES WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.",
    "pt-terms-of-service-indemnification-title": "Indemnification",
    "pt-terms-of-service-indemnification-subtitle":
        "You agree to defend, indemnify, and hold harmless the MyFreeQr Parties from and against all liabilities, damages, losses, and expenses of any kind (including reasonable legal fees and costs) relating to, arising out of, or in any way in connection with any of the following: (a) your access to or use of our Services, including information provided in connection therewith; (b) your breach or alleged breach of our Terms; or (c) any misrepresentation made by you. You will cooperate as fully as required by us in the defense or settlement of any Claim.",
    "pt-terms-of-service-dispute-resolution-title": "Dispute resolution",
    "pt-terms-of-service-form-venue": "Forum and Venue.",
    "pt-terms-of-service-form-venue-desc":
        "If you are a MyFreeQr user located in the United States or Canada, the “Special Arbitration Provision for United States or Canada Users” section below applies to you. Please also read that section carefully and completely. If you are not subject to the “Special Arbitration Provision for United States or Canada Users” section below, you agree that you and MyFreeQr will resolve any Claim relating to, arising out of, or in any way in connection with our Terms, us, or our Services (each, a “Dispute,” and together, “Disputes”) exclusively in the United Kingdom courts, and you agree to submit to the personal jurisdiction of such courts for the purpose of litigating all such Disputes. Without prejudice to the foregoing, you agree that, in our sole discretion, we may elect to resolve any Dispute we have with you in any competent court in the country in which you reside that has jurisdiction over the Dispute.",
    "pt-terms-of-service-governing-law": "Governing Law.",
    "pt-terms-of-service-governing-law-desc":
        "The laws of the United Kingdom govern our Terms, as well as any Disputes, whether in court or arbitration, which might arise between MyFreeQr and you, without regard to conflict of law provisions.",
    "pt-terms-of-service-language-clause": "Language Clause.",
    "pt-terms-of-service-language-clause-desc":
        "The whole text of the present Contract, as well as the documents derived from it, including those in the Annexes, have been written in several languages, all versions being deemed authentic, but for legal purposes the text in English is to be given priority of interpretation.",
    "pt-terms-of-service-availability-termination":
        "Availability and termination of our services",
    "pt-terms-of-service-availability-services":
        "Availability of Our Services.",
    "pt-terms-of-service-availability-services-desc":
        "Our Services may be interrupted, including for maintenance, repairs, upgrades, or network or equipment failures. We may discontinue some or all of our Services, including certain features and the support for certain devices and platforms, at any time. Events beyond our control may affect our Services, such as events in nature and other force majeure events.",
    "pt-terms-of-service-termination": "Termination.",
    "pt-terms-of-service-termination-desc":
        "We may modify, suspend, or terminate your access to or use of our Services anytime for any reason, such as if you violate the letter or spirit of our Terms or create harm, risk, or possible legal exposure for us, our users, or others. The following provisions will survive any termination of your relationship with MyFreeQr: “Licenses,” “Disclaimers,” “Limitation of Liability,” “Indemnification,” “Dispute Resolution,” “Availability and Termination of our Services,” “Other,” and “Special Arbitration Provision for United States or Canada Users.”",
    "pt-terms-of-service-other": "Other",
    "pt-terms-of-service-other-1":
        "Unless a mutually executed agreement between you and us states otherwise, our Terms make up the entire agreement between you and us regarding MyFreeQr and our Services, and supersede any prior agreements.",
    "pt-terms-of-service-other-2":
        "We may ask you to agree to additional terms for certain of our Services in the future, which will govern to the extent there is a conflict between our Terms and such additional terms.",
    "pt-terms-of-service-other-3":
        "Our Services are not intended for distribution to or use in any country where such distribution or use would violate local law or would subject us to any regulations in another country. We reserve the right to limit our Services in any country.",
    "pt-terms-of-service-other-4":
        "You will comply with all applicable U.S. and non-U.S. export control and trade sanctions laws (“Export Laws”). You will not, directly or indirectly, export, re-export, provide, or otherwise transfer our Services: (a) to any individual, entity, or country prohibited by Export Laws; (b) to anyone on U.S. or non-U.S. government restricted parties lists; or (c) for any purpose prohibited by Export Laws, including nuclear, chemical, or biological weapons, or missile technology applications without the required government authorizations. You will not use or download our Services if you are located in a restricted country, if you are currently listed on any U.S. or non-U.S. restricted parties list, or for any purpose prohibited by Export Laws, and you will not disguise your location through IP proxying or other methods.",
    "pt-terms-of-service-other-5":
        "Our Terms are written in English (U.S.). Any translated version is provided solely for your convenience. To the extent any translated version of our Terms conflicts with the English version, the English version controls.",
    "pt-terms-of-service-other-6":
        "Any amendment to or waiver of our Terms requires our express consent.",
    "pt-terms-of-service-other-7":
        "We may amend or update these Terms. We will provide you notice of amendments to our Terms, as appropriate, and update the “Last Modified” date at the top of our Terms. Your continued use of our Services confirms your acceptance of our Terms, as amended. If you do not agree to our Terms, as amended, you must stop using our Services. Please review our Terms from time to time.",
    "pt-terms-of-service-other-8":
        "All of our rights and obligations under our Terms are freely assignable by us to any of our affiliates or in connection with a merger, acquisition, restructuring, or sale of assets, or by operation of law or otherwise, and we may transfer your information to any of our affiliates, successor entities, or new owner.",
    "pt-terms-of-service-other-9":
        "You will not transfer any of your rights or obligations under our Terms to anyone else without our prior written consent.",
    "pt-terms-of-service-other-10":
        "Nothing in our Terms will prevent us from complying with the law.",
    "pt-terms-of-service-other-11":
        "Except as contemplated herein, our Terms do not give any third-party beneficiary rights.",
    "pt-terms-of-service-other-12":
        "If we fail to enforce any of our Terms, it will not be considered a waiver.",
    "pt-terms-of-service-other-13":
        "If any provision of these Terms is deemed unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from our Terms and shall not affect the validity and enforceability of the remaining provisions, except as set forth in the “Special Arbitration Provision for United States or Canada Users” — “Severability” section below.",
    "pt-terms-of-service-other-14":
        "We reserve all rights not expressly granted by us to you. In certain jurisdictions, you may have legal rights as a consumer, and our Terms are not intended to limit such consumer legal rights that may not be waived by contract.",
    "pt-terms-of-service-other-15":
        "We always appreciate your feedback or other suggestions about MyFreeQr and our Services, but you understand that we may use your feedback or suggestions without any obligation to compensate you for them (just as you have no obligation to offer them).",
    "pt-terms-of-service-attribution-notice-title": "Attribution Notice",
    "pt-terms-of-service-attribution-notice-subtitle":
        "We thank the following organizations and individuals for their contributions:",

    /* --------------- COMPONENTS --------------- */

    /* --- Ads --- */
    loadMenu: "Loading your menu...",
    adProduct1: "Apple Cider Vinegar Gummy Vitamins",
    adProduct2: "REVLON One-Step Hair Dryer And Volumizer",
    adProduct3: "Furbo Dog Camera: Treat Tossing",
    adProduct4: "Lodge Miniature Skillet",
    adProduct5: "Wool Dryer Balls",
    adProduct6: "Jabra Elite Active 75t True Wireless Bluetooth EarbUSD",
    adProduct7: "High Waist Biker Shorts Yoga Workout Running",
    adProduct8: "LEVOIT Air Purifier",

    /* --- Footer --- */
    freeQRMenu: "Free QR Menu",
    createAccountNow: "Create an account now",
    termsPrivacy: "Terms & Privacy",
    blog: "Blog",

    /* --- Gift Button --- */
    // Modal
    giftModalSubtitle1: "You subscribed <bold>a few minutes</bold> ago",
    giftModalSubtitle2: "You subscribed <bold>{{timeCreated}} hours</bold> ago",

    /* --- Help Button --- */
    helpButtonTitle: "Support",
    // Modal
    helpButtonModalTitle: "Contact Our Support Team",

    /* --- Mobile Empty --- */
    mobileEmptyTitle: "Your account is set!",
    mobileEmptyDescription: "Go to your computer to start using MyFreeQr",

    /* --- My Card Panel --- */
    getStarted: "Get started",
    selectPlan: "Select plan",
    currentPlan: "Your current plan",
    feedbackDescription:
        "Get <bold><a>Free without Ads</a></bold>by signing up to our<bold><a> Feedback Program! </a></bold>",
    limitedSeats: "Limited Seats",

    /* --- Plans --- */
    save20: "Save 20%",
    billedMonthly: "BILLED MONTHLY",
    Monthly: "MONTHLY",
    billedYearly: "BILLED YEARLY",
    Yearly: "YEARLY",

    freePlanTitle: "Free",
    freePlanDescription:
        "3 dynamic QR codes. Unlimited scans. Scan reports. 3-second Ads",

    feedbackPlanTitle: "Feedback",
    feedbackPlanDescription:
        "3 dynamic QR codes. Unlimited scans. Scan reports. No Ads",

    /* --- Side Content --- */
    upgradePlan: "Upgrade your plan",

    /* -- Account -- */
    accountTitle: "Account Details",

    tab1: "Account",
    tab2: "Invoices",
    tab3: "Your Product",

    // Tab 1 - Account
    yourAccount: "Your Account",
    personalInfo: "Personal Info",

    yourCompany: "Your Restaurant",

    firstName: "First Name",
    lastName: "Last Name",
    emailAddress: "Email Address",
    phoneNumber: "Phone Number",

    currentPassword: "Current Password",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",

    timeZone: "Time Zone",

    companyName: "Restaurant Name",

    streetAddress: "Street Address",
    country: "Country",
    city: "City",
    stateProvince: "State/Province",
    postalCode: "Postal Code",

    // Tab 2 - Invoices
    invoices: "Invoices",
    billing: "Billing",
    invoicesEmptySectionTitle: "Your invoices go here",
    invoicesStatus: "Accepted",
    invoicePaymentMethod: "Payment Method",
    invoiceCard: "{{brand}} Ending in {{last4}} Expires {{month}}/{{year}}",

    // Tab 3 - Your Product
    yourPlan: "Your Plan",
    planFreeAds: "Free of Ads",
    planShowAds: "Showing Ads",
    cancelPlan: "Cancel Plan",
    emailsSent: "emails sent this month",
    inUse: "{{count}} Menu in use",
    inUse_plural: "{{count}} menus in use",

    /* --- Side Nav --- */
    recoverPassword: "You don't yet have a password? <bold>Click here</bold>",

    /* -- Sub Menu -- */

    /* -- Menu -- */

    /* -- Sub Menu Click -- */

    /* -- Menus -- */
    newMenuWord: "New Menu",
    addNewMenuWord: "Add New Menu",
    addMenu: "New Menu",

    /* -- Profile -- */
    getHelp: "Get Help",
    logOut: "Log Out",
    profileTitle: "Upgrade your plan",
    profileContent:
        "Remove the Ads when your customs scan your QR code. <br> <br> Remove the Ads from scans when you switch to a paid plan.",
    profileButton: "Upgrade Now",

    /* --- Temp Toast --- */
    tempToast1Content:
        "You have collected data on the maximum number of customers allowed on your plan. To continue collecting new customers, please",
    tempToast1Link: "upgrade your plan",

    tempToast2Content:
        "Your free trial will expire in {{leftTrialDays}} days. To continue enjoying MyFreeQr",
    tempToast2Link: "please click here",

    tempToast3Content: "Your trial has ended. To continue enjoying MyFreeQr",
    tempToast3Link: "please click here",

    tempToast4Content:
        "Your internet connection seems to be having issues. Some actions may not work until you are back online",

    tempToast5Content:
        "It seems that something has gone wrong. The page will reload automatically in 5 seconds.",
    tempToast5Link: "please click here",

    /* --------------- TOAST --------------- */

    /* --- Error --- */
    toastUnexpectedError: "An unexpected error occurred, please try again",
    toastErrorFirstName: "Please enter your first name",
    toastErrorLastName: "Please enter your last name",
    toastErrorEmail: "Please enter your email",
    toastErrorCompany: "Please enter your company name",
    toastErrorPass: "Please enter your password",
    toastErrorNewPass: "Please enter your new password",
    toastErrorConfirmNewPass: "Please confirm your new password",
    toastErrorAddress: "Enter your address",
    toastErrorCountry: "Please enter your country",
    toastErrorCity: "Please enter your city",
    toastErrorState: "Please enter your state/province",
    toastErrorPostalCode: "Please enter your postal code",
    toastErrorUrl: "Please enter a valid URL",
    toastErrorWhatsApp: "Please enter a WhatsApp",
    toastErrorMenuName: "Please enter your menu name",
    toastErrorLoadImage:
        "There was an error loading your image, please try again",
    toastErrorEmailNotSent:
        "There was an error sending your QR code, please try again",
    toastErrorShare:
        "There was an error sharing your QR code, please try again",
    toastErrorSizeExceed: "The file size should not exceed 100 MB",
    toastErrorUploadPdrOrImage: "Please upload an image or a PDF",
    toastErrorInvalidImageFormat: "Please upload an image as .jpeg or .png ",
    toastErrorCustomUrl: "Please enter your custom URL",
    toastErrorInvalidEmail: "Please enter a valid email address",
    toastErrorInvalidPass: "Your password must be at least 6 characters long",
    toastErrorPassNotMatch:
        "Your new password doesn’t match the password confirmation",
    toastErrorCustomUrlNotAvailable:
        "This custom URL is not available, please try a different one",
    toastErrorEmailNotFound:
        "The email address was not found, please try again",
    toastErrorAccountNotDeleted:
        "The account can't be deleted, please contact to support",
    toastErrorStripeCustomerNoCreated:
        "Your purchase username could not be created, please try again",
    toastErrorStripePaymentNoCreated:
        "Your purchase could not be created, please try again",
    toastErrorStripeInvoiceNoSent:
        "The invoice of your purchase could not be sent, please contact support",
    toastErrorStripeNoConfirmPayment:
        "Your payment could not be made, please try again",
    toastErrorStripeNoGetPayment:
        "Your purchase information could not be validated, please try again",
    toastErrorStripePaymentMethodNoDeleted:
        "Your payment method could not be removed, please contact support",
    toastLimitTimeExceeded:
        "An unexpected error has occurred, this page will be refreshed because the time has been exceeded, please try again after reloading.",
    toastErrorQRcodeNotCreated:
        "Your QR Code can't be created, please try again",

    /* --- Info --- */

    /* --- Success --- */
    toastSuccessQRSent:
        "Your QR code was sent to your email. Please check your spam folder if it doesn’t arrive in your inbox.",
    toastSuccessEmailSent: "Your email was sent",
    toastSuccessLinkCopied: "Link copied",
    toastSuccessPassUpdated: "Your new password was updated",
    toastSuccessLinkPassSent:
        "A link to reset your password was sent to your email",
    toastSuccessEmailUpdated: "Your email was updated",
    toastSuccessUnsubscribed: "You have been unsubscribed",
    toastSuccessInfoUpdated: "Your info was updated",
    toastSuccessMenuDeleted: "Your menu was deleted",
    toastSuccessAccountDeleted: "Your account was deleted",
    toastSuccessInvoiceSent:
        "Your invoice was sent to your email. Please check your spam folder if it doesn’t arrive in your inbox.",

    /* --------------- ERRORS AUTH FIREBASE --------------- */

    "admin-restricted-operation":
        "This operation is restricted to administrators only.",
    "argument-error": "",
    "app-not-authorized":
        "This app, identified by the domain where it's hosted, is not authorized to use Firebase Authentication with the provided API key. Review your key configuration in the Google API console.",
    "app-not-installed":
        "The requested mobile application corresponding to the identifier (Android package name or iOS bundle ID) provided is not installed on this device.",
    "captcha-check-failed":
        "The reCAPTCHA response token provided is either invalid, expired, already used or the domain associated with it does not match the list of whitelisted domains.",
    "code-expired":
        "The SMS code has expired. Please re-send the verification code to try again.",
    "cordova-not-ready": "Cordova framework is not ready.",
    "cors-unsupported": "This browser is not supported.",
    "credential-already-in-use":
        "This credential is already associated with a different user account.",
    "custom-token-mismatch":
        "The custom token corresponds to a different audience.",
    "requires-recent-login":
        "This operation is sensitive and requires recent authentication. Log in again before retrying this request.",
    "dynamic-link-not-activated":
        "Please activate Dynamic Links in the Firebase Console and agree to the terms and conditions.",
    "email-change-needs-verification":
        "Multi-factor users must always have a verified email.",
    "email-already-in-use":
        "The email address is already in use by another account.",
    "expired-action-code": "The action code has expired. ",
    "cancelled-popup-request":
        "This operation has been cancelled due to another conflicting popup being opened.",
    "internal-error": "An internal error has occurred.",
    "invalid-app-credential":
        "The phone verification request contains an invalid application verifier. The reCAPTCHA token response is either invalid or expired.",
    "invalid-app-id":
        "The mobile app identifier is not register for the current project.",
    "invalid-user-token":
        "This user's credential isn't valid for this project. This can happen if the user's token has been tampered with, or if the user isn't for the project associated with this API key.",
    "invalid-auth-event": "An internal error has occurred.",
    "invalid-verification-code":
        "The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure use the verification code provided by the user.",
    "invalid-continue-uri":
        "The continue URL provided in the request is invalid.",
    "invalid-cordova-configuration":
        "The following Cordova plugins must be installed to enable OAuth sign-in: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser and cordova-plugin-customurlscheme.",
    "invalid-custom-token":
        "The custom token format is incorrect. Please check the documentation.",
    "invalid-dynamic-link-domain":
        "The provided dynamic link domain is not configured or authorized for the current project.",
    "invalid-email": "The email address is badly formatted.",
    "invalid-api-key":
        "Your API key is invalid, please check you have copied it correctly.",
    "invalid-cert-hash": "The SHA-1 certificate hash provided is invalid.",
    "invalid-credential":
        "The supplied auth credential is malformed or has expired.",
    "invalid-message-payload":
        "The email template corresponding to this action contains invalid characters in its message. Please fix by going to the Auth email templates section in the Firebase Console.",
    "invalid-multi-factor-session":
        "The request does not contain a valid proof of first factor successful sign-in.",
    "invalid-oauth-provider":
        "EmailAuthProvider is not supported for this operation. This operation only supports OAuth providers.",
    "invalid-oauth-client-id":
        "The OAuth client ID provided is either invalid or does not match the specified API key.",
    "unauthorized-domain":
        "This domain is not authorized for OAuth operations for your Firebase project. Edit the list of authorized domains from the Firebase console.",
    "invalid-action-code":
        "The action code is invalid. This can happen if the code is malformed, expired, or has already been used.",
    "wrong-password":
        "The password is invalid or the user does not have a password.",
    "invalid-persistence-type":
        "The specified persistence type is invalid. It can only be local, session or none.",
    "invalid-phone-number":
        "The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code].",
    "invalid-provider-id": "The specified provider ID is invalid.",
    "invalid-recipient-email":
        "The email corresponding to this action failed to send as the provided recipient email address is invalid.",
    "invalid-sender":
        "The email template corresponding to this action contains an invalid sender email or name. Please fix by going to the Auth email templates section in the Firebase Console.",
    "invalid-verification-id":
        "The verification ID used to create the phone auth credential is invalid.",
    "invalid-tenant-id": "The Auth instance's tenant ID is invalid.",
    "multi-factor-info-not-found":
        "The user does not have a second factor matching the identifier provided.",
    "multi-factor-auth-required":
        "Proof of ownership of a second factor is required to complete sign-in.",
    "missing-android-pkg-name":
        "An Android Package Name must be provided if the Android App is required to be installed.",
    "auth-domain-config-required":
        "Be sure to include authDomain when calling firebase.initializeApp(), by following the instructions in the Firebase console.",
    "missing-app-credential":
        "The phone verification request is missing an application verifier assertion. A reCAPTCHA response token needs to be provided.",
    "missing-verification-code":
        "The phone auth credential was created with an empty SMS verification code.",
    "missing-continue-uri": "A continue URL must be provided in the request.",
    "missing-iframe-start": "An internal error has occurred.",
    "missing-ios-bundle-id":
        "An iOS Bundle ID must be provided if an App Store ID is provided.",
    "missing-multi-factor-info": "No second factor identifier is provided.",
    "missing-multi-factor-session":
        "The request is missing proof of first factor successful sign-in.",
    "missing-or-invalid-nonce":
        "The request does not contain a valid nonce. This can occur if the SHA-256 hash of the provided raw nonce does not match the hashed nonce in the ID token payload.",
    "missing-phone-number":
        "To send verification codes, provide a phone number for the recipient.",
    "missing-verification-id":
        "The phone auth credential was created with an empty verification ID.",
    "app-deleted": "This instance of FirebaseApp has been deleted.",
    "account-exists-with-different-credential":
        "An account already exists with the same email address but different sign-in credentials. Sign in using a provider associated with this email address.",
    "network-request-failed":
        "A network error (such as timeout, interrupted connection or unreachable host) has occurred.",
    "no-auth-event": "An internal error has occurred.",
    "no-such-provider":
        "User was not linked to an account with the given provider.",
    "null-user":
        "A null user object was provided as the argument for an operation which requires a non-null user object.",
    "operation-not-allowed":
        "The given sign-in provider is disabled for this Firebase project. Enable it in the Firebase console, under the sign-in method tab of the Auth section.",
    "operation-not-supported-in-this-environment":
        'This operation is not supported in the environment this application is running on. "location.protocol" must be http, https or chrome-extension and web storage must be enabled.',
    "popup-blocked":
        "Unable to establish a connection with the popup. It may have been blocked by the browser.",
    "popup-closed-by-user":
        "The popup has been closed by the user before finalizing the operation.",
    "provider-already-linked":
        "User can only be linked to one identity for the given provider.",
    "quota-exceeded":
        "The project's quota for this operation has been exceeded.",
    "redirect-cancelled-by-user":
        "The redirect operation has been cancelled by the user before finalizing.",
    "redirect-operation-pending":
        "A redirect sign-in operation is already pending.",
    "rejected-credential":
        "The request contains malformed or mismatching credentials.",
    "second-factor-already-in-use":
        "The second factor is already enrolled on this account.",
    "maximum-second-factor-count-exceeded":
        "The maximum allowed number of second factors on a user has been exceeded.",
    "tenant-id-mismatch":
        "The provided tenant ID does not match the Auth instance's tenant ID",
    timeout: "The operation has timed out.",
    "user-token-expired":
        "The user's credential is no longer valid. The user must sign in again.",
    "too-many-requests":
        "We have blocked all requests from this device due to unusual activity. Try again later.",
    "unauthorized-continue-uri":
        "The domain of the continue URL is not whitelisted.  Please whitelist the domain in the Firebase console.",
    "unsupported-first-factor":
        "Enrolling a second factor or signing in with a multi-factor account requires sign-in with a supported first factor.",
    "unsupported-persistence-type":
        "The current environment does not support the specified persistence type.",
    "unsupported-tenant-operation":
        "This operation is not supported in a multi-tenant context.",
    "unverified-email": "The operation requires a verified email.",
    "user-cancelled":
        "The user did not grant your application the permissions it requested.",
    "user-not-found":
        "There is no existing user record corresponding to the provided identifier.",
    "user-disabled": "The user account has been disabled by an administrator.",
    "user-mismatch":
        "The supplied credentials do not correspond to the previously signed in user.",
    "user-signed-out": "",
    "weak-password": "The password must be 6 characters long or more.",
    "web-storage-unsupported":
        "This browser is not supported or 3rd party cookies and data may be disabled.",

    /* --------------- CUSTOM ERRORS AUTH FIREBASE --------------- */

    "o-unavailable": "It seems the connection is unstable, we'll try again",
    "o-user-not-found": "We haven't found a user with this email",
    "o-popup-closed-by-user": "You have closed the popup",
    "o-email-already-in-use":
        "The email address is already in use by another account.",

    /* TEMPORALS */
    deleteLogo: "Delete Logo",
    uploadLogo: "Upload Logo",
}
