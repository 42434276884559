import React from "react"

/* Router */
// import { Link } from "react-router-dom"

/* Styles */
import "./style.scss"

/* Components */
import { Footer } from "react-materialize"

/* Helpers */
import { LogEvent } from "helpers/LogEvents"

/* i18n */
import { useTranslation } from "react-i18next"

const MyFooter = ({
  country,
  from = "home",
  showAuth = () => {},
  createAccountAction = () => {},
}) => {
  // Const to translation
  const { t } = useTranslation()

  const handlePushAuth = () => {
    if (country) {
      LogEvent("sign_up_clicked", { from: from, country: country })
      //history.push(`/auth/signUp/${from}/${country}`)
    } else {
      LogEvent("sign_up_clicked", { from: from, country: "NaN" })
      //history.push(`/auth/signUp/${from}/`)
    }

    showAuth(true)
    createAccountAction()
  }

  return (
    <div className="o-footer-container">
      <Footer
        className="o-footer"
        copyrights="2020 © SuperQr Ltd."
        links={
          <ul>
            <li>
              <h5
                className="grey-text text-lighten-3 o-click"
                onClick={() => handlePushAuth()}
              >
                <span>{t("freeQRMenu")}</span>
                <br />
                <span>{t("createAccountNow")}</span> <span>&#8594;</span>
              </h5>
            </li>
          </ul>
        }
        moreLinks={
          <>
            <a
              // to={{ pathname: "/blog", from, country }}
              href="https://myfreeqr.com/blog"
              className="grey-text text-lighten-4 right"
              style={{ marginLeft: "20px" }}
            >
              {t("blog")}
            </a>
            <a
              // to={{ pathname: "/terms", from, country }}
              href="https://myfreeqr.com/terms"
              className="grey-text text-lighten-4 right"
              style={{ marginRight: "20px" }}
            >
              {t("termsPrivacy")}
            </a>
          </>
        }
      >
        <h5 className="white-text">{t("siteName")}</h5>
        <p>
          <a href="mailto:hello@myfreeqr.com">hello@myfreeqr.com</a>
        </p>
        <p className="o-social-networks hide">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/supermenuapp/"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/supermenuapp/"
          >
            <i className="fab fa-facebook-square"></i>
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/supermenuapp/"
          >
            <i className="fab fa-twitter"></i>
          </a>
        </p>
      </Footer>
    </div>
  )
}

export default MyFooter
