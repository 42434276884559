/* Firebase */
import { functions } from "controller/firebase"

/* Components */
import { openToast, SUCCESS, ERROR } from "components/toast/Toast"

/* Helpers */
import { LogEvent } from "./LogEvents"

/* I18n */
import i18n from "i18next"

export function DeleteAccount(userId) {
    return new Promise((resolve) => {
        // Call function
        const deleteAccount = functions.httpsCallable("deleteAccount")

        deleteAccount({ userId })
            .then((res) => {
                openToast({
                    content: i18n.t("toastSuccessAccountDeleted"),
                    type: SUCCESS,
                })

                if (res === 200) {
                    LogEvent("delete_account", {
                        new_user_id: userId,
                        description: "Done",
                    })
                } else {
                    LogEvent("delete_account", {
                        new_user_id: userId,
                        description: "Error",
                    })
                }

                resolve(res.data.code)
            })
            .catch((error) => {
                // Save error
                openToast({
                    content: i18n.t("toastErrorAccountNotDeleted"),
                    type: ERROR,
                })
                LogEvent("new_error", {
                    description: `L40 @ DeleteAccount.js | ${error.code} - ${error.message}`,
                })
                resolve("Error")
            })
    })
}