import React from "react"

/* Styles */
import "./style.scss"

/* Assets */
import emptyMenu from "assets/img/png/empty-menu.png"

/* Components */
import EmptySection from "components/emptySection/EmptySection"

/* Helpers */
import { GetFileSizeFromURL } from "helpers/GetFileSizeFromURL"
import { LogEvent } from "helpers/LogEvents"

/* Lazy load */
import LazyImages from "components/lazyImages/LazyImages"

/* Redux */
import { useSelector, useDispatch } from "react-redux"

/* I18n */
import { useTranslation } from "react-i18next"

const ShowMenu = ({ venue, user, qrFlow, from }) => {
  // Const to translation
  const { t } = useTranslation()

  // Const to redux
  const dispatch = useDispatch()

  // Redux states
  const { pricesType, flowSequence, qrCode, versionAd } = useSelector(
    (store) => store.scan.data
  )

  // Component states
  const [arrayImages, setArrayImages] = React.useState([])
  const [indexImages, setIndexImages] = React.useState(1)

  React.useEffect(() => {
    if (flowSequence.showMenu.length > 0) {
      var array_size = 5
      var groups = flowSequence.showMenu
        .map(function (e, i) {
          return i % array_size === 0
            ? flowSequence.showMenu.slice(i, i + array_size)
            : null
        })
        .filter(function (e) {
          return e
        })
      setArrayImages(groups)
    }
  }, [flowSequence.showMenu])

  // Save analytics
  React.useEffect(() => {
    const getMenuSize = async () => {
      // Global size
      let globalSize = 0

      await Promise.all(
        flowSequence.showMenu.map(async (item, index) => {
          await GetFileSizeFromURL(item.url, function (size) {
            globalSize += size / 1024
          })
        })
      )

      // Save the size in Kilobytes
      LogEvent("scan", {
        has_subscribe_form: flowSequence.newsLetterSignUp.active,
        venue_id: venue,
        qr_flow_id: qrFlow,
        new_user_id: user,
        file_size: globalSize,
        num_pages: flowSequence.showMenu.length,
        logo: qrCode.selectedOption,
        prices_type: pricesType,
        qr_body: qrCode.config.body,
        qr_color: qrCode.config.bodyColor,
        qr_eyes: qrCode.config.eye,
        qr_style: qrCode.style,
        type: versionAd,
        from,
        description: `${flowSequence.newsLetterSignUp.active} - ${venue} - ${globalSize} - ${flowSequence.showMenu.length} - ${pricesType} - ${qrCode.selectedOption} - ${versionAd} - ${from}`,
      })
    }

    getMenuSize()
  }, [
    flowSequence.showMenu,
    flowSequence.newsLetterSignUp.active,
    pricesType,
    venue,
    dispatch,
    user,
    qrCode.config.body,
    qrCode.config.bodyColor,
    qrCode.config.eye,
    qrCode.style,
    qrCode.selectedOption,
    qrFlow,
    versionAd,
    from,
  ])

  return (
    <div>
      <div className="o-show-menu-container">
        {flowSequence.showMenu.length > 0 ? (
          arrayImages.map(
            (images, index) =>
              index < indexImages && (
                <LazyImages
                  images={images}
                  setIndexImages={(value) =>
                    setIndexImages(indexImages + value)
                  }
                  key={index}
                  isFirst={index === 0 ? true : false}
                />
              )
          )
        ) : (
          <EmptySection
            title={t("emptySectionTitle")}
            description={t("emptySectionDescription")}
            image={emptyMenu}
          />
        )}
      </div>

      {/* <div className="o-footer-show-menu">
        <a href="/">{t("poweredByMyFreeQr")}</a>
      </div> */}
    </div>
  )
}

export default ShowMenu
