import React from "react"

/* Styles */
import "./style.scss"

/* Components */
import { Button, Icon } from "react-materialize"

const StaticToast = ({
  title = "",
  content = "",
  link = "",
  action = null,
  close = false,
  type = "info",
  closeAction = () => {},
}) => {
  // Component states
  const [hide, setHide] = React.useState(false)

  return (
    <div className="o-static-toast-container">
      <div className={hide ? "hide" : null}>
        <div className="o-toast-container">
          <div className={`o-toast ${type}`}>
            <div>
              <h6 className={title === "" ? "hide" : null}>{title}</h6>
              <Button
                onClick={() => {
                  setHide(true)
                  closeAction()
                }}
                className={!close ? "hide" : null}
              >
                <Icon>close</Icon>
              </Button>
              <div className="o-content">
                {content}
                <div className="o-action-container" onClick={() => action()}>
                  {link}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaticToast
