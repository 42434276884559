export function HideAd() {
    document.getElementById("google-ad-container").classList.add("hide")

    // Enable scroll
    document.body.style.overflow = "auto"
    document.body.style.backgroundColor = "#f1f0f7"
    document.documentElement.style.overflow = "auto"

    document.body.style.height = ""
    document.documentElement.style.height = ""
}