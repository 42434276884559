import React from "react"

/* Styles */
import "./style.scss"

const EmptySection = ({ title = "", description = "", image = "" }) => {
  return (
    <div className="o-empty-section-container">
      <div className="o-content">
        <div className="o-img-container">
          <img className="responsive-img" src={image} alt="" />
        </div>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  )
}

export default EmptySection
