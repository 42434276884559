/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

/* Firebase */
import { auth, firebase, db } from "controller/firebase"

/* Ducks */
import {
    createUserStructure,
    reloadDataToken,
    updateDataUser,
    restartDataUser,
    updateHasPasswordUser,
} from "./userDucks"
import { restartDataQrFlow } from "./qrFlowDucks"

/* Styles */
import M from "materialize-css"

import { openToast, ERROR, SUCCESS } from "components/toast/Toast"

/* I18n */
import i18n from "i18next"

/* Helpers */
import { ListFirebaseCustomError } from "helpers/ListFirebaseCustomErrors"
import { LogEvent } from "helpers/LogEvents"
import { restartDataVenue } from "./venueDucks"
import { restartDataStripe } from "./stripeDucks"

/* Constants or States */
const initData = {
    loading: false,
    active: false,
}

const LOADING_AUTH = "LOADING_AUTH"

const AUTH_EXIT = "AUTH_EXIT"
const AUTH_ERROR = "AUTH_ERROR"

const UPDATE_PASSWORD_EXIT = "UPDATE_PASSWORD_EXIT"
const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR"

const REST_PASSWORD_EXIT = "REST_PASSWORD_EXIT"
const REST_PASSWORD_ERROR = "REST_PASSWORD_ERROR"

const UPDATE_EMAIL_EXIT = "UPDATE_EMAIL_EXIT"
const UPDATE_EMAIL_ERROR = "UPDATE_EMAIL_ERROR"

const UPDATE_MODAL = "UPDATE_MODAL"

const LOGOUT = "LOGOUT"

/* Reducer (Save call API in constant) */
export default function authReducer(state = initData, action) {
    switch (action.type) {
        case LOADING_AUTH:
            return {...state, loading: true }
        case AUTH_EXIT:
            return {
                ...state,
                loading: false,
                active: true,
                ...action.payload,
            }
        case AUTH_ERROR:
            return {...initData }
        case LOGOUT:
            return {...initData }
        case UPDATE_PASSWORD_EXIT:
            return {...state, loading: false }
        case UPDATE_PASSWORD_ERROR:
            return {...state, loading: false }
        case REST_PASSWORD_EXIT:
            return {...state, loading: false }
        case REST_PASSWORD_ERROR:
            return {...state, loading: false }
        case UPDATE_EMAIL_EXIT:
            return {...state, ...action.payload, loading: false }
        case UPDATE_EMAIL_ERROR:
            return {...state, loading: false }
        case UPDATE_MODAL:
            return {...state, modal: false }
        default:
            return {...state }
    }
}

/* Actions (Calls API) */
export const loginUserWithGoogle =
    ({ from = "home", country }) =>
    async(dispatch, getState) => {
        // Init loading to register or login user
        dispatch({
            type: LOADING_AUTH,
        })

        try {
            // Create new user
            const provider = new firebase.auth.GoogleAuthProvider()
            const res = await auth.signInWithPopup(provider)
            let showModal = false

            // Validate country
            if (!country) {
                country = "NaN"
            }

            // Detect new if user is new to create structure
            if (res.additionalUserInfo.isNewUser) {
                await createUserStructure({
                    uid: res.user.uid,
                    email: res.user.email,
                    country: country,
                    from: from,
                    hasPassword: true,
                })(dispatch)
                showModal = true
                LogEvent("sign_up", {
                    method: "google",
                    resolution: `${window.screen.width} x ${window.screen.height}`,
                    prices_type: 6,
                    new_user_id: res.user.uid,
                    email: res.user.email,
                    from: from,
                    country,
                })
            } else {
                LogEvent("log_in", {
                    method: "google",
                    new_user_id: res.user.uid,
                    email: res.user.email,
                })

                // Get current tokenID
                const idToken = await auth.currentUser.getIdToken(
                    /* forceRefresh */
                    false
                )

                // Save current tokenID
                await db.collection("users").doc(res.user.uid).set({
                    idToken,
                    reloadData: false,
                    reloadDataToken: false,
                }, { merge: true })

                reloadDataToken(false)(dispatch)
            }

            let providers = []

            for (let i = 0; i < res.user.providerData.length; i++) {
                providers = [...providers, res.user.providerData[i].providerId]
            }

            dispatch({
                type: AUTH_EXIT,
                payload: {
                    uid: res.user.uid,
                    email: res.user.email,
                    provider: providers,
                    modal: showModal,
                },
            })

            // Save in the browser
            localStorage.setItem(
                "auth",
                JSON.stringify({
                    uid: res.user.uid,
                    email: res.user.email,
                    provider: providers,
                    modal: showModal,
                })
            )
        } catch (error) {
            const errorCode = error.code.split("/")
            const errorMessage = ListFirebaseCustomError({
                code: errorCode[1],
            })

            // Save error
            openToast({
                content: errorMessage,
                type: ERROR,
            })

            if (
                errorMessage ===
                "An unexpected error occurred, please try again"
            ) {
                LogEvent("new_error", {
                    description: `L126 @ authDucks.js | ${error.code} - ${error.message}`,
                })
            } else {
                LogEvent("new_error", {
                    description: `L126 @ authDucks.js | ${errorMessage}`,
                })
            }

            dispatch({
                type: AUTH_ERROR,
            })
        }
    }

export const singUpWithEmailPassword =
    ({ email, pass, from, country, hasPassword = true }) =>
    async(dispatch) => {
        // Init loading to register or login user
        dispatch({
            type: LOADING_AUTH,
        })

        try {
            // Create new user
            const res = await auth.createUserWithEmailAndPassword(email, pass)

            // Validate country
            if (!country) {
                country = "NaN"
            }

            // Save user structure
            createUserStructure({
                uid: res.user.uid,
                email: res.user.email,
                country: country,
                from: from,
                hasPassword,
            })(dispatch)

            let providers = []

            for (let i = 0; i < res.user.providerData.length; i++) {
                providers = [...providers, res.user.providerData[i].providerId]
            }

            LogEvent("sign_up", {
                method: "email",
                resolution: `${window.screen.width} x ${window.screen.height}`,
                prices_type: 6,
                new_user_id: res.user.uid,
                email: res.user.email,
                from: from,
                country,
            })

            dispatch({
                type: AUTH_EXIT,
                payload: {
                    uid: res.user.uid,
                    email: res.user.email,
                    provider: providers,
                    modal: true,
                },
            })

            // Save in the browser
            localStorage.setItem(
                "auth",
                JSON.stringify({
                    uid: res.user.uid,
                    email: res.user.email,
                    provider: providers,
                    modal: true,
                })
            )
        } catch (error) {
            const errorCode = error.code.split("/")
            const errorMessage = ListFirebaseCustomError({
                code: errorCode[1],
            })

            if (errorMessage === i18n.t("o-email-already-in-use")) {
                const elem = document.getElementById("modal-sign-in")
                var instance = M.Modal.getInstance(elem)
                instance.open()
            } else {
                // Save error
                openToast({
                    content: errorMessage,
                    type: ERROR,
                    time: 6000,
                })
            }

            if (
                errorMessage ===
                "An unexpected error occurred, please try again"
            ) {
                LogEvent("new_error", {
                    description: `L180 @ authDucks.js | ${error.code} - ${error.message}`,
                })
            } else {
                LogEvent("new_error", {
                    description: `L180 @ authDucks.js | ${errorMessage}`,
                })
            }

            dispatch({
                type: AUTH_ERROR,
            })
        }
    }

export const loginWithEmailPassword = (email, pass) => async(dispatch) => {
    // Init loading to register or login user
    dispatch({
        type: LOADING_AUTH,
    })

    try {
        const res = await auth.signInWithEmailAndPassword(email, pass)

        let providers = []

        // Get current tokenID
        const idToken = await auth.currentUser.getIdToken(
                /* forceRefresh */
                false
            )
            // Save current tokenID
        await db.collection("users").doc(res.user.uid).set({
            idToken,
            reloadData: false,
            reloadDataToken: false,
        }, { merge: true })

        for (let i = 0; i < res.user.providerData.length; i++) {
            providers = [...providers, res.user.providerData[i].providerId]
        }

        reloadDataToken(false)(dispatch)

        LogEvent("log_in", {
            method: "email",
            new_user_id: res.user.uid,
            email: res.user.email,
        })

        dispatch({
            type: AUTH_EXIT,
            payload: {
                uid: res.user.uid,
                email: res.user.email,
                provider: providers,
                modal: false,
            },
        })

        // Save in the browser
        localStorage.setItem(
            "auth",
            JSON.stringify({
                uid: res.user.uid,
                email: res.user.email,
                provider: providers,
                modal: false,
            })
        )
    } catch (error) {
        const errorCode = error.code.split("/")
        const errorMessage = ListFirebaseCustomError({ code: errorCode[1] })

        if (errorMessage === i18n.t("o-user-not-found")) {
            const elem = document.getElementById("modal-create-account")
            var instance = M.Modal.getInstance(elem)
            instance.open()
        } else {
            // Save error
            openToast({
                content: errorMessage,
                type: ERROR,
                time: 6000,
            })
        }

        if (errorMessage === "An unexpected error occurred, please try again") {
            LogEvent("new_error", {
                description: `L228 @ authDucks.js | ${error.code} - ${error.message}`,
            })
        } else {
            LogEvent("new_error", {
                description: `L228 @ authDucks.js | ${errorMessage}`,
            })
        }

        dispatch({
            type: AUTH_ERROR,
        })
    }
}

export const getActiveAuth = () => async(dispatch) => {
    if (localStorage.getItem("auth")) {
        dispatch({
            type: AUTH_EXIT,
            payload: JSON.parse(localStorage.getItem("auth")),
        })
    }
}

export const updatePassword =
    ({ data = {} }) =>
    async(dispatch, getState) => {
        // Init loading
        dispatch({
            type: LOADING_AUTH,
        })

        if (localStorage.getItem("auth")) {
            try {
                const user = auth.currentUser
                const email = JSON.parse(localStorage.getItem("auth")).email
                const pass = data.currentPassword

                const provider = JSON.parse(
                    localStorage.getItem("auth")
                ).provider

                const existPassword = provider.includes("password")

                // Validate if the password it's equals
                if (data.newPassword === data.confirmNewPassword) {
                    if (!existPassword) {
                        // Link with email and pass provider
                        try {
                            // Sign in user
                            const provider =
                                new firebase.auth.GoogleAuthProvider()
                            await auth.signInWithPopup(provider)

                            // Link google provider with password provider
                            await user.linkWithCredential(
                                firebase.auth.EmailAuthProvider.credential(
                                    email,
                                    data.newPassword
                                )
                            )

                            const updateUser = auth.currentUser

                            // Update providers
                            let providers = []

                            for (
                                let i = 0; i < updateUser.providerData.length; i++
                            ) {
                                providers = [
                                    ...providers,
                                    updateUser.providerData[i].providerId,
                                ]
                            }

                            dispatch({
                                type: AUTH_EXIT,
                                payload: {
                                    uid: updateUser.uid,
                                    email: updateUser.email,
                                    provider: providers,
                                },
                            })

                            openToast({
                                content: i18n.t("toastSuccessPassUpdated"),
                                type: SUCCESS,
                            })

                            LogEvent("toast", {
                                description: `L296 @ authDucks.jsx | Your new password was updated`,
                            })

                            // Save in the browser
                            localStorage.setItem(
                                "auth",
                                JSON.stringify({
                                    uid: updateUser.uid,
                                    email: updateUser.email,
                                    provider: providers,
                                })
                            )
                        } catch (error) {
                            // Show toast unexpected error reload
                            document
                                .getElementById("toast-unexpected-error")
                                .classList.remove("hide")
                                // Save analytics
                            LogEvent("new_error", {
                                description: `L309 @ authDucks.js | ${error.code} - ${error.message}`,
                            })

                            dispatch({
                                type: UPDATE_PASSWORD_ERROR,
                            })
                        }
                    } else {
                        // Sign in user
                        await auth.signInWithEmailAndPassword(email, pass)

                        if (data.newPassword === data.confirmNewPassword) {
                            const newPassword = data.newPassword

                            await user
                                .updatePassword(newPassword)
                                .then(function() {
                                    // Sign in user
                                    auth.signInWithEmailAndPassword(
                                        email,
                                        newPassword
                                    )

                                    dispatch({
                                        type: UPDATE_PASSWORD_EXIT,
                                    })
                                })

                            openToast({
                                content: i18n.t("toastSuccessPassUpdated"),
                                type: SUCCESS,
                            })

                            LogEvent("toast", {
                                description: `L332 @ authDucks.jsx | Your new password was updated`,
                            })
                        }
                    }
                } else {
                    openToast({
                        content: i18n.t("toastErrorPassNotMatch"),
                        type: ERROR,
                    })

                    LogEvent("toast", {
                        description: `L341 @ authDucks.jsx | Your new password doesn’t match the password confirmation`,
                    })

                    dispatch({
                        type: UPDATE_PASSWORD_ERROR,
                    })
                }
            } catch (error) {
                const errorCode = error.code.split("/")
                const errorMessage = ListFirebaseCustomError({
                    code: errorCode[1],
                })

                // Save error
                openToast({
                    content: errorMessage,
                    type: ERROR,
                    time: 6000,
                })

                if (
                    errorMessage ===
                    "An unexpected error occurred, please try again"
                ) {
                    LogEvent("new_error", {
                        description: `L351 @ authDucks.js | ${error.code} - ${error.message}`,
                    })
                } else {
                    LogEvent("new_error", {
                        description: `L351 @ authDucks.js | ${errorMessage}`,
                    })
                }

                dispatch({
                    type: UPDATE_PASSWORD_ERROR,
                })
            }
        }
    }

export const resetPassword =
    ({ email = "" }) =>
    async(dispatch, getState) => {
        // Init loading
        dispatch({
            type: LOADING_AUTH,
        })

        auth.sendPasswordResetEmail(email)
            .then(function() {
                openToast({
                    content: i18n.t("toastSuccessLinkPassSent"),
                    type: SUCCESS,
                })

                updateHasPasswordUser({
                    data: {
                        hasPassword: true,
                    },
                })(dispatch, getState)

                LogEvent("toast", {
                    description: `L371 @ authDucks.jsx | A link to reset your password was sent to your email`,
                })

                LogEvent("forgot_password", {
                    email: email,
                })

                dispatch({
                    type: REST_PASSWORD_EXIT,
                })
            })
            .catch(function(error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                    // Save analytics
                LogEvent("new_error", {
                    description: `L384 @ authDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: REST_PASSWORD_ERROR,
                })
            })
    }

export const updateEmail =
    ({
        newEmail = "",
        password = null,
        isQrAction = false,
        qrAction = () => {},
    }) =>
    async(dispatch, getState) => {
        // Init loading
        dispatch({
            type: LOADING_AUTH,
        })

        if (localStorage.getItem("auth")) {
            // Get user's current email
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            const providers = JSON.parse(localStorage.getItem("auth")).provider
            const currentEmail = JSON.parse(localStorage.getItem("auth")).email

            if (currentEmail === null) {
                try {
                    if (!password) {
                        // open modal
                        dispatch({
                            type: UPDATE_EMAIL_EXIT,
                            payload: {
                                newEmail: newEmail,
                            },
                        })

                        const elem = document.getElementById("modal-password")
                        const instance = M.Modal.getInstance(elem)
                        instance.open()
                    } else {
                        const email = newEmail ?
                            newEmail :
                            getState().auth.newEmail
                        const credential =
                            firebase.auth.EmailAuthProvider.credential(
                                email,
                                password
                            )

                        await auth.currentUser.linkWithCredential(credential)

                        openToast({
                            content: i18n.t("toastSuccessEmailUpdated"),
                            type: SUCCESS,
                        })

                        LogEvent("toast", {
                            description: `L428 @ authDucks.jsx | Your email was updated`,
                        })

                        // open modal to download QR code
                        if (isQrAction) {
                            qrAction()
                        }

                        // Update feedback user
                        const feedbackUsers = await db
                            .collection("feedbackUsers")
                            .where("userId", "==", uid)
                            .get()
                        for (let i in feedbackUsers.docs) {
                            const feedbackUser = feedbackUsers.docs[i]
                            await db
                                .collection("feedbackUsers")
                                .doc(feedbackUser.id)
                                .update({
                                    emailSuperMenu: email,
                                })
                        }

                        // Data from user
                        const { firstName, lastName, phone } =
                        getState().user.data

                        // Update user redux
                        await updateDataUser({
                            data: {
                                firstName: firstName,
                                lastName: lastName,
                                email: email,
                                phone: phone,
                            },
                            newEmail: email,
                        })(dispatch)

                        // Save analytic anonymous conversion
                        LogEvent("anonymous_conversion", {
                            from: getState().user.data.from,
                            country: getState().user.data.country,
                        })

                        // Save in the browser
                        localStorage.setItem(
                            "auth",
                            JSON.stringify({
                                uid: uid,
                                email: email,
                                provider: ["password"],
                            })
                        )

                        // Update auth redux
                        dispatch({
                            type: UPDATE_EMAIL_EXIT,
                            payload: {
                                email: email,
                            },
                        })
                    }
                    return
                } catch (error) {
                    const errorCode = error.code.split("/")
                    const errorMessage = ListFirebaseCustomError({
                        code: errorCode[1],
                    })

                    // Save error
                    openToast({
                        content: errorMessage,
                        type: ERROR,
                        time: 6000,
                    })

                    if (
                        errorMessage ===
                        "An unexpected error occurred, please try again"
                    ) {
                        LogEvent("new_error", {
                            description: `L482 @ authDucks.js | ${error.code} - ${error.message}`,
                        })
                    } else {
                        LogEvent("new_error", {
                            description: `L482 @ authDucks.js | ${errorMessage}`,
                        })
                    }

                    dispatch({
                        type: UPDATE_EMAIL_ERROR,
                    })
                    return
                }
            }

            if (newEmail !== currentEmail) {
                const user = auth.currentUser

                try {
                    // Get type providers
                    const provider = JSON.parse(
                        localStorage.getItem("auth")
                    ).provider
                    const existPassword = provider.includes("password")

                    // Data from user
                    const { firstName, lastName, phone } = getState().user.data
                    if (!existPassword) {
                        if (!password) {
                            // Sign in user
                            const provider =
                                new firebase.auth.GoogleAuthProvider()
                            await auth.signInWithPopup(provider)

                            // open modal
                            dispatch({
                                type: UPDATE_EMAIL_EXIT,
                                payload: {
                                    newEmail: newEmail,
                                },
                            })

                            const elem =
                                document.getElementById("modal-password")
                            const instance = M.Modal.getInstance(elem)
                            instance.open()
                        } else {
                            const email = newEmail ?
                                newEmail :
                                getState().auth.newEmail

                            const credential =
                                firebase.auth.EmailAuthProvider.credential(
                                    email,
                                    password
                                )

                            await auth.currentUser.linkWithCredential(
                                credential
                            )

                            openToast({
                                content: i18n.t("toastSuccessEmailUpdated"),
                                type: SUCCESS,
                            })

                            LogEvent("toast", {
                                description: `L511 @ authDucks.jsx | Your email was updated`,
                            })

                            // Update feedback user
                            const feedbackUsers = await db
                                .collection("feedbackUsers")
                                .where("userId", "==", uid)
                                .get()
                            for (let i in feedbackUsers.docs) {
                                const feedbackUser = feedbackUsers.docs[i]
                                await db
                                    .collection("feedbackUsers")
                                    .doc(feedbackUser.id)
                                    .update({
                                        emailSuperMenu: email,
                                    })
                            }

                            // Update user redux
                            await updateDataUser({
                                data: {
                                    firstName: firstName,
                                    lastName: lastName,
                                    email: email,
                                    phone: phone,
                                },
                                newEmail: email,
                            })(dispatch)

                            // Save in the browser
                            localStorage.setItem(
                                "auth",
                                JSON.stringify({
                                    uid: uid,
                                    email: email,
                                    provider: ["google.com", "password"],
                                })
                            )

                            // Update auth redux
                            dispatch({
                                type: UPDATE_EMAIL_EXIT,
                                payload: {
                                    email: email,
                                },
                            })
                        }
                    } else {
                        if (password) {
                            // Sign in user
                            await auth.signInWithEmailAndPassword(
                                currentEmail,
                                password
                            )
                            const { newEmail } = getState().auth

                            user.updateEmail(newEmail)
                                .then(async function() {
                                    openToast({
                                        content: i18n.t(
                                            "toastSuccessEmailUpdated"
                                        ),
                                        type: SUCCESS,
                                    })

                                    LogEvent("toast", {
                                        description: `L559 @ authDucks.jsx | Your email was updated`,
                                    })

                                    // Update feedback user
                                    const feedbackUsers = await db
                                        .collection("feedbackUsers")
                                        .where("userId", "==", uid)
                                        .get()
                                    for (let i in feedbackUsers.docs) {
                                        const feedbackUser =
                                            feedbackUsers.docs[i]
                                        await db
                                            .collection("feedbackUsers")
                                            .doc(feedbackUser.id)
                                            .update({
                                                emailSuperMenu: newEmail,
                                            })
                                    }

                                    // Update user redux
                                    await updateDataUser({
                                        data: {
                                            firstName: firstName,
                                            lastName: lastName,
                                            email: newEmail,
                                            phone: phone,
                                        },
                                        newEmail: newEmail,
                                    })(dispatch)

                                    // Save in the browser
                                    localStorage.setItem(
                                        "auth",
                                        JSON.stringify({
                                            uid: uid,
                                            email: newEmail,
                                            provider: [...providers],
                                        })
                                    )

                                    // Update auth redux
                                    dispatch({
                                        type: UPDATE_EMAIL_EXIT,
                                        payload: {
                                            email: newEmail,
                                        },
                                    })
                                })
                                .catch(function(error) {
                                    const errorCode = error.code.split("/")
                                    const errorMessage =
                                        ListFirebaseCustomError({
                                            code: errorCode[1],
                                        })

                                    // Save error
                                    openToast({
                                        content: errorMessage,
                                        type: ERROR,
                                        time: 6000,
                                    })

                                    if (
                                        errorMessage ===
                                        "An unexpected error occurred, please try again"
                                    ) {
                                        LogEvent("new_error", {
                                            description: `L591 @ authDucks.js | ${error.code} - ${error.message}`,
                                        })
                                    } else {
                                        LogEvent("new_error", {
                                            description: `L591 @ authDucks.js | ${errorMessage}`,
                                        })
                                    }

                                    dispatch({
                                        type: UPDATE_EMAIL_ERROR,
                                    })
                                })
                        } else {
                            // open modal
                            dispatch({
                                type: UPDATE_EMAIL_EXIT,
                                payload: {
                                    newEmail: newEmail,
                                },
                            })

                            const elem =
                                document.getElementById("modal-password")
                            const instance = M.Modal.getInstance(elem)
                            instance.open()
                        }
                    }
                } catch (error) {
                    const errorCode = error.code ? error.code.split("/") : ""
                    const errorMessage = ListFirebaseCustomError({
                        code: errorCode[1],
                    })

                    // Save error
                    openToast({
                        content: errorMessage,
                        type: ERROR,
                        time: 6000,
                    })

                    if (
                        errorMessage ===
                        "An unexpected error occurred, please try again"
                    ) {
                        LogEvent("new_error", {
                            description: `L616 @ authDucks.js | ${error.code} - ${error.message}`,
                        })
                    } else {
                        LogEvent("new_error", {
                            description: `L616 @ authDucks.js | ${errorMessage}`,
                        })
                    }

                    dispatch({
                        type: UPDATE_EMAIL_ERROR,
                    })
                }
            } else {
                // Init loading
                dispatch({
                    type: UPDATE_EMAIL_EXIT,
                    payload: {
                        email: currentEmail,
                    },
                })
            }
        }
    }

export const updateModal = () => (dispatch) => {
    dispatch({
        type: UPDATE_MODAL,
    })

    // Save in the browser
    localStorage.setItem(
        "auth",
        JSON.stringify({
            ...JSON.parse(localStorage.getItem("auth")),
            modal: false,
        })
    )
}

export const logout = () => (dispatch) => {
    auth.signOut()

    // Delete all info of the browser
    localStorage.clear()

    // Restart store
    restartDataUser()(dispatch)
    restartDataVenue()(dispatch)
    restartDataQrFlow()(dispatch)
    restartDataStripe()(dispatch)

    dispatch({
        type: LOGOUT,
    })
}

export const reload = () => () => {
    if (localStorage.getItem("auth")) {
        const uid = JSON.parse(localStorage.getItem("auth")).uid

        try {
            db.collection("users")
                .doc(uid)
                .onSnapshot(async(snapshot) => {
                    if (snapshot.exists) {
                        if (snapshot.data().reloadData) {
                            // Set to false
                            await db.collection("users").doc(uid).update({
                                    reloadData: false,
                                })
                                // Delete all info of the browser
                            localStorage.removeItem("user")
                            localStorage.removeItem("venue")
                            localStorage.removeItem("qrFlow")
                            localStorage.removeItem("stripe")
                                // Reload page
                            window.location.reload()
                        }

                        // Validate if exist the idToken
                        if (
                            snapshot.data().idToken &&
                            snapshot.data().reloadDataToken
                        ) {
                            const lastTokenId = snapshot.data().idToken
                            auth.currentUser
                                .getIdToken(false)
                                .then(async(currentTokenId) => {
                                    if (lastTokenId !== currentTokenId) {
                                        // Set to false
                                        await db
                                            .collection("users")
                                            .doc(uid)
                                            .update({
                                                reloadDataToken: false,
                                            })

                                        // Delete all info of the browser
                                        localStorage.removeItem("user")
                                        localStorage.removeItem("qrFlow")
                                            // Reload page
                                        window.location.reload()
                                    }
                                })
                        }
                    }
                })
        } catch (error) {}
    }
}

export const singUpAnonymously =
    ({ country }) =>
    async(dispatch) => {
        // Init loading to register or login user
        dispatch({
            type: LOADING_AUTH,
        })

        try {
            const res = await firebase.auth().signInAnonymously()
            let providers = []

            for (let i = 0; i < res.user.providerData.length; i++) {
                providers = [...providers, res.user.providerData[i].providerId]
            }

            // Validate country
            if (!country) {
                country = "NaN"
            }

            // Save user structure
            createUserStructure({
                uid: res.user.uid,
                email: res.user.email,
                country: country,
                from: "direct-signup",
                hasPassword: true,
            })(dispatch)

            LogEvent("sign_up", {
                method: "anonymous",
                resolution: `${window.screen.width} x ${window.screen.height}`,
                prices_type: 6,
                new_user_id: res.user.uid,
                email: null,
                from: "direct-signup",
                country,
            })

            dispatch({
                type: AUTH_EXIT,
                payload: {
                    uid: res.user.uid,
                    email: res.user.email,
                    provider: providers,
                },
            })

            // Save in the browser
            localStorage.setItem(
                "auth",
                JSON.stringify({
                    uid: res.user.uid,
                    email: res.user.email,
                    provider: providers,
                })
            )
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")
                // Save analytics
            LogEvent("new_error", {
                description: `L706 @ authDucks.js | ${error.code} - ${error.message}`,
            })

            dispatch({
                type: AUTH_ERROR,
            })
        }
    }