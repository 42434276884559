import React from "react"

/* Styles */
import "./style.scss"

/* Components */
import ShowMenu from "components/showMenu/ShowMenu"
import CustomUrlScan from "components/customUrlScan/CustomUrlScan"

/* Router */
import { withRouter } from "react-router-dom"

/* Redux */
import { useSelector, useDispatch } from "react-redux"
import { getMenuData } from "redux/scanDucks"

/* i18n */
import { useTranslation } from "react-i18next"

/* SEO */
import { Helmet } from "react-helmet-async"

const CustomScan = ({ history }) => {
  // Const to translation
  const { t } = useTranslation()

  // Const to redux
  const dispatch = useDispatch()

  // Redux states
  const menuLoading = useSelector((store) => store.scan.loading)
  const { existMenu } = useSelector((store) => store.scan.data)
  const { user, venue, qrFlow } = useSelector((store) => store.scan.userData)

  // Load data
  React.useEffect(() => {
    const fetchMenu = () => {
      if ((user, venue, qrFlow)) {
        dispatch(getMenuData({ user: user, venue: venue, qrFlow: qrFlow }))
      }
    }

    fetchMenu()
  }, [user, venue, qrFlow, dispatch])

  React.useEffect(() => {
    if (existMenu === false) {
      history.push({
        pathname: "/menu-not-found",
        state: { user, venue, qrFlow },
      })
    }
  }, [existMenu, history, user, venue, qrFlow])

  return (
    <div className="o-scan-container">
      <Helmet>
        <title>{t("siteTitleScan")}</title>
        <meta name="description" content={t("siteDescriptionScan")} />
        <meta name="keywords" content={t("siteKeyWordsScan")} />
        {/* hreflang */}
        <link rel="alternate" hreflang="en" href="https://myfreeqr.com/scan" />
        <link rel="alternate" hreflang="es" href="https://myfreeqr.com/scan" />
        <link
          rel="alternate"
          hreflang="x-default"
          href="https://myfreeqr.com/scan"
        />
      </Helmet>

      <CustomUrlScan />

      {menuLoading !== true && existMenu && (
        <ShowMenu
          venue={venue}
          user={user}
          qrFlow={qrFlow}
          from={"Custom URL"}
        />
      )}
    </div>
  )
}

export default withRouter(CustomScan)
