import React from "react"

/* Styles */
import "./style.scss"

/* Components */
import { Col, Row } from "react-materialize"

const GoogleAds = ({ style, slot = "7928311853" }) => {
  React.useEffect(() => {
    const installGoogleAds = () => {
      const elem = document.createElement("script")
      elem.src = "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      elem.async = true
      elem.defer = true
      document.body.insertBefore(elem, document.body.firstChild)
    }
    installGoogleAds()
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return (
    <div id="google-ad">
      <Row>
        <Col s={12} m={12}>
          <ins
            className="adsbygoogle"
            style={style}
            data-ad-client="ca-pub-6856190324693341"
            data-ad-slot={slot}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </Col>
      </Row>
    </div>
  )
}

export default GoogleAds
