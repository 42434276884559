export function checkImageExist(url) {
    return new Promise((resolve) => {
        var request = new XMLHttpRequest()
        request.open("GET", url, true)
        request.send()
        request.onload = function() {
            if (request.status === 404) {
                resolve(false)
            } else {
                resolve(true)
            }
        }
    })
}