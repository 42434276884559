import React, { Suspense } from "react"

/* Style */
import "./style.scss"

/* Components */
import Loading from "components/desktop/loading/Loading"

/* Hook */
import useNearScreen from "hooks/useNearScreen"

/* Lazy images */
const ListImages = React.lazy(() => import("./ListImages"))

export default function LazyImages({ images, setIndexImages, isFirst }) {
  // Lazy states
  const { isNearScreen, fromRef } = useNearScreen({ distance: "2000px" })

  // Component state
  const [imageLoaded, setImagesLoaded] = React.useState(0)

  React.useEffect(() => {
    // Update if the group of images was rendered
    if (images.length === imageLoaded) {
      setIndexImages(1)
    }
  }, [imageLoaded, images])

  // When the observe detect the element is close show that element
  // Save the reference of div in the state
  return (
    <div ref={fromRef}>
      <Suspense
        fallback={
          <div className={isFirst ? "" : "o-load-height"}>
            <Loading mobile={false} />
          </div>
        }
      >
        {isNearScreen ? (
          // Show images only if all are loaded in the DOM
          <>
            <div className={images.length === imageLoaded ? "" : "hide"}>
              <ListImages
                images={images}
                updateImages={(value) => {
                  // Update the number of images rendered
                  setImagesLoaded(imageLoaded + value)
                }}
              />
            </div>
            <div
              className={
                images.length === imageLoaded
                  ? "hide"
                  : isFirst
                  ? ""
                  : "o-load-height"
              }
            >
              <Loading mobile={false} />
            </div>
          </>
        ) : (
          <div className={isFirst ? "" : "o-load-height"}>
            <Loading mobile={false} />
          </div>
        )}
      </Suspense>
    </div>
  )
}
