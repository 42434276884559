import { CreateRandomId } from "./CreateRandomId"
import { db } from "../controller/firebase"
import { LogEvent } from "./LogEvents"

export function CreateCustomUrl({
    userId = "",
    venueId = "",
    qrFlowId = "",
    customUrl = null,
}) {
    return new Promise(async(resolve) => {
        const createUrl = async(id) => {
            return new Promise(async(resolve, reject) => {
                await db
                    .collection("customUrls")
                    .doc(id)
                    .set({
                        uid: userId,
                        url: `${window.origin}/scan/${userId}/${venueId}/${qrFlowId}`,
                        customName: id,
                    })
                    .catch((error) => {
                        if (
                            error.message ===
                            "The caller does not have permission"
                        ) {
                            resolve("Error")
                        } else {
                            reject(error)
                        }
                    })
                resolve("Exit")
            })
        }

        try {
            if (customUrl) {
                // Validate if the user send a custom url
                const create = await createUrl(customUrl)
                resolve(create)
            } else {
                // Create random ID to the image
                let randomID = CreateRandomId(5)
                let create = await createUrl(randomID)

                // Create until the id don't exist
                while (create === "Error") {
                    randomID = CreateRandomId(5)
                    create = await createUrl(randomID)
                }
                resolve(randomID)
            }
        } catch (error) {
            LogEvent("new_error", {
                description: `L51 @ CreateCustomUrl.js | ${error.code} - ${error.message}`,
            })
            resolve("Error")
        }
    })
}