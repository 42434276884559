/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

/* Firebase */
import { db } from "../controller/firebase"

/* Components */
import { openToast, ERROR, SUCCESS } from "components/toast/Toast"

/* Helpers */
import { LogEvent } from "helpers/LogEvents"
import { DiffInHours } from "helpers/GetDifferenceBetweenDates"
import { ListFirebaseCustomError } from "helpers/ListFirebaseCustomErrors"

/* Translation */
import i18n from "i18next"

/* Constants or States */
const initData = {
    loading: true,
    loadingCustomers: false,
    data: {
        flowSequence: null,
        timeToHideAd: -2,
        existMenu: null,
        showAds: null,
    },
    userData: {},
    timeCreated: null,
    dateCreated: null,
}

const LOADING_MENU = "LOADING_MENU"
const LOADING_CUSTOMERS_SCAN = "LOADING_CUSTOMERS_SCAN"

const GET_MENU_EXIT = "GET_MENU_EXIT"
const GET_MENU_ERROR = "GET_MENU_ERROR"

const ADD_CUSTOMER_EXIT = "ADD_CUSTOMER_EXIT"
const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR"

const DISABLE_CUSTOMER_EXIT = "DISABLE_CUSTOMER_EXIT"
const DISABLE_CUSTOMER_ERROR = "DISABLE_CUSTOMER_ERROR"

const GET_TIME_CREATED_EXIT = "GET_TIME_CREATED_EXIT"
const GET_TIME_CREATED_ERROR = "GET_TIME_CREATED_ERROR"

const GET_URL_REAL_EXIT = "GET_URL_REAL_EXIT"
const GET_URL_REAL_ERROR = "GET_URL_REAL_ERROR"

const UPDATE_TIME_CREATED = "UPDATE_TIME_CREATED"

/* Reducer (Save call API in constant) */
export default function scanReducer(state = initData, action) {
    switch (action.type) {
        case LOADING_MENU:
            return {...state, loading: true }
        case LOADING_CUSTOMERS_SCAN:
            return { loadingCustomers: true }
        case GET_MENU_EXIT:
            return {
                ...state,
                data: {...action.payload.menu },
                userData: {...action.payload.user },
                loading: false,
            }
        case GET_MENU_ERROR:
            return {...initData }
        case ADD_CUSTOMER_EXIT:
            return {
                ...state,
                timeCreated: action.payload.diffTime,
                dateCreated: action.payload.dateCreated,
                loading: false,
            }
        case ADD_CUSTOMER_ERROR:
            return {...initData }
        case GET_TIME_CREATED_EXIT:
            return {
                ...state,
                timeCreated: action.payload.timeCreated,
                dateCreated: action.payload.dateCreated,
            }
        case GET_TIME_CREATED_ERROR:
            return {...state }
        case UPDATE_TIME_CREATED:
            return {
                ...state,
                timeCreated: action.payload.diffTime,
                dateCreated: action.payload.dateCreated,
                loading: false,
            }
        case DISABLE_CUSTOMER_EXIT:
            return { loadingCustomers: false }
        case DISABLE_CUSTOMER_ERROR:
            return { loadingCustomers: false }
        case GET_URL_REAL_EXIT:
            return {
                ...state,
                userData: {...action.payload.user },
                data: {...state.data, ...action.payload.menu },
            }
        case GET_URL_REAL_ERROR:
            return {...state }
        default:
            return {...state }
    }
}

/* Actions (Calls API) */
export const getMenuData =
    ({ user = "", venue = "", qrFlow = "" }) =>
    async(dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_MENU,
        })

        let time = 5
        const timer = setInterval(() => {
            time--
            console.log(time)
            if (time < 0) {
                LogEvent("slow_network", {
                    new_user_id: user,
                    venue_id: venue,
                    qr_flow_id: qrFlow,
                    description: "firebase wait more than 5 secs in scan page",
                })
                clearInterval(timer)
            }
        }, 1000)

        try {
            const p1 = new Promise((resolve) => {
                db.collection("users")
                    .doc(user)
                    .collection("venues")
                    .doc(venue)
                    .collection("qrFlows")
                    .doc(qrFlow)
                    .get()
                    .then((res) => {
                        resolve(res)
                    })
            })
            const p2 = new Promise((resolve) => {
                db.collection("users")
                    .doc(user)
                    .collection("venues")
                    .doc(venue)
                    .get()
                    .then((res) => {
                        resolve(res)
                    })
            })

            Promise.all([p1, p2]).then((values) => {
                const res = values[0]
                const resVenue = values[1]

                // Validate if the menu exist
                let existMenu = res.exists
                let existVenue = resVenue.exists

                if (!existMenu || !existVenue) {
                    dispatch({
                        type: GET_MENU_EXIT,
                        payload: {
                            menu: {
                                ...res.data(),
                                existMenu,
                            },
                        },
                    })

                    clearInterval(timer)

                    return
                }

                // Validate prices type
                let prices = 6
                if (resVenue.data().pricesType) {
                    prices = resVenue.data().pricesType
                }

                // Validate show Ads
                let showAds = true
                let timeToHideAd = -2

                if (
                    resVenue.data().showAds &&
                    resVenue.data().showAds.offUntil &&
                    resVenue.data().showAds.version
                ) {
                    // Validate version
                    const version = resVenue.data().showAds.version

                    if (version === 4) {
                        // This version change the time of the Ad and show the 50% of scans
                        const offUntil = new Date(
                            resVenue.data().showAds.offUntil.toDate()
                        )
                        const currentDate = new Date()

                        if (currentDate < offUntil) {
                            // Show ads just the 50%
                            const showAd = Math.round(Math.random())

                            if (showAd === 1) {
                                showAds = false
                            }

                            timeToHideAd = -0.5
                        }
                    } else if (version === 5) {
                        // This version only change the time of the Ad
                        const offUntil = new Date(
                            resVenue.data().showAds.offUntil.toDate()
                        )
                        const currentDate = new Date()

                        if (currentDate < offUntil) {
                            timeToHideAd = -0.5
                        }
                    } else {
                        // This version remove the Ad
                        const offUntil = new Date(
                            resVenue.data().showAds.offUntil.toDate()
                        )
                        const currentDate = new Date()

                        if (currentDate < offUntil) {
                            showAds = false
                        }
                    }
                }

                if (showAds && res.data().showAds) {
                    if (res.data().showAds.offUntil) {
                        const offUntil = new Date(
                            res.data().showAds.offUntil.toDate()
                        )
                        const currentDate = new Date()

                        if (currentDate < offUntil) {
                            showAds = false
                        }
                    }
                }

                dispatch({
                    type: GET_MENU_EXIT,
                    payload: {
                        menu: {
                            ...res.data(),
                            existMenu,
                            payNow: resVenue.data().payNow,
                            versionAd: resVenue.data().showAds.version,
                            pricesType: prices,
                            showAds,
                            timeToHideAd,
                        },
                        user: {
                            user,
                            venue,
                            qrFlow: qrFlow,
                        },
                    },
                })

                clearInterval(timer)
            })
        } catch (error) {
            const errorCode = error.code.split("/")
            const errorMessage = ListFirebaseCustomError({ code: errorCode[1] })

            // Save error
            openToast({
                content: { errorMessage },
                type: ERROR,
                time: 6000,
            })

            if (
                errorMessage ===
                "An unexpected error occurred, please try again"
            ) {
                LogEvent("new_error", {
                    description: `L145 @ scanDucks.js | ${error.code} - ${error.message}`,
                })
            } else {
                LogEvent("new_error", {
                    description: `L145 @ scanDucks.js | ${errorMessage}`,
                })
            }

            dispatch({
                type: GET_MENU_ERROR,
            })

            clearInterval(timer)
        }
    }

export const addCustomer =
    ({ data = [], user = "", venue = "", qrFlow = "" }) =>
    async(dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_MENU,
        })

        try {
            // Validate if the user exist
            const res = await db
                .collection("users")
                .doc(user)
                .collection("venues")
                .doc(venue)
                .collection("customers")
                .where("emailAddress", "==", data.emailAddress)
                .get()
                // Id if exist
            let idCustomer = null
            let dateCreated = new Date()
            let diffInHours = null

            res.forEach((customer) => {
                idCustomer = customer.id
                    // Validate if exist the user
                if (idCustomer) {
                    dateCreated = new Date(customer.data().created.toDate())
                }
                return
            })

            const newData = []

            let hasFistName = true
            let hasLastName = true
            let hasEmail = true
            let hasPhone = true
            let hasBirthday = true

            // Validate which fields exist
            if (!data.firstName.trim()) {
                hasFistName = false
            } else {
                newData.firstName = data.firstName
            }
            if (!data.lastName.trim()) {
                hasLastName = false
            } else {
                newData.lastName = data.lastName
            }
            if (!data.emailAddress.trim()) {
                hasEmail = false
            } else {
                newData.emailAddress = data.emailAddress
            }
            if (!data.phoneNumber.trim()) {
                hasPhone = false
            } else {
                newData.phoneNumber = data.phoneNumber
            }
            if (!data.birthday.trim()) {
                hasBirthday = false
            } else {
                newData.birthday = data.birthday
            }

            const { pricesType } = getState().scan.data

            if (idCustomer && hasEmail) {
                LogEvent("subscribe", {
                    has_first_name: hasFistName,
                    has_last_name: hasLastName,
                    has_email: hasEmail,
                    has_phone: hasPhone,
                    has_birthday: hasBirthday,
                    is_new: false,
                    email: data.emailAddress,
                    venue_id: venue,
                    new_user_id: user,
                    qr_flow_id: qrFlow,
                    prices_type: pricesType,
                    description: `${hasFistName} - ${hasLastName} - ${hasEmail} - ${hasPhone} - ${hasBirthday} - ${
                        data.emailAddress
                    } - ${venue} - ${false} - ${pricesType}`,
                })

                await db
                    .collection("users")
                    .doc(user)
                    .collection("venues")
                    .doc(venue)
                    .collection("customers")
                    .doc(idCustomer)
                    .update({
                        ...newData,
                        activeEmail: "Subscribed",
                        activeSMS: "Subscribed",
                    })
            } else {
                LogEvent("subscribe", {
                    has_first_name: hasFistName,
                    has_last_name: hasLastName,
                    has_email: hasEmail,
                    has_phone: hasPhone,
                    has_birthday: hasBirthday,
                    is_new: true,
                    email: data.emailAddress,
                    venue_id: venue,
                    new_user_id: user,
                    qr_flow_id: qrFlow,
                    prices_type: pricesType,
                    description: `${hasFistName} - ${hasLastName} - ${hasEmail} - ${hasPhone} - ${hasBirthday} - ${
                        data.emailAddress
                    } - ${venue} - ${true} - ${pricesType}`,
                })

                // Validate if some field exist
                if (
                    hasFistName ||
                    hasLastName ||
                    hasEmail ||
                    hasPhone ||
                    hasBirthday
                ) {
                    await db
                        .collection("users")
                        .doc(user)
                        .collection("venues")
                        .doc(venue)
                        .collection("customers")
                        .add({
                            ...data,
                            activeEmail: "Subscribed",
                            activeSMS: "Subscribed",
                            qrFlowId: qrFlow,
                            created: new Date(),
                        })
                }
            }

            // Get the time that the user have subscribed to the restaurant
            diffInHours = DiffInHours(new Date(), dateCreated)

            dispatch({
                type: ADD_CUSTOMER_EXIT,
                payload: {
                    diffTime: diffInHours,
                    dateCreated: dateCreated,
                },
            })

            // Save in the browser
            localStorage.setItem(
                "scan",
                JSON.stringify({
                    [user]: {
                        timeCreated: diffInHours,
                        dateCreated: dateCreated,
                    },
                })
            )
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")
                // Save analytics
            LogEvent("new_error", {
                description: `L317 @ scanDucks.js | ${error.code} - ${error.message}`,
            })

            dispatch({
                type: ADD_CUSTOMER_ERROR,
            })
        }
    }

export const getTimeCreated =
    ({ user = "" }) =>
    async(dispatch, getState) => {
        // Validate if the data exist in the browser
        if (localStorage.getItem("scan")) {
            if (JSON.parse(localStorage.getItem("scan"))[user]) {
                dispatch({
                    type: GET_TIME_CREATED_EXIT,
                    payload: {
                        timeCreated: JSON.parse(localStorage.getItem("scan"))[
                            user
                        ].timeCreated,
                        dateCreated: new Date(
                            JSON.parse(localStorage.getItem("scan"))[
                                user
                            ].dateCreated
                        ),
                    },
                })
                return
            }
        }

        dispatch({
            type: GET_TIME_CREATED_ERROR,
        })
    }

export const updateTimeCreated =
    ({ user = "", diffInHours = null, dateCreated = null }) =>
    (dispatch, getState) => {
        dispatch({
            type: UPDATE_TIME_CREATED,
            payload: {
                diffTime: diffInHours,
                dateCreated: dateCreated,
            },
        })

        // Save in the browser
        localStorage.setItem(
            "scan",
            JSON.stringify({
                [user]: {
                    timeCreated: diffInHours,
                    dateCreated: dateCreated,
                },
            })
        )
    }

export const disableCustomer =
    ({ user = "", venue = "", email = "" }) =>
    async(dispatch, getState) => {
        dispatch({
            type: LOADING_CUSTOMERS_SCAN,
        })

        try {
            const res = await db
                .collection("users")
                .doc(user)
                .collection("venues")
                .doc(venue)
                .collection("customers")
                .where("emailAddress", "==", email)
                .get()
            const resVenue = await db
                .collection("users")
                .doc(user)
                .collection("venues")
                .doc(venue)
                .get()

            // Validate prices type
            let prices = 1
            if (resVenue.data().pricesType) {
                prices = resVenue.data().pricesType
            }

            if (res.docs.length > 0) {
                for await (let customers of res.docs) {
                    await db
                        .collection("users")
                        .doc(user)
                        .collection("venues")
                        .doc(venue)
                        .collection("customers")
                        .doc(customers.id)
                        .update({
                            activeEmail: "Unsubscribed",
                        })
                }

                openToast({
                    content: i18n.t("toastSuccessUnsubscribed"),
                    type: SUCCESS,
                })

                LogEvent("toast", {
                    description: `L423 @ scanDucks.jsx | You have been unsubscribed`,
                })

                LogEvent("unsubscribe", {
                    email: email,
                    venue_id: venue,
                    new_user_id: user,
                    prices_type: prices,
                    description: `${email} - ${venue} - ${prices}`,
                })

                dispatch({
                    type: DISABLE_CUSTOMER_EXIT,
                })
            } else {
                openToast({
                    content: i18n.t("toastErrorEmailNotFound"),
                    type: ERROR,
                })

                LogEvent("toast", {
                    description: `L444 @ scanDucks.jsx | The email address was not found, please try again`,
                })

                dispatch({
                    type: DISABLE_CUSTOMER_ERROR,
                })
            }
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")
                // Save analytics
            LogEvent("new_error", {
                description: `L458 @ scanDucks.js | ${error.code} - ${error.message}`,
            })

            dispatch({
                type: DISABLE_CUSTOMER_ERROR,
            })
        }
    }

export const redirectFromCustomUrl =
    ({ urlId = "" }) =>
    async(dispatch, getState) => {
        let time = 5
        const timer = setInterval(() => {
            time--
            console.log(time)
            if (time < 0) {
                LogEvent("slow_network", {
                    new_user_id: urlId,
                    venue_id: "null",
                    qr_flow_id: "null",
                    description: "firebase wait more than 5 secs in qr page",
                })
                clearInterval(timer)
            }
        }, 1000)

        try {
            const res = await db.collection("customUrls").doc(urlId).get()

            if (res.exists) {
                let url = res.data().url

                if (!url.match(/^https?:\/\//i)) {
                    url = "http://" + url
                }

                let userId
                let venueId
                let qrFlowId

                if (url.includes("/#/")) {
                    userId = url.split("/")[5]
                    venueId = url.split("/")[6]
                    qrFlowId = url.split("/")[7]
                } else {
                    userId = url.split("/")[4]
                    venueId = url.split("/")[5]
                    qrFlowId = url.split("/")[6]
                }

                LogEvent("open_custom_url", {
                    new_user_id: userId,
                    venue_id: venueId,
                    qr_flow_id: qrFlowId,
                    custom_url: urlId,
                    description: `${urlId} - ${userId} - ${venueId} - ${qrFlowId}`,
                })

                dispatch({
                    type: GET_URL_REAL_EXIT,
                    payload: {
                        user: {
                            user: userId,
                            venue: venueId,
                            qrFlow: qrFlowId,
                        },
                        menu: {},
                    },
                })

                clearInterval(timer)
            } else {
                dispatch({
                    type: GET_URL_REAL_EXIT,
                    payload: {
                        menu: {
                            existMenu: false,
                        },
                    },
                })

                clearInterval(timer)
            }
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")
                // Save analytics
            LogEvent("new_error", {
                description: `L503 @ scanDucks.js | ${error.code} - ${error.message}`,
            })

            dispatch({
                type: GET_URL_REAL_ERROR,
            })

            clearInterval(timer)
        }
    }