/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

/* Helpers */
import { LogEvent } from "helpers/LogEvents"

/* Firebase */
import { db, functions } from "controller/firebase"

/* Constants or States */
const initData = {
    loading: false,
    loadingData: true,
    currentPayment: {},
    paymentMethod: [],
    payments: {},
    customerId: "",
}

const LOADING_STRIPE_DATA = "LOADING_STRIPE_DATA"
const LOADING_PAYMENT = "LOADING_PAYMENT"

const GET_STRIPE_EXIT = "GET_STRIPE_EXIT"
const GET_STRIPE_ERROR = "GET_STRIPE_ERROR"

const CREATE_PAYMENT_METHOD_EXIT = "CREATE_PAYMENT_METHOD_EXIT"
const CREATE_PAYMENT_METHOD_ERROR = "CREATE_PAYMENT_METHOD_ERROR"

const DELETE_PAYMENT_METHOD_EXIT = "DELETE_PAYMENT_METHOD_EXIT"
const DELETE_PAYMENT_METHOD_ERROR = "DELETE_PAYMENT_METHOD_ERROR"

const UPDATE_CURRENT_PAYMENT_EXIT = "UPDATE_CURRENT_PAYMENT_EXIT"
const UPDATE_CURRENT_PAYMENT_ERROR = "UPDATE_CURRENT_PAYMENT_ERROR"

const DELETE_CURRENT_PAYMENT_EXIT = "DELETE_CURRENT_PAYMENT_EXIT"
const DELETE_CURRENT_PAYMENT_ERROR = "DELETE_CURRENT_PAYMENT_ERROR"

const UPDATE_PAYMENT_EXIT = "UPDATE_PAYMENT_EXIT"
const UPDATE_PAYMENT_ERROR = "UPDATE_PAYMENT_ERROR"

const UPDATE_CUSTOMER_EXIT = "UPDATE_CUSTOMER_EXIT"
const UPDATE_CUSTOMER_ERROR = "UPDATE_CUSTOMER_ERROR"

const START_LOADING = "START_LOADING"
const END_LOADING = "END_LOADING"

const RESTART_STRIPE = "RESTART_STRIPE"

/* Reducer (Save call API in constant) */
export default function stripeReducer(state = initData, action) {
    switch (action.type) {
        case LOADING_STRIPE_DATA:
            return {...state, loadingData: true }

        case GET_STRIPE_EXIT:
            return {...state, ...action.payload, loadingData: false }
        case GET_STRIPE_ERROR:
            return {...state, loadingData: false }

        case START_LOADING:
            return {...state, loading: true }
        case END_LOADING:
            return {...state, loading: false }

        case LOADING_PAYMENT:
            return {...state, loading: true }

        case CREATE_PAYMENT_METHOD_EXIT:
            return {
                ...state,
                paymentMethod: action.payload.paymentMethod,
            }
        case CREATE_PAYMENT_METHOD_ERROR:
            return {...state, loading: false }

        case DELETE_PAYMENT_METHOD_EXIT:
            return {
                ...state,
                paymentMethod: initData.paymentMethod,
            }
        case DELETE_PAYMENT_METHOD_ERROR:
            return {...state }

        case UPDATE_CURRENT_PAYMENT_EXIT:
            return {
                ...state,
                currentPayment: action.payload.currentPayment,
            }
        case UPDATE_CURRENT_PAYMENT_ERROR:
            return {...state, loading: false }

        case DELETE_CURRENT_PAYMENT_EXIT:
            return {
                ...state,
                currentPayment: {},
            }
        case DELETE_CURRENT_PAYMENT_ERROR:
            return {...state, loading: false }

        case UPDATE_PAYMENT_EXIT:
            return {
                ...state,
                payments: action.payload.payments,
            }
        case UPDATE_PAYMENT_ERROR:
            return {...state, loading: false }

        case UPDATE_CUSTOMER_EXIT:
            return {
                ...state,
                customerId: action.payload,
            }
        case UPDATE_CUSTOMER_ERROR:
            return {...state, loading: false }

        case RESTART_STRIPE:
            return {...initData }
        default:
            return {...state }
    }
}

/* Actions (Calls API) */
export const getDataStripe = () => async(dispatch, getState) => {
    // Init loading
    dispatch({
        type: LOADING_STRIPE_DATA,
    })

    // Validate if the data exist in the browser
    /* if (localStorage.getItem("stripe")) {
        dispatch({
            type: GET_STRIPE_EXIT,
            payload: JSON.parse(localStorage.getItem("stripe")),
        })

        return
    } */

    if (localStorage.getItem("auth")) {
        // Get user's uid from browser
        const uid = JSON.parse(localStorage.getItem("auth")).uid

        try {
            const p1 = new Promise((resolve) =>
                db
                .collection("users")
                .doc(uid)
                .collection("paymentMethod")
                .get()
                .then((res) => resolve(res))
            )
            const p2 = new Promise((resolve) => {
                db.collection("users")
                    .doc(uid)
                    .collection("payments")
                    .get()
                    .then((res) => resolve(res))
            })
            const p3 = new Promise((resolve) => {
                db.collection("users")
                    .doc(uid)
                    .get()
                    .then((res) => resolve(res))
            })

            Promise.all([p1, p2, p3]).then((values) => {
                const paymentMethod = values[0]
                const payments = values[1]
                const user = values[2]

                let card = {}
                let currentPayment = {}
                const pays = []
                const customerId = user.data().stripeCustomerId ?
                    user.data().stripeCustomerId :
                    ""

                paymentMethod.forEach((value) => {
                    card = value.data()
                })

                payments.forEach((value) => {
                    if (value.data().status === "succeeded") {
                        pays.push(value.data())
                    }

                    if (value.data().id === user.data().currentPaymentIntent) {
                        currentPayment = value.data()
                    }
                })

                const data = {
                    currentPayment: currentPayment ? currentPayment : {},
                    paymentMethod: card,
                    payments: pays,
                    customerId,
                }

                dispatch({
                    type: GET_STRIPE_EXIT,
                    payload: data,
                })

                // Save in the browser
                /* localStorage.setItem(
                    "stripe",
                    JSON.stringify({
                        ...data,
                    })
                ) */
            })
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")

            // Save analytics
            LogEvent("new_error", {
                description: `L208 @ stripeDucks.js | ${error.code} - ${error.message}`,
            })

            dispatch({
                type: GET_STRIPE_ERROR,
            })
        }
    }
}

export const startLoadingStriper = () => (dispatch) => {
    dispatch({
        type: START_LOADING,
    })
}

export const endLoadingStriper = () => (dispatch) => {
    dispatch({
        type: END_LOADING,
    })
}

export const updateCustomerId =
    ({ customerId }) =>
    async(dispatch) => {
        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid

            try {
                // Update customer id in firestore
                await db.collection("users").doc(uid).set({
                    stripeCustomerId: customerId,
                }, { merge: true })

                // Save in redux
                dispatch({
                    type: UPDATE_CUSTOMER_EXIT,
                    payload: customerId,
                })
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")

                // Save analytics
                LogEvent("new_error", {
                    description: `L257 @ stripeDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_CUSTOMER_ERROR,
                })
            }
        }
    }

export const updateCurrentPaymentIntent =
    ({ payment }) =>
    async(dispatch, getState) => {
        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid

            try {
                // Update the status of payment intent in firestore
                db.collection("users")
                    .doc(uid)
                    .collection("payments")
                    .doc(payment.id)
                    .set({
                        ...payment,
                    })

                db.collection("users").doc(uid).set({
                    currentPaymentIntent: payment.id,
                }, { merge: true })

                // Save in redux
                dispatch({
                    type: UPDATE_CURRENT_PAYMENT_EXIT,
                    payload: {
                        currentPayment: {...payment },
                    },
                })
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")

                // Save analytics
                LogEvent("new_error", {
                    description: `L303 @ stripeDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_CURRENT_PAYMENT_ERROR,
                })
            }
        }
    }

export const deleteCurrentPaymentIntent = () => (dispatch) => {
    if (localStorage.getItem("auth")) {
        // Get user's uid from browser
        const uid = JSON.parse(localStorage.getItem("auth")).uid

        try {
            db.collection("users").doc(uid).set({
                currentPaymentIntent: null,
            }, { merge: true })

            // Save in redux
            dispatch({
                type: DELETE_CURRENT_PAYMENT_EXIT,
            })
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")

            // Save analytics
            LogEvent("new_error", {
                description: `L340 @ stripeDucks.js | ${error.code} - ${error.message}`,
            })

            dispatch({
                type: DELETE_CURRENT_PAYMENT_ERROR,
            })
        }
    }
}

export const createPaymentMethod =
    ({ paymentMethodId }) =>
    async(dispatch) => {
        dispatch({
            type: LOADING_PAYMENT,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid

            try {
                // Save data of payment in firestore
                const addPaymentMethodDetails = functions.httpsCallable(
                    "addPaymentMethodDetails"
                )
                const paymentMethod = await addPaymentMethodDetails({
                    userId: uid,
                    paymentMethodId,
                })

                // Save in redux
                dispatch({
                    type: CREATE_PAYMENT_METHOD_EXIT,
                    payload: {
                        paymentMethod: {
                            ...paymentMethod.data.paymentMethodData,
                        },
                    },
                })
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")

                // Save analytics
                LogEvent("new_error", {
                    description: `L352 @ stripeDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: CREATE_PAYMENT_METHOD_ERROR,
                })
            }
        }
    }

export const deletePaymentMethod =
    ({ paymentMethodId }) =>
    async(dispatch) => {
        dispatch({
            type: LOADING_PAYMENT,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid

            try {
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("paymentMethod")
                    .doc(paymentMethodId)
                    .delete()

                // Save in redux
                dispatch({
                    type: DELETE_PAYMENT_METHOD_EXIT,
                })
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")

                // Save analytics
                LogEvent("new_error", {
                    description: `L392 @ stripeDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: DELETE_PAYMENT_METHOD_ERROR,
                })
            }
        }
    }

export const updatePaymentIntent =
    ({ payment }) =>
    async(dispatch, getState) => {
        dispatch({
            type: LOADING_PAYMENT,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid

            try {
                const { id } = payment

                const { payments } = getState().stripe

                // Update the status of payment intent in firestore
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("payments")
                    .doc(id)
                    .set({
                        ...payment,
                    })

                payments.push(payment)

                // Save in redux
                dispatch({
                    type: UPDATE_PAYMENT_EXIT,
                    payload: {
                        payments,
                    },
                })
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")

                // Save analytics
                LogEvent("new_error", {
                    description: `L445 @ stripeDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: CREATE_PAYMENT_METHOD_ERROR,
                })
            }
        }
    }

export const deletePaymentIntent =
    ({ payment }) =>
    async(dispatch, getState) => {
        dispatch({
            type: LOADING_PAYMENT,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid

            try {
                const { id } = payment

                const { payments } = getState().stripe

                // Update the status of payment intent in firestore
                await db
                    .collection("users")
                    .doc(uid)
                    .collection("payments")
                    .doc(id)
                    .delete()

                payments.filter((item) => item.id !== id)

                // Save in redux
                dispatch({
                    type: UPDATE_PAYMENT_EXIT,
                    payload: {
                        payments,
                    },
                })
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")

                // Save analytics
                LogEvent("new_error", {
                    description: `L445 @ stripeDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: CREATE_PAYMENT_METHOD_ERROR,
                })
            }
        }
    }

// Reset stripe
export const restartDataStripe = () => async(dispatch) => {
    dispatch({
        type: RESTART_STRIPE,
    })
}