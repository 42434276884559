import React from "react"

/* Style */
import "./style.scss"

/* Components */
import { Row, Preloader, Col } from "react-materialize"

const Loading = () => {
  return (
    <div className="o-loading-container">
      <Row>
        <Col s={12} className="center-align">
          <Preloader active color="blue" flashing={false} size="big" />
        </Col>
      </Row>
    </div>
  )
}

export default Loading
