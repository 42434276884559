import { useState, useEffect, useRef } from "react"

export default function useNearScreen({ distance = "100px" }) {
    //const [isNearScreen, setIsNearScreen] = useState(false);
    const [isNearScreen, setIsNearScreen] = useState(true)
    const fromRef = useRef()

    useEffect(() => {
        let observer

        const onChange = (entries, observer) => {
            // Element to observe, can be different elements
            const el = entries[0]

            if (el.isIntersecting) {
                setIsNearScreen(true)
                    // Stop to listen the element once is show it
                observer.disconnect()
            }
        }

        // Import library to intersection observer if the browser don't support
        Promise.resolve(
            typeof IntersectionObserver !== "undefined" ?
            IntersectionObserver :
            import ("intersection-observer")
        ).then(() => {
            // Observe th viewport to know when is close to the element
            observer = new IntersectionObserver(onChange, {
                // DIstance to show element
                rootMargin: distance,
            })

            // Start to observe the element
            observer.observe(fromRef.current)
        })

        return () => observer && observer.disconnect()
    }, [distance])

    return { isNearScreen, fromRef }
}