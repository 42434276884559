/* NOTE
    Ducks are a methodology to order redux, if you can't use ducks you have to create constant, reducer and action in different files
*/

import { db } from "../controller/firebase"

/* Components */
import { openToast, SUCCESS } from "components/toast/Toast"

/* Redux */
import { reloadDataToken } from "./userDucks"

/* Helpers */
import { LogEvent } from "helpers/LogEvents"

/* I18n */
import i18n from "i18next"

/* Constants or States */
const initData = {
    loading: true,
    data: [],
}

const LOADING_VENUE = "LOADING_VENUE"

const GET_VENUE_EXIT = "GET_VENUE_EXIT"
const GET_VENUE_ERROR = "GET_VENUE_ERROR"

const UPDATE_VENUE_EXIT = "UPDATE_VENUE_EXIT"
const UPDATE_VENUE_ERROR = "UPDATE_VENUE_ERROR"

const RESTART_VENUE = "RESTART_VENUE"

/* Reducer (Save call API in constant) */
export default function venueReducer(state = initData, action) {
    switch (action.type) {
        case LOADING_VENUE:
            return {...state, loading: true }
        case GET_VENUE_EXIT:
            return {...state, data: action.payload, loading: false }
        case GET_VENUE_ERROR:
            return {...initData }
        case UPDATE_VENUE_EXIT:
            return {...state, data: action.payload, loading: false }
        case UPDATE_VENUE_ERROR:
            return {...state, loading: false }
        case RESTART_VENUE:
            return {...initData }
        default:
            return {...state }
    }
}

/* Actions (Calls API) */
export const getDataVenue = () => async(dispatch) => {
    // Init loading to get venue
    dispatch({
        type: LOADING_VENUE,
    })

    // Validate if the data exist in the browser
    if (localStorage.getItem("venue")) {
        const venues = [...JSON.parse(localStorage.getItem("venue")).data]
        venues[0].showAds.offUntil = new Date(
            Date.parse(venues[0].showAds.offUntil)
        )

        dispatch({
            type: GET_VENUE_EXIT,
            payload: venues,
        })
        return
    }

    if (localStorage.getItem("auth")) {
        // Get user's uid from browser
        const uid = JSON.parse(localStorage.getItem("auth")).uid
        let data = []

        try {
            const res = await db
                .collection("users")
                .doc(uid)
                .collection("venues")
                .get()
            res.forEach((doc) => {
                data = [
                    ...data,
                    {
                        ...doc.data(),
                        id: doc.id,
                        showAds: {
                            ...doc.data().showAds,
                            offUntil: new Date(
                                doc.data().showAds.offUntil.toDate()
                            ),
                        },
                    },
                ]
            })

            if (data.length > 0) {
                dispatch({
                    type: GET_VENUE_EXIT,
                    payload: data,
                })

                // Save in the browser
                localStorage.setItem(
                    "venue",
                    JSON.stringify({
                        data,
                    })
                )
            }
        } catch (error) {
            // Show toast unexpected error reload
            document
                .getElementById("toast-unexpected-error")
                .classList.remove("hide")
                // Save analytics
            LogEvent("new_error", {
                description: `L85 @ venueDucks.js | ${error.code} - ${error.message}`,
            })

            dispatch({
                type: GET_VENUE_ERROR,
            })
        }
    }
}

export const updateNameVenue =
    ({ values = {}, id = "" }) =>
    async(dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_VENUE,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            try {
                // Get previous data from the state
                const { data } = getState().venue

                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(id)
                    .update({
                        name: values.name,
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const dataUpdate = data.map((item) =>
                    item.id === id ?
                    {
                        ...item,
                        name: values.name,
                    } :
                    item
                )

                dispatch({
                        type: UPDATE_VENUE_EXIT,
                        payload: [...dataUpdate],
                    })
                    // Save in the browser
                localStorage.setItem(
                    "venue",
                    JSON.stringify({
                        data: [...dataUpdate],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                    // Save analytics
                LogEvent("new_error", {
                    description: `L132 @ venueDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_VENUE_ERROR,
                })
            }
        }
    }

export const updateAddressVenue =
    ({ values = {}, id = "" }) =>
    async(dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_VENUE,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            try {
                // Get previous data from the state
                const { data } = getState().venue

                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(id)
                    .update({
                        address: {
                            street: values.street,
                            country: values.country,
                            city: values.city,
                            state: values.state,
                            postalCode: values.postalCode,
                        },
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const dataUpdate = data.map((item) =>
                    item.id === id ?
                    {
                        ...item,
                        address: {...values },
                    } :
                    item
                )

                dispatch({
                        type: UPDATE_VENUE_EXIT,
                        payload: [...dataUpdate],
                    })
                    // Save in the browser
                localStorage.setItem(
                    "venue",
                    JSON.stringify({
                        data: [...dataUpdate],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                    // Save analytics
                LogEvent("new_error", {
                    description: `L185 @ venueDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_VENUE_ERROR,
                })
            }
        }
    }

export const updateSocialsVenue =
    ({ values = {}, id = "" }) =>
    async(dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_VENUE,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            try {
                // Get previous data from the state
                const { data } = getState().venue

                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(id)
                    .update({
                        socials: {
                            facebook: values.facebook,
                            instagram: values.instagram ?
                                `www.instagram.com/${values.instagram}` :
                                values.instagram,
                            tiktok: values.tiktok ?
                                `www.tiktok.com/@${values.tiktok}` :
                                values.tiktok,
                            twitter: values.twitter ?
                                `www.twitter.com/${values.twitter}` :
                                values.twitter,
                            whatsapp: values.whatsapp,
                        },
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const dataUpdate = data.map((item) =>
                    item.id === id ?
                    {
                        ...item,
                        socials: {
                            facebook: values.facebook,
                            instagram: values.instagram ?
                                `www.instagram.com/${values.instagram}` :
                                values.instagram,
                            tiktok: values.tiktok ?
                                `www.tiktok.com/@${values.tiktok}` :
                                values.tiktok,
                            twitter: values.twitter ?
                                `www.twitter.com/${values.twitter}` :
                                values.twitter,
                            whatsapp: values.whatsapp,
                        },
                    } :
                    item
                )

                dispatch({
                        type: UPDATE_VENUE_EXIT,
                        payload: [...dataUpdate],
                    })
                    // Save in the browser
                localStorage.setItem(
                    "venue",
                    JSON.stringify({
                        data: [...dataUpdate],
                    })
                )
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                    // Save analytics
                LogEvent("new_error", {
                    description: `L244 @ venueDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_VENUE_ERROR,
                })
            }
        }
    }

export const updateNameAndAddressVenue =
    ({ values = {}, id = "" }) =>
    async(dispatch, getState) => {
        // Init loading to create user
        dispatch({
            type: LOADING_VENUE,
        })

        if (localStorage.getItem("auth")) {
            // Get user's uid from browser
            const uid = JSON.parse(localStorage.getItem("auth")).uid
            try {
                // Get previous data from the state
                const { data } = getState().venue

                await db
                    .collection("users")
                    .doc(uid)
                    .collection("venues")
                    .doc(id)
                    .update({
                        name: values.name,
                        address: {
                            street: values.street,
                            country: values.country,
                            city: values.city,
                            state: values.state,
                            postalCode: values.postalCode,
                        },
                    })

                // Reload data token
                reloadDataToken()(dispatch)

                const dataUpdate = data.map((item) =>
                    item.id === id ?
                    {
                        ...item,
                        name: values.name,
                        address: {
                            street: values.street,
                            country: values.country,
                            city: values.city,
                            state: values.state,
                            postalCode: values.postalCode,
                        },
                    } :
                    item
                )

                dispatch({
                        type: UPDATE_VENUE_EXIT,
                        payload: [...dataUpdate],
                    })
                    // Save in the browser
                localStorage.setItem(
                    "venue",
                    JSON.stringify({
                        data: [...dataUpdate],
                    })
                )

                // Show toast
                openToast({
                    content: i18n.t("toastSuccessInfoUpdated"),
                    type: SUCCESS,
                })

                LogEvent("toast", {
                    description: `L305 @ venueDucks.jsx | Your info was updated`,
                })
            } catch (error) {
                // Show toast unexpected error reload
                document
                    .getElementById("toast-unexpected-error")
                    .classList.remove("hide")
                    // Save analytics
                LogEvent("new_error", {
                    description: `L311 @ venueDucks.js | ${error.code} - ${error.message}`,
                })

                dispatch({
                    type: UPDATE_VENUE_ERROR,
                })
            }
        }
    }

// Reset venue
export const restartDataVenue = () => async(dispatch) => {
    dispatch({
        type: RESTART_VENUE,
    })
}