/* Components */
import { openToast, ERROR } from "components/toast/Toast"

/* Helpers */
import { LogEvent } from "./LogEvents"

/* I18n */
import i18n from "i18n/i18n"

export function LoadingTimer({
    maxTime,
    eventName,
    userId,
    venueId,
    qrFlowId,
}) {
    const timeToSave = maxTime

    const stopTimer = (timer) => {
        clearInterval(timer)
    }

    const interval = setInterval(() => {
        maxTime--

        if (maxTime <= 0) {
            // Reload the page if the timer is 0
            stopTimer(interval)

            LogEvent("reload_automatically", {
                new_user_id: userId,
                venue_id: venueId,
                qr_flow_id: qrFlowId,
                time: timeToSave,
                method: eventName,
                description: `${userId} - ${venueId} - ${qrFlowId} - ${maxTime} - ${eventName}`,
            })

            // Show toast
            openToast({
                content: i18n.t("toastLimitTimeExceeded"),
                type: ERROR,
                callback: () => {
                    window.location.reload()
                },
            })
        }
    }, 1000)

    return { interval, stopTimer }
}