import React from "react"

/* Routes */
import { useParams } from "react-router"

/* Redux */
import { useDispatch } from "react-redux"
import { redirectFromCustomUrl } from "redux/scanDucks"

const CustomUrlScan = () => {
  // Const Redux
  const dispatch = useDispatch()

  // Params get from the url
  const { url } = useParams()

  React.useEffect(() => {
    const fetchUrl = () => {
      dispatch(redirectFromCustomUrl({ urlId: url }))
    }

    fetchUrl()
  }, [dispatch, url])

  return <div></div>
}

export default CustomUrlScan
