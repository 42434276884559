import ReactDOMServer from "react-dom/server"
import M from "materialize-css"
//import { Button } from "react-materialize";

export const SUCCESS = "success"
export const ERROR = "error"
export const INFO = "info"

export const openToast = ({
  content = "",
  importantContent = "",
  type = INFO,
  time = 4000,
  callback = () => {},
}) => {
  let newMessage
  const contentString = ReactDOMServer.renderToString(content)
  const importantContentString = ReactDOMServer.renderToString(importantContent)
  newMessage = `<div><p>${contentString}</p>${importantContentString}</div>`

  M.toast({
    html: newMessage,
    displayLength: time,
    classes: type,
    completeCallback: callback,
  })
}
